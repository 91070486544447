import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory('HasToggleableDisplayMode', [
    '$injector',
    $injector => {
        const $rootScope = $injector.get('$rootScope');
        const ClientStorage = $injector.get('ClientStorage');
        const TranslationHelper = $injector.get('TranslationHelper');
        const scopeTimeout = $injector.get('scopeTimeout');

        return {
            onLink(scope, savedPreferenceKey, supportsPlaylists, filterCompletedStreamsFromTopicGroups) {
                const translationHelper = new TranslationHelper('lessons.stream.has_toggleable_display_mode');

                scope.supportsPlaylists = supportsPlaylists;

                scope.__preferenceKey = angular.isDefined(savedPreferenceKey)
                    ? savedPreferenceKey
                    : 'toggleableCourseListDefault';

                scope.renderDisplay = {
                    flat: false,
                    topic: false,
                };

                // Load up the default saved preference
                let defaultSortValue = ClientStorage.getItem(scope.__preferenceKey);

                // special logic for high school blue ocean students - they only have one course
                // Make this override everything so we don't confuse ourselves in development
                // when we have the last user's default still saved in local storage
                if ($rootScope.currentUser && $rootScope.currentUser.sign_up_code === 'BOSHIGH') {
                    defaultSortValue = 'flat';
                }

                // If user has a legacy 'playlist' value, default to topic
                if (defaultSortValue === 'playlist') {
                    defaultSortValue = 'topic';

                    // If user doesn't have a saved preference yet...
                } else if (defaultSortValue !== 'flat' && defaultSortValue !== 'topic') {
                    defaultSortValue = 'topic';
                }

                // preference for which view to show
                scope.dashboardDisplayMode = {
                    key: defaultSortValue,
                };

                // toggle method
                scope.changeDashboardDisplayMode = key => {
                    scope.renderDisplay[key] = true;
                    scope.dashboardDisplayMode.key = key;

                    // save the preference
                    ClientStorage.setItem(scope.__preferenceKey, key);
                };

                scope.changeDashboardDisplayMode(defaultSortValue);

                // initialization of topic groups
                scope.createTopicGroups = streams => {
                    const topics = [];
                    const coursesGroupedByTopic = {};

                    if (!streams) {
                        return undefined;
                    }

                    // Special "topic" for courses with no topics
                    // FIXME: Other needs to be translated
                    // Use TranslationHelper and put this string in a locale file
                    const noTopicName = translationHelper.get('other');

                    streams.forEach(stream => {
                        if (filterCompletedStreamsFromTopicGroups && stream.complete) {
                            return;
                        }

                        function addCourseToTopic(courseStream, topicName) {
                            coursesGroupedByTopic[topicName] = coursesGroupedByTopic[topicName] || [];
                            if (coursesGroupedByTopic[topicName].length === 0) {
                                topics.push(topicName);
                            }

                            coursesGroupedByTopic[topicName].push(courseStream);
                        }

                        const topicNames = stream.contentTopicNames;

                        if (!_.some(topicNames)) {
                            topicNames.push(noTopicName);
                        }

                        topicNames.forEach(topicName => {
                            addCourseToTopic(stream, topicName);
                        });
                    });

                    scope.coursesGroupedByTopic = coursesGroupedByTopic;

                    // sort the topic groups the same as the topic buttons
                    scope.topics = topics.sort();

                    return coursesGroupedByTopic;
                };

                // it's up to whatever downstream component to (optionally) subscribe to
                // this pre-rendering, but if so, they should call this after initial
                // data load, etc. A delay is baked-in in order to allow for arbitrary
                // DOM rendering, etc that might already be in progress.
                scope.preloadAllDisplayModes = function () {
                    if (this._preloaded) {
                        return;
                    }

                    // no need to do this again ...
                    this._preloaded = true;

                    // defer additional DOM rendering a bit. ensure it cleans-up
                    scopeTimeout(
                        scope,
                        () => {
                            for (const key in scope.renderDisplay) {
                                scope.renderDisplay[key] = true;
                            }
                        },
                        4000,
                    );
                };
            },
        };
    },
]);
