import { regionAwareImageUrlForFormat } from 'regionAwareImage';

angular.module('FrontRoyal.EntityMetadata', []).factory('EntityMetadata', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');

        // eslint-disable-next-line func-names
        return Iguana.subclass(function () {
            this.setCollection('entity_metadata');
            this.alias('EntityMetadata');
            this.embeddedIn('stream');
            this.embeddedIn('lesson');
            this.setIdProperty('id'); // todo: eventually we should only publicly expose guids instead of sequential IDs

            return {
                imageUploadUrl() {
                    return `${window.ENDPOINT_ROOT}/api/entity_metadata/${this.id}/images.json`;
                },
                imageSrc() {
                    return regionAwareImageUrlForFormat(
                        this.image,
                        'original',
                        $injector.get('ConfigFactory').getSync(true),
                    );
                },
            };
        });
    },
]);
