import { regionAwareImageUrlForFormat } from 'regionAwareImage';

angular.module('FrontRoyal.GlobalMetadata', []).factory('GlobalMetadata', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');

        // eslint-disable-next-line func-names
        return Iguana.subclass(function () {
            this.setCollection('global_metadata');
            this.alias('GlobalMetadata');
            this.setIdProperty('id'); // todo: eventually we should only publically expose guids instead of sequential IDs

            return {
                imageSrc() {
                    return regionAwareImageUrlForFormat(
                        this.default_image,
                        'original',
                        $injector.get('ConfigFactory').getSync(true),
                    );
                },

                imageUploadUrl() {
                    return `${window.ENDPOINT_ROOT}/api/global_metadata/${this.id}/images.json`;
                },
            };
        });
    },
]);
