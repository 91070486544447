import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory('Lesson.StreamProgress', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');
        const EventLogger = $injector.get('EventLogger');
        const $window = $injector.get('$window');
        const $rootScope = $injector.get('$rootScope');

        return Iguana.subclass(function () {
            this.setCollection('lesson_streams_progress');
            this.alias('Lesson.StreamProgress');
            this.embeddedIn('stream');

            this.defineSetter('complete', function (val) {
                if (this.complete === val) {
                    return val;
                }

                // FIXME: see https://trello.com/c/yX3uh6Mj/227-getsentry-lessonprogress-complete-cannot-be-set-back-to-false-from-true
                if (this.complete === true) {
                    return val;
                    // throw new Error('Cannot set stream_progress.complete back to false');
                }

                // complete is being set to true
                this.writeKey('complete', val);
                EventLogger.log('lesson:stream:complete', this.stream().logInfo());

                return val;
            });

            // See also stream_progress.rb
            this.extend({
                WAIVER_EQUIVALENT_STREAM_ALREADY_COMPLETED: 'equivalent_stream_already_completed',
                WAIVER_EXAM_ALREADY_COMPLETED: 'exam_already_completed',
            });

            Object.defineProperty(this.prototype, 'msLeftInTimeLimit', {
                get() {
                    if (!this.time_runs_out_at) {
                        return null;
                    }
                    return Math.max(0, 1000 * this.time_runs_out_at - this._now());
                },
            });

            Object.defineProperty(this.prototype, 'timerIsStarted', {
                get() {
                    return !!this.time_runs_out_at;
                },
            });

            Object.defineProperty(this.prototype, 'inProgress', {
                get() {
                    if (this.complete) {
                        return false;
                    }

                    if (this.msLeftInTimeLimit === 0) {
                        return false;
                    }

                    return !!this.started_at;
                },
            });

            this.extend({
                startStream(stream) {
                    const streamProgress = this.new({
                        user_id: $rootScope.currentUser.id,
                        locale_pack_id: stream.localePackId,
                        complete: false,
                        time_runs_out_at: null,
                    });
                    streamProgress.$$embeddedIn = stream;
                    stream.lesson_streams_progress = streamProgress;

                    EventLogger.log('lesson:stream:start', this._startStreamLogInfo(stream), {
                        log_to_customerio: true,
                    });
                    return streamProgress;
                },

                _startStreamLogInfo(stream) {
                    const payload = stream.logInfo();
                    let period;
                    if ($rootScope.currentUser && $rootScope.currentUser.relevantCohort) {
                        period = $rootScope.currentUser.relevantCohort.periodForRequiredStream(stream.localePackId);
                    }

                    if (period) {
                        payload.period = period ? period.logInfo() : null;
                    }

                    if (period && stream.exam) {
                        // Needed for https://fly.customer.io/env/24964/tv2/campaigns/1000061/setup/trigger
                        payload.starting_final_exam = stream.exam && period.exam_style === 'final';
                    }

                    return payload;
                },
            });

            return {
                certificateImageSrc() {
                    if (!this.certificate_image) {
                        return undefined;
                    }
                    const format = this._certificateFormat();
                    const formats = this.certificate_image.formats;
                    return formats[format]?.url || formats.original?.url;
                },
                destroy($super, options) {
                    const self = this;

                    return $super.apply(this, [options]).then(response => {
                        // the server will have cleared all lesson progress as well
                        self.stream().lessons.forEach(lesson => {
                            if (lesson.lesson_progress) lesson.lesson_progress.clear();
                        });

                        self.stream().lesson_streams_progress.clear();

                        return response;
                    });
                },

                clear() {
                    this.lesson_bookmark_id = null;
                    this.started_at = null;
                    this.completed_at = null;
                    this.certificate_image_id = null;
                    this.time_runs_out_at = null;
                    this.official_test_score = null;
                    this.waiver = null;
                },

                // loosely based on image_model.js
                // todo: refactor parts that are shared?
                _certificateFormat() {
                    if ($window.devicePixelRatio > 2) {
                        return '2100x825';
                    }
                    if ($window.devicePixelRatio > 1) {
                        return '1400x550';
                    }
                    return '700x275';
                },

                // mockable
                _now() {
                    return new Date();
                },
            };
        });
    },
]);
