import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory('Stream.StreamSummariesHelper', [
    '$injector',
    $injector => {
        const ContentAccessHelper = $injector.get('ContentAccessHelper');

        const StreamSummariesHelper = {
            summariesForStreamOrLesson(stream, user, lesson) {
                if (!stream || !user) {
                    return undefined;
                }

                const streamAccessHelper = new ContentAccessHelper(stream, user);
                if (!streamAccessHelper.canLaunch) {
                    return [];
                }

                if (!lesson) {
                    return stream.summaries;
                }

                return stream.summaries.filter(summary => summary.lessons.includes(lesson.id));
            },
            onLink(scope) {
                scope.summariesForStreamOrLesson = this.summariesForStreamOrLesson;
            },
        };

        return StreamSummariesHelper;
    },
]);
