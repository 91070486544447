import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import { setupBrandNameProperties, setupBrandScopeProperties } from 'AppBranding';
import template from 'Lessons/angularModule/views/stream/dashboard_footer.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

import availableAppStore from 'vectors/available-app-store.svg';
import availableGooglePlay from 'vectors/available-google-play.svg';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('dashboardFooter', [
    '$injector',

    function factory($injector) {
        const $window = $injector.get('$window');
        const $rootScope = $injector.get('$rootScope');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const ConfigFactory = $injector.get('ConfigFactory');
        const offlineModeManager = $injector.get('offlineModeManager');
        const TranslationHelper = $injector.get('TranslationHelper');
        const translationHelper = new TranslationHelper('lessons.stream.dashboard_footer');

        return {
            scope: {},
            restrict: 'E',
            templateUrl,
            controllerAs: 'controller',

            link(scope) {
                scope.availableAppStore = availableAppStore;
                scope.availableGooglePlay = availableGooglePlay;

                NavigationHelperMixin.onLink(scope);

                const config = ConfigFactory.getSync();
                setupBrandNameProperties($injector, scope, { config });
                setupBrandScopeProperties($injector, scope, ['dashboardFooterLogo'], { config });

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                //---------------------------
                // Initialization
                //---------------------------

                scope.showCookiePreferences = config.gdprAppliesToUser();
                scope.launchCookiePreferences = () => {
                    if ($window.Optanon) {
                        const toggle = $window.Optanon.ToggleInfoDisplay;
                        toggle(); // work around ESLint
                    }
                };

                // Hybrid app has no footer.  When offline, none of the
                // links will work, so we hide the whole thing.  Tiny performance
                // bump by only watching when we're not in cordova
                if ($window.CORDOVA) {
                    scope.showFooter = false;
                } else {
                    scope.$watch(() => {
                        scope.showFooter = !offlineModeManager.inOfflineMode;
                    });
                }

                const disclaimerLocaleKey = scope.currentUser?.active_institution?.disclaimerLocaleKey;
                if (disclaimerLocaleKey) {
                    scope.disclaimerText = translationHelper.get(disclaimerLocaleKey);
                }

                const defaultUrls = {
                    ios_url: '/smartly-ios',
                    android_url: '/smartly-android',
                    privacy_policy_url: '/privacy',
                    terms_url: '/terms',
                };

                const miyaMiyaUrls = {
                    ios_url: '/miyamiya-ios',
                    android_url: '/miyamiya-android',
                };

                scope.launchPage = urlKey => {
                    let url = '';

                    url = defaultUrls[urlKey];

                    if (scope.currentUser?.active_institution[urlKey]) {
                        url = scope.currentUser?.active_institution[urlKey];
                    }

                    // FIXME: once we decide to add the urls to the MiyaMiya institution model,
                    // this code block below can be removed along with the miyaMiyaUrls object.
                    if (scope.currentUser?.isMiyaMiya && miyaMiyaUrls[urlKey]) {
                        url = miyaMiyaUrls[urlKey];
                    }

                    scope.loadUrl(url, '_blank');
                };
            },
        };
    },
]);
