import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import 'FrontRoyalUiBootstrap/popover';

angularModule.directive('modalPopup', [
    '$injector',
    $injector => {
        const $window = $injector.get('$window');
        const $compile = $injector.get('$compile');
        const $timeout = $injector.get('$timeout');
        const safeApply = $injector.get('safeApply');
        const EventLogger = $injector.get('EventLogger');
        const guid = $injector.get('guid');

        return {
            scope: {
                index: '<',
                modalContent: '<',

                /*
                        skipRecompile is a total hack.  I don't understand $compile.

                        Originally I thought that the combination of transclude:true and $compile
                        meant that the content would not be compiled by default, and we had to use
                        $compile to get it compiled against the correct scope.  Setting it up
                        using $compile works for modals in the lesson player.

                        But, when I tried to modal-popup somewhere else (admin_cohort_gradebook), I noticed that the content
                        was being double-compiled.  I was seeing an ng-repeat linked to a collection with 3
                        elements end up printing out 9 elements because of the double-compiling.  So I
                        added in skipRecompile to turn off the $compile, and it all works.

                        So the mystery now is, why does the lesson player rely on the double-compile?  It
                        has been using the double-compile for years, and if I remove it there, then things
                        break.  But I do not understand why it needs it.
                    */
                skipRecompile: '<',
                modalContainer: '@',
                popoverClass: '@',
                placement: '@',
                trigger: '@',
                eventType: '@',
            },

            restrict: 'E',
            transclude: true,

            /*
                    There are two ways to use this.  The first is shorter, but doesn't allow for compiling of
                    the contents.  The second allows for compiling of the contents:

                    1.
                        Here is a <modal-popup index='1' modal-content="<span>Here is some html content for the modal</span>">modal popup</modal-popup>

                    2.
                        Here is a <modal-popup index='1'>
                            <span label>modal popup</span>
                            <span content>Here is som html content that will be compiled against the expected scope</span>
                        </modal-popup>
                */
            template(element, attrs) {
                if (attrs.modalContent) {
                    return '<a class="modal-popup" dir="auto"><span class="lesson_popover" data-index="{{index}}" ng-transclude label><div class="text-click-target"></div></span></a>';
                }
                return '<a class="modal-popup"><span class="lesson_popover" data-index="{{index}}"><div class="text-click-target"></div></span></a>';
            },

            link(scope, elem, attrs, nullController, transclude) {
                const directiveScope = scope;
                const popoverElem = elem.find('.lesson_popover');
                const modalIndex = $('.cf-text .lesson_popover').index(popoverElem);
                const windowElem = $($window);
                const bodyElem = $('body');
                let modalContent;
                const popoverId = `popover-${guid.generate()}`;

                if (popoverElem.popover !== undefined && attrs.modalContent) {
                    // why is this null in tests?

                    // Note.  If the value of `modalContent` changes while the popover is being displayed,
                    // the new content will not be shown unless the user moves the cursor away and hovers again,
                    // since the bootstrap popover code has no way to know it needs to re-render the popover
                    // content.
                    modalContent = () => `<div dir="auto" id="${popoverId}">${scope.modalContent}</div>`; // the id is there so that mathjax can find this

                    // if the modal label contains mathjax, add nowrap to the container to avoid weird display issues on mobile: https://trello.com/c/hvfvOo5L
                    if (elem.find('.lesson_popover .MathJax').length > 0) {
                        popoverElem.addClass('nowrap');
                    }
                } else {
                    transclude(scope, clone => {
                        const label = clone.filter('[label]');
                        const content = clone.filter('[content]');
                        $compile(label)(scope.$parent, newElement => {
                            elem.find('span').append(newElement);
                        });

                        let compiledContent;
                        modalContent = () => {
                            if (!compiledContent) {
                                // dealing in jquery land. prevent out of band rendering issues.
                                safeApply(scope, () => {
                                    // See comment above about skipRecompile
                                    if (!directiveScope.skipRecompile) {
                                        $compile(content)(scope.$parent, newElement => {
                                            compiledContent = newElement;
                                        });
                                    } else {
                                        compiledContent = content;
                                    }

                                    // if the modal label contains mathjax, add nowrap to the container to avoid weird display issues on mobile: https://trello.com/c/hvfvOo5L
                                    if (elem.find('.lesson_popover .MathJax').length > 0) {
                                        popoverElem.addClass('nowrap');
                                    }
                                });
                            }
                            // it's possible that the scope has already been destroyed and safeApply returns, leaving compiledContent undefined
                            if (compiledContent) {
                                compiledContent.attr('id', popoverId);
                            }
                            return compiledContent;
                        };

                        // Pre-compile the data after a short delay. This
                        // way it shouldn't interfere with other stuff going
                        // on right now, but any mathjax will be rendered
                        // already when someone clicks on the modal.
                        $timeout(() => {
                            modalContent();
                        }, 500);
                    });
                }

                popoverElem.popover({
                    container: scope.modalContainer || 'body',
                    toggle: 'popover',
                    placement: scope.placement || 'bottom',
                    html: true,
                    trigger: scope.trigger || 'manual',

                    // important to use function in order to maintain data updates
                    content: modalContent,
                });

                if (scope.popoverClass) {
                    popoverElem.data('bs.popover').tip().addClass(scope.popoverClass);
                }

                // If manual trigger, we will own showing and hide the tooltip ourselves via click events
                if (!scope.trigger) {
                    popoverElem.on('click.modal_popup', function (e) {
                        $('.lesson_popover').not(this).popover('hide');
                        $(this).popover('toggle');
                        e.stopPropagation();
                    });

                    $(document).on('click.modal_popup', e => {
                        // don't dismiss if the click was on a link within the popover
                        if ($(e.target).is('a.external-link') && $(e.target).parents('.popover').length > 0) {
                            return;
                        }

                        if (!$(e.target).is('.lesson_popover')) {
                            $('.lesson_popover').popover('hide');
                        }
                    });
                }

                // listen for resizes (orientation change or window resize) and re-display
                popoverElem.on('shown.bs.popover', () => {
                    const _modalContent = modalContent();
                    if (scope.eventType) {
                        EventLogger.log(scope.eventType, {
                            label: popoverElem.text().trim(),
                            content: (angular.isFunction(_modalContent.text)
                                ? _modalContent.text()
                                : _modalContent
                            ).trim(),
                        });
                    }
                    bodyElem.addClass('popover-open');
                    windowElem.on(`resize.modals_${modalIndex}`, () => {
                        windowElem.off(`resize.modals_${modalIndex}`);
                        popoverElem.popover('show');
                    });
                });

                // clear resize listener when we've definitively hidden the popup
                popoverElem.on('hidden.bs.popover', () => {
                    bodyElem.removeClass('popover-open');
                    windowElem.off(`resize.modals_${modalIndex}`);
                });

                // clean up any potential event listeners
                scope.$on('$destroy', () => {
                    bodyElem.removeClass('popover-open');
                    popoverElem.off('shown.bs.popover');
                    popoverElem.off('hidden.bs.popover');
                    popoverElem.off('click.modal_popup');
                    $(document).off('click.modal_popup');
                    windowElem.off(`resize.modals_${modalIndex}`);

                    // We've seen cases where the popover element does not get removed correctly.
                    // Make sure it does (https://trello.com/c/ZRcl6Q2m)
                    $(`#${popoverId}`).closest('.popover').remove();
                });
            },
        };
    },
]);
