import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import { lessonLaunchPath } from 'Lessons';
import { claimExamVerification, mustClaimExamVerificationToLaunchStream } from 'ExamVerification';

angularModule.factory('Lesson', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');
        const IsContentItem = $injector.get('IsContentItem');
        const LessonProgress = $injector.get('LessonProgress');
        const ClientConfig = $injector.get('ClientConfig');
        const stemmer = $injector.get('stemmer');
        const FormatsText = $injector.get('FormatsText');
        const EventLogger = $injector.get('EventLogger');
        const Locale = $injector.get('Locale');
        const $q = $injector.get('$q');
        const $location = $injector.get('$location');
        const $rootScope = $injector.get('$rootScope');
        const timerSingleton = $injector.get('timerSingleton');

        return Iguana.subclass(function () {
            this.setCollection('lessons');
            this.alias('Lesson');
            this.setSciProperty('lesson_type');
            this.include(IsContentItem);
            this.embeddedIn('stream');
            this.embedsOne('lesson_progress', 'LessonProgress');
            this.embedsOne('entity_metadata', 'EntityMetadata');
            this.embedsMany('version_history', 'LessonVersion');

            this.extend({
                fieldsForEditorList: [
                    'id',
                    'title',
                    'tag',
                    'modified_at',
                    'published_at',
                    'updated_at',
                    'author',
                    'stream_titles',
                    'lesson_type',
                    'archived',
                    'unrestricted',
                    'locale',
                    'locale_pack',
                    'assessment',
                    'test',
                ],
                editorUrl(id) {
                    return `/editor/lesson/${id}/edit`;
                },
            });

            Object.defineProperty(this.prototype, 'factoryName', {
                get() {
                    return 'Lesson';
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'allContentLoaded', {
                get() {
                    throw new Error(
                        `Subclasses of Lesson should define allContentLoaded. "${this.constructor.alias()}" does not.`,
                    );
                },
            });

            Object.defineProperty(this.prototype, 'parameterizedTag', {
                get() {
                    return this.tag
                        ? this.tag
                              .trim()
                              .replace(/[^a-zA-Z0-9-\s]/g, '')
                              .replace(/\s/g, '-')
                        : '';
                },
            });

            Object.defineProperty(this.prototype, 'complete', {
                get() {
                    return !!this.lesson_progress && this.lesson_progress.complete;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'completedTest', {
                get() {
                    return (
                        (this.test && this.complete) ||
                        // this is an edge case, but if a user's progress has been
                        // transferred from one exam to another, they might have the
                        // exam marked as complete when they have not completed all of the
                        // lessons in the exam.  If that's the case, we want to show the lessons
                        // as being complete
                        (this.stream() && this.stream().exam && this.stream().complete)
                    );
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'lastProgressAt', {
                get() {
                    return (
                        (this.lesson_progress &&
                            this.lesson_progress.last_progress_at &&
                            new Date(1000 * this.lesson_progress.last_progress_at)) ||
                        undefined
                    );
                },
            });

            Object.defineProperty(this.prototype, 'streamTitlesToS', {
                get() {
                    return this.stream_titles ? this.stream_titles.sort().join(', ') : '';
                },
            });

            Object.defineProperty(this.prototype, 'started', {
                get() {
                    return !!this.lesson_progress?.started_at;
                },
            });

            Object.defineProperty(this.prototype, 'utmCampaign', {
                get() {
                    return 'share_lesson';
                },
            });

            Object.defineProperty(this.prototype, 'launchUrl', {
                get() {
                    return lessonLaunchPath({
                        lessonId: this.id,
                        streamId: this.stream().id,
                        chapterIndex: this.chapterIndex(),
                    });
                },
            });

            Object.defineProperty(this.prototype, 'comingSoon', {
                get() {
                    return this.chapter() && this.chapter().isLessonComingSoon(this);
                },
                configurable: true, // tests
            });

            Object.defineProperty(this.prototype, 'standaloneRoute', {
                get() {
                    if (this.entity_metadata && this.entity_metadata.canonical_url) {
                        return this.entity_metadata.canonical_url;
                    }
                    return `/lesson/${this.title.toLowerCase().replace(/[\W_]+/g, '-')}/show/${this.id}`;
                },
            });

            Object.defineProperty(this.prototype, 'canonicalUrlTitlePart', {
                get() {
                    if (this.entity_metadata && this.entity_metadata.canonical_url) {
                        return this.entity_metadata.canonical_url.split('/')[2];
                    }
                    return this.title.toLowerCase().replace(/[\W_]+/g, '-');
                },
            });

            Object.defineProperty(this.prototype, 'localeDirection', {
                get() {
                    return this.localeObject.rtl ? 'rtl' : 'ltr';
                },
            });

            Object.defineProperty(this.prototype, 'mostRecentVersion', {
                get() {
                    return this.version_history[0];
                },
            });

            Object.defineProperty(this.prototype, 'editable', {
                get() {
                    return !this.old_version;
                },
            });

            Object.defineProperty(this.prototype, 'thisVersionPublished', {
                get() {
                    return this.published_version_id === this.version_id;
                },
            });

            Object.defineProperty(this.prototype, 'launchableWithCurrentClient', {
                get() {
                    return ClientConfig.current.versionNumber >= this.client_requirements.min_allowed_version;
                },
                configurable: true, // tests
            });

            Object.defineProperty(this.prototype, 'launchableWithLatestAvailableVersionOfCurrentClient', {
                get() {
                    return this.client_requirements.supported_in_latest_available_version;
                },
                configurable: true, // tests
            });

            Object.defineProperty(this.prototype, 'nextLessonInStream', {
                get() {
                    if (!this.stream()) {
                        return undefined;
                    }
                    const orderedLessons = this.stream().orderedLessons;
                    const index = orderedLessons.indexOf(this);
                    return index > -1 ? orderedLessons[index + 1] : undefined;
                },
            });

            Object.defineProperty(this.prototype, 'blockSaving', {
                get() {
                    if (this.$$saving) {
                        return true;
                    }
                    if (this.$$saveBlocks && Object.keys(this.$$saveBlocks).length > 0) {
                        return true;
                    }
                    return false;
                },
            });

            Object.defineProperty(this.prototype, 'bestScore', {
                get() {
                    if (this.lesson_progress && $.isNumeric(this.lesson_progress.best_score)) {
                        return this.lesson_progress.best_score;
                    }

                    return undefined;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'editorName', {
                get() {
                    const name = this.last_editor && this.last_editor.name;
                    return name || 'Unknown';
                },
            });

            Object.defineProperty(this.prototype, 'localeObject', {
                get() {
                    return Locale[this.locale];
                },
            });

            Object.defineProperty(this.prototype, 'isPractice', {
                get() {
                    return this.locale_pack && !!this.locale_pack.is_practice_for_locale_pack_id;
                },
            });

            Object.defineProperty(this.prototype, 'hasPractice', {
                get() {
                    return !!this.practiceLessonId;
                },
            });

            Object.defineProperty(this.prototype, 'practiceLessonId', {
                get() {
                    return (
                        this.locale_pack &&
                        _.chain(this.locale_pack.practice_content_items)
                            .filter({
                                locale: this.locale,
                            })
                            .map('id')
                            .first()
                            .value()
                    );
                },
            });

            Object.defineProperty(this.prototype, 'supportsPractice', {
                get() {
                    return !!this.locale_pack && !this.isPractice;
                },
            });

            // Disable some things for practice lessons
            const self = this;
            [
                'supportsDescription',
                'supportsTag',
                'supportsSeoMetadata',
                'supportsGrading',
                'supportsFrameNavigator',
                'supportsLessonOptions',
            ].forEach(prop => {
                Object.defineProperty(self.prototype, prop, {
                    get() {
                        return !this.isPractice;
                    },
                    configurable: true, // specs
                });
            });

            Object.defineProperty(this.prototype, 'editorAbilities', {
                get() {
                    const user = $rootScope.currentUser;
                    const lesson = this;
                    return {
                        canOpenEditor:
                            user &&
                            (user.canEditLesson(lesson) ||
                                user.canPreviewLesson(lesson) ||
                                user.canReviewLesson(lesson)),
                        canArchive: user && user.canArchiveLesson(lesson),
                        canDelete: lesson.archived && user && user.canEditLesson(lesson),
                    };
                },
            });

            Object.defineProperty(this.prototype, 'testOrAssessment', {
                get() {
                    return this.test || this.assessment;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'keyTermDefinitions', {
                get() {
                    return this.key_term_definitions ?? [];
                },
                configurable: true,
            });

            this.defineSetter('archived', function (val) {
                if (val === true) {
                    this.locale_pack = undefined;
                }

                this.writeKey('archived', val);
            });

            this.setCallback('before', 'save', function () {
                EventLogger.log('lesson:save', this.logInfo());
            });

            return {
                toJasminePP() {
                    return `Lesson: "${this.title}" (id=${this.id}) `;
                },

                importFromCsv() {
                    throw new Error('importFromCsv should be implemented by subclasses of Lesson.');
                },

                createPlayerViewModel(options) {
                    return new this.constructor.PlayerViewModel(this, options);
                },

                chapterIndex() {
                    const chapter = this.chapter();
                    return chapter ? chapter.index : undefined;
                },

                chapterLessonsIndex() {
                    const chapter = this.chapter();
                    return chapter ? chapter.lessonIds.indexOf(this.id) : undefined;
                },

                chapter() {
                    if (!this.stream() || !this.stream().chapters) {
                        return undefined;
                    }

                    // eslint-disable-next-line no-restricted-syntax
                    for (const chapter of this.stream().chapters) {
                        if (chapter.lessons.includes(this)) {
                            return chapter;
                        }
                    }

                    return undefined;
                },

                // Determine if a stream is not_started, in_progress, or completed
                progressStatus() {
                    if (this.lesson_progress) {
                        if (this.lesson_progress.inProgress) {
                            return 'in_progress';
                        }
                        if (this.lesson_progress.complete) {
                            return 'completed';
                        }
                    }
                    return 'not_started';
                },

                ensureLessonProgress() {
                    if (!this.lesson_progress) {
                        return LessonProgress.startLesson(this);
                    }
                    return this.lesson_progress;
                },

                logInfo() {
                    const streamInfo = this.stream() ? this.stream().logInfo() : {};
                    return angular.extend(
                        {
                            lesson_id: this.id,
                            lesson_complete: this.complete,

                            /*
                            We considered dropping the lesson_title to save space in the db, but
                            then thought that it's pretty useful over in amplitude. We could remove
                            it and just use lesson_id in amplitude even though it is inconvenient, or
                            we could consider only putting the lesson_title on certain events, like
                            lesson:start, but not on others, like challenge_complete
                        */
                            lesson_title: this.title,
                            lesson_version_id: this.version_id,
                        },
                        streamInfo,
                    );
                },

                grade() {
                    throw new Error('subclasses of Lesson should define grade()');
                },

                blockSave() {
                    // eslint-disable-next-line no-multi-assign
                    const saveBlocks = (this.$$saveBlocks = this.$$saveBlocks || {});
                    const id = $injector.get('guid').generate();
                    this.$$saveBlocks[id] = true;
                    return {
                        unblock() {
                            delete saveBlocks[id];
                        },
                    };
                },

                getSearchTermsSet() {
                    const texts = [this.title].concat(this.description).concat(this.key_terms);
                    return stemmer.stemmedWordsSet(texts);
                },

                resetKeyTerms() {
                    throw new Error('Subclasses of Lesson should define resetKeyTerms');
                },

                // Used by views that need to display the saved key terms formatted
                getKeyTermsForDisplay() {
                    if (this._cachedKeyTermsForDisplay) {
                        return this._cachedKeyTermsForDisplay;
                    }

                    const keyTermsForDisplay = (this.key_terms || []).map(key_term => {
                        const definitionObj = this.keyTermDefinitions.find(def => def.key_term === key_term);
                        let keyTermText = FormatsText.stripFormatting(key_term);
                        keyTermText = keyTermText.substring(0, 1).toUpperCase() + keyTermText.substring(1);
                        return {
                            keyTerm: keyTermText,
                            definition: definitionObj?.definition,
                            verified: definitionObj?.verified ?? false,
                        };
                    });
                    this._cachedKeyTermsForDisplay = keyTermsForDisplay;

                    return keyTermsForDisplay;
                },

                launch(linkId) {
                    if (!linkId) {
                        $injector.get('ErrorLogService').notify(new Error('No linkId provided when launching lesson'));
                        linkId = 'unknown';
                    }

                    const launchLesson = () => {
                        $injector.get('EventLogger').log(
                            'lesson:clicked_launch',
                            angular.extend(this.logInfo(), {
                                label: linkId.toLowerCase().replace(' ', '_'),
                            }),
                        );
                        timerSingleton.startTimer(`lesson:clickedLaunch:${this.id}`);
                        $location.url(this.launchUrl);
                    };

                    if (mustClaimExamVerificationToLaunchStream($rootScope.currentUser, this.stream())) {
                        claimExamVerification($rootScope.currentUser, this.stream()).then(response => {
                            // NOTE - It seems counterintuitive, but we are intentionally not handling errors
                            // coming from /api/exam_verifications/claim here. claimExamVerification uses `fetch`
                            // under the hood and fetch will always return a Promise that resolves to a Response
                            // object, meaning we'll always get into the .then() method of that promise here.
                            // Since we'll have up-to-date push messages from the API request, we'll immediately
                            // switch to showing the user the correct UI.
                            if (response.ok) launchLesson();
                        });
                    } else {
                        launchLesson();
                    }
                },

                getScore() {
                    throw new Error('Subclasses of Lesson should define getScore');
                },

                openPracticeEditor(newWindow) {
                    // eslint-disable-next-line no-shadow
                    const self = this;
                    this.ensurePracticeLesson().then(practiceLessonId => {
                        if (newWindow) {
                            const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
                            NavigationHelperMixin.loadUrl(self.constructor.editorUrl(practiceLessonId), '_blank');
                        } else {
                            $location.url(self.constructor.editorUrl(practiceLessonId));
                        }
                    });
                },

                ensurePracticeLesson() {
                    if (this.hasPractice) {
                        return $q.when(this.practiceLessonId);
                    }
                    // eslint-disable-next-line no-shadow
                    const self = this;

                    // we don't have to do the nonsense with the seo metadata here,
                    // like we do in list_lessons_dir.js
                    // because practice lessons do not care about that stuff.
                    return this.constructor
                        .create(
                            {
                                title: `Practice for "${this.title}"`,
                                locale: this.locale,
                            },
                            {
                                is_practice_for_locale_pack_id: this.localePackId,
                            },
                        )
                        .then(response => {
                            // Both because it is theoretically correct and because it is
                            // necessary to prevent dupe key errors, we copy the new lesson
                            // onto this one. See https://trello.com/c/u3TDJ7L6/959-bug-dupe-key-error-creating-practice-lesson
                            const practiceLesson = response.result;
                            self.locale_pack.practice_locale_pack_id = practiceLesson.localePackId;
                            self.locale_pack.practice_content_items = self.locale_pack.practice_content_items || [];

                            const practiceLessonJson = _.pick(practiceLesson, 'id', 'title', 'locale');
                            self.locale_pack.practice_content_items.push(practiceLessonJson);
                            return response.result.id;
                        });
                },
            };
        });
    },
]);
