import { setupBrandNameProperties } from 'AppBranding';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { errorHandlingComponentsForTopLevelComponents } from 'FrontRoyalErrorBoundary';
import template from '../../views/stream/show_stream.html';
import angularModule from '../lessons_module';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('showStream', [
    '$injector',
    function factory($injector) {
        const Stream = $injector.get('Lesson.Stream');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const ClientConfig = $injector.get('ClientConfig');
        const $rootScope = $injector.get('$rootScope');
        const TranslationHelper = $injector.get('TranslationHelper');
        const EventLogger = $injector.get('EventLogger');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                lessonId: '@',
                streamId: '@',
                chapterIndex: '@', // retaining since we might use this
                mode: '@mode',
            },
            controllerAs: 'controller',
            link(scope) {
                const translationHelper = new TranslationHelper('lessons.stream.show_stream');

                NavigationHelperMixin.onLink(scope);
                setupBrandNameProperties($injector, scope);

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                Object.defineProperty(scope, 'streamViewModel', {
                    get() {
                        if (!this.stream) {
                            return undefined;
                        }

                        if (!this.lesson.launchableWithCurrentClient) {
                            return undefined;
                        }

                        if (this._streamViewModel) {
                            return this._streamViewModel;
                        }

                        this._streamViewModel = this.stream.createStreamViewModel({
                            logProgress: !!scope.currentUser,
                            scormMode: scope.mode === 'scorm',
                        });

                        if (scope.lessonId) {
                            if (!scope.lesson) {
                                throw new Error(`No lesson found for "${scope.lessonId}"`);
                            }

                            this._streamViewModel.activeLessonId = scope.lessonId;
                        }

                        return this._streamViewModel;
                    },
                });

                scope.resetShowStreamError = () => {
                    scope.showStreamError = null;
                };

                const showAndRender = (streamId, lessonId) =>
                    Stream.showWithFullContentForLesson(streamId, lessonId)
                        .then(stream => {
                            scope.stream = stream;
                            scope.lesson = stream.lessonForId(lessonId);
                            if (!scope.lesson.launchableWithCurrentClient) {
                                if (!scope.lesson.launchableWithLatestAvailableVersionOfCurrentClient) {
                                    scope.buttonText = translationHelper.get('go_back');
                                }
                            }
                            scope.resetShowStreamError();
                        })
                        .catch(err => {
                            scope.showStreamError = err;
                        });

                scope.errorActions = errorHandlingComponentsForTopLevelComponents;
                scope.$watchGroup(['streamId', 'lessonId'], newValues => {
                    const streamId = newValues[0];
                    const lessonId = newValues[1];

                    if (streamId && lessonId) {
                        // When retrying, we need the error to bubble up so the DisconnectedModal knows not to
                        // hide itself. When triggering the initial load below, we don't want to throw the error
                        // because it would end up bubbling up as an unhandled promise rejection.
                        scope.retryShowAndRender = () =>
                            showAndRender(streamId, lessonId).then(() => {
                                if (scope.showStreamError) {
                                    throw scope.showStreamError;
                                }
                                // I don't understand why we need this safeApply here, but it seems to make it respond faster
                                // after a successful retry
                                $injector.get('safeApply')(scope);
                            });

                        showAndRender(streamId, lessonId);
                    }
                });

                scope.onClickUpgradeButton = () => {
                    if (scope.lesson.launchableWithLatestAvailableVersionOfCurrentClient) {
                        ClientConfig.current.upgrade(EventLogger);
                    } else {
                        scope.loadRoute(scope.stream.streamDashboardPath);
                    }
                };

                scope.getUpgradeMessage = () =>
                    translationHelper.get('cannot_be_launched', { brandName: scope.brandNameShort });
            },
        };
    },
]);
