import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory('UserProgressLoaderInterceptor', [
    '$injector',

    $injector => {
        let loggedInitialCall = false;

        function logInitialCall(params) {
            if (loggedInitialCall) return;

            loggedInitialCall = true;
            // I didn't understand why, but we have to lazy-get EventLogger here, rather
            // than initializing at the top of the factory, to prevent errors when loading
            // the page
            const EventLogger = $injector.get('EventLogger');
            EventLogger.log('checking_for_unfetched_progress_updates', {
                params,
            });
        }

        async function setProgressMaxUpdatedAt(response) {
            const currentUser = $injector.get('$rootScope').currentUser;
            if (!currentUser) return undefined;

            // On api requests that update progress, both of these will be
            // set in the meta. On pings, only the second one will be set (since in that
            // case we know the api request did not create any changes)
            let progressMaxUpdatedAtBeforeChanges =
                response?.data?.meta.push_messages?.progress_max_updated_at_before_changes;
            const serverProgressMaxUpdatedAt = response?.data?.meta?.push_messages?.progress_max_updated_at;

            if (serverProgressMaxUpdatedAt && !progressMaxUpdatedAtBeforeChanges) {
                progressMaxUpdatedAtBeforeChanges = serverProgressMaxUpdatedAt;
            }

            // We don't need userProgressLoaderProgressMaxUpdatedAt unless we have progressMaxUpdatedAtBeforeChanges.
            // Since userProgressLoaderProgressMaxUpdatedAt takes time to load, skip the load if possible.
            let userProgressLoaderProgressMaxUpdatedAt;
            const userProgressLoader = currentUser.progress;
            if (progressMaxUpdatedAtBeforeChanges != null) {
                userProgressLoaderProgressMaxUpdatedAt = await userProgressLoader.getProgressMaxUpdatedAt();
            }

            // if the server says that there is progress later than the last progress we
            // know about, then clear the cache.  Otherwise, just update progressMaxUpdatedAt
            const params = {
                progressMaxUpdatedAtBeforeChanges,
                userProgressLoaderProgressMaxUpdatedAt,
                serverProgressMaxUpdatedAt,
            };
            if (
                angular.isDefined(progressMaxUpdatedAtBeforeChanges) &&
                angular.isDefined(userProgressLoaderProgressMaxUpdatedAt) &&
                progressMaxUpdatedAtBeforeChanges > userProgressLoaderProgressMaxUpdatedAt
            ) {
                logInitialCall({ ...params, detectedUnfetchedUpdates: true });
                userProgressLoader.onUnfetchedUpdatesDetected(userProgressLoaderProgressMaxUpdatedAt);
            } else {
                logInitialCall({ ...params, detectedUnfetchedUpdates: false });
            }

            return response;
        }

        return {
            response(response) {
                // Some requests cannot handle async responses, so only
                // await the interceptor if this is an api request with push
                // messages in it.

                if (!response?.data?.meta?.push_messages) return response;

                // setProgressMaxUpdatedAt is a side effect, so it would be nice if we didn't have to wait for it.
                // But, in order for it to do its check, it needs to know the current value of userProgressLoader.getProgressMaxUpdatedAt
                // *before* a lesson_progress save request is handled and saved to IndexedDB. So, if this is a lesson_progress save request,
                // we have to wait for setProgressMaxUpdatedAt to finish. We could probably
                // speed things up here by wrapping RTKQuery around userProgressLoader.getProgressMaxUpdatedAt using
                // FrontRoyalStoreApi, or we could probably ONLY wait in the case that this is a lesson progress save call
                return setProgressMaxUpdatedAt(response).then(() => response);
            },
        };
    },
]);

angularModule.config([
    '$httpProvider',
    $httpProvider => {
        $httpProvider.interceptors.push('UserProgressLoaderInterceptor');
    },
]);
