import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import * as userAgentHelper from 'userAgentHelper';
import { saveAs } from 'file-saver';
import { fetchBrandConfig, Brand } from 'AppBranding';
import { targetBrand, targetBrandConfig } from 'AppBranding';
import moment from 'moment-timezone';
import getFilePathsFromManifest from 'WebpackManifestHelper';

import blueOceanCertificate from 'images/blue_ocean_certificate.png';

import proximaNovaSoftSemibold from 'fonts/ProximaNovaSoft-Semibold.ttf';
import proximaNovaSoftRegular from 'fonts/ProximaNovaSoft-Regular.ttf';

angularModule.factory('Stream.CertificateHelperMixin', [
    '$injector',

    $injector => {
        const Stream = $injector.get('Lesson.Stream');
        const $rootScope = $injector.get('$rootScope');
        const $q = $injector.get('$q');
        const $http = $injector.get('$http');
        const $window = $injector.get('$window');
        const $ocLazyLoad = $injector.get('$ocLazyLoad');
        const EventLogger = $injector.get('EventLogger');
        const TranslationHelper = $injector.get('TranslationHelper');
        const safeDigest = $injector.get('safeDigest');
        const Cohort = $injector.get('Cohort');
        const TextToImageHelper = $injector.get('TextToImageHelper');

        return {
            onLink(scope) {
                // Initialize certificateDownloadState
                scope.certificateDownloadState = {
                    downloadingCertificate: false,
                };

                const textToImageHelper = new TextToImageHelper();

                // NOTE: viewing the PDF on native mobile isn't a nice experience right now
                // disabling until we have time to make it better or think more about it.
                // additionally, downloading on iOS and iPadOS is entirely broken.
                const iOSoriPadOS = userAgentHelper.isiOSoriPadOSDevice();

                scope.allowCertificateDownload = stream =>
                    !($window.CORDOVA || iOSoriPadOS) &&
                    !stream.exam &&
                    !!scope.currentUser?.canDownloadStreamCertificateOrAddToLinkedInProfile;

                scope.allowProgramCertificateDownload = cohort =>
                    !($window.CORDOVA || iOSoriPadOS) && Cohort.supportsCertificateDownload(cohort.program_type);

                scope.setDownloading = downloading => {
                    if (downloading) {
                        scope.certificateDownloadState.downloadingCertificate = true;
                    } else {
                        scope.certificateDownloadState.downloadingCertificate = false;
                    }
                };

                scope.downloadProgramCertificate = programInclusion => {
                    const cohort = scope.currentUser.relevantCohort;

                    if (cohort.id !== programInclusion.cohortId) {
                        throw new Error(
                            'cohort ID for program inclusion does not match relevantCohort during program certificate download',
                        );
                    }

                    if (!scope.allowProgramCertificateDownload(cohort)) return;

                    scope.setDownloading(true);

                    EventLogger.log('student-dashboard:download-program-certificate', {
                        program_type: cohort.program_type,
                    });

                    scope.performProgramDownload({ ...programInclusion, cohort });
                };

                scope.downloadCertificate = stream => {
                    if (!scope.allowCertificateDownload(stream)) {
                        return;
                    }

                    scope.setDownloading(true);

                    EventLogger.log('lesson:stream:download_certificate_click', stream.logInfo());
                    scope.performStreamDownload(stream.id);
                };

                scope.performProgramDownload = programInclusionWithCohort => {
                    scope.performDownload('program', programInclusionWithCohort);
                };

                scope.performStreamDownload = streamId => {
                    scope.performDownload('stream', streamId);
                };

                scope.performDownload = (mode, modeData) => {
                    let stream;
                    let programInclusionWithCohort;
                    const requests = [];
                    let backgroundCertificateResponse;
                    let goldCertificateResponse;
                    let semiboldFontResponse;
                    let regularFontResponse;

                    const isProgram = mode === 'program';

                    // special case for BOS: we use a blue certificate with a hard-coded blue cert within it
                    const blueOcean =
                        !isProgram &&
                        modeData === '87b85177-fdf6-4cf8-a29a-c44b0ef77530' &&
                        $rootScope.currentUser.sign_up_code === 'BOSHIGH';

                    const paperCertURL = blueOcean
                        ? blueOceanCertificate
                        : targetBrandConfig($rootScope.currentUser).paperCertificate;

                    const textColor = blueOcean ? '#0077C0' : '#896600';

                    // FIXME: We don't have any error handling for any of the below $http.get requests.
                    // If any one of these requests fail, we end up with a corresponding "Possibly unhandled
                    // rejection" error in Sentry, which is difficult to debug. Additionally, any of these
                    // requests has the potential to fail if the referenced URL has changed and is no longer
                    // being cached by CloudFlare. We saw this happen for `/assets/images/paper_certificate.png`
                    // and `/assets/images/empty_cert_default.png` when the corresponding images' hashes were
                    // changed as a result of a slightly different compression algorithm. Any old clients
                    // referencing the older assets would then receive a 404 in response to their request.
                    //
                    // Maybe we should depend on the local filesystem here, instead of making an HTTP request?
                    // See also: vendor/front-royal/components/sound_manager/scripts/sound_manager.js

                    // get the pre-constructed stream certificate (lesson count, etc) or the static certificate based on mode
                    if (isProgram) {
                        const certAssetPath = targetBrandConfig(scope.currentUser).emptyCertDefault;

                        programInclusionWithCohort = modeData;
                        requests.push(
                            $http
                                .get(window.ENDPOINT_ROOT + certAssetPath, {
                                    responseType: 'arraybuffer',
                                })
                                .then(response => {
                                    goldCertificateResponse = response;
                                }),
                        );
                    } else {
                        requests.push(
                            Stream.getCachedOrShow(modeData, {
                                include_progress: true,
                            }).then(s => {
                                stream = s;
                                // blue ocean course has a built-in certificate and doesn't need the gold certificate
                                if (blueOcean) {
                                    return $q.when();
                                }

                                return $http
                                    .get(stream.lesson_streams_progress.certificate_image.formats.original.url, {
                                        responseType: 'arraybuffer',
                                    })
                                    .then(response => {
                                        goldCertificateResponse = response;
                                    });
                            }),
                        );
                    }

                    // grab the needed assets in parallel and cache them for rendering the PDF
                    getFilePathsFromManifest('certificates', 'js').forEach(path => {
                        requests.push($ocLazyLoad.load(path));
                    });

                    requests.push(
                        $http
                            .get(window.ENDPOINT_ROOT + paperCertURL, {
                                responseType: 'arraybuffer',
                            })
                            .then(response => {
                                backgroundCertificateResponse = response;
                            }),
                    );

                    // NOTE: I tried to change this to import the .woff font in /vendor/static/fonts, but the version
                    // of PDFKit we are pegged to (0.7.1) does not support .woff fonts.
                    requests.push(
                        $http
                            .get(window.ENDPOINT_ROOT + proximaNovaSoftSemibold, {
                                responseType: 'arraybuffer',
                            })
                            .then(response => {
                                semiboldFontResponse = response;
                            }),
                    );

                    // NOTE: I tried to change this to import the .woff font in /vendor/static/fonts, but the version
                    // of PDFKit we are pegged to (0.7.1) does not support .woff fonts.
                    requests.push(
                        $http
                            .get(window.ENDPOINT_ROOT + proximaNovaSoftRegular, {
                                responseType: 'arraybuffer',
                            })
                            .then(response => {
                                regularFontResponse = response;
                            }),
                    );

                    $q.all(requests).then(() => {
                        const translationHelper = new TranslationHelper('lessons.stream.certificate_helper_mixin');

                        // Algorithm for using a temp Pdf object to do text resizing
                        // See: https://github.com/devongovett/pdfkit/issues/289
                        const temp = new $window.PDFDocument({
                            margin: 0,
                        });

                        function _measureHeight(text, fontSize, width) {
                            temp.fontSize(fontSize);
                            temp.x = 0;
                            temp.y = 0;
                            temp.text(text, {
                                width,
                            });

                            return temp.y;
                        }

                        // Scales the fontSize down to a minimum threshold to try and fit text within the
                        // specified bounds
                        function _fittedSize(text, fontSize, min, step, bounds) {
                            if (fontSize <= min) {
                                return {
                                    fontSize: min,
                                    height: _measureHeight(text, fontSize, bounds.width),
                                };
                            }

                            const height = _measureHeight(text, fontSize, bounds.width);

                            if (height <= bounds.height) {
                                return {
                                    fontSize,
                                    height,
                                };
                            }

                            return _fittedSize(text, fontSize - step, min, step, bounds);
                        }

                        function _unsanitizeTranslation(text) {
                            return $('<textarea />').html(text).text();
                        }

                        // Order matters and there doesn't seem to be a way to specify layers so used the cached
                        // assets to assemble the PDF here in the correct order.

                        // create the pdfkit Document
                        const doc = new $window.PDFDocument({
                            size: [792, 612],
                        });
                        const pdfStream = doc.pipe($window.blobStream());

                        const textIsASCII = textToImageHelper.isASCII(
                            _unsanitizeTranslation(translationHelper.get('confirms')),
                        );

                        // set the background
                        doc.image(backgroundCertificateResponse.data, 0, 0, {
                            width: 792,
                            height: 612,
                        });

                        // set the gold certificate if not blue ocean
                        if (!blueOcean) {
                            doc.image(goldCertificateResponse.data, 508, 231, {
                                width: 230,
                                height: 150,
                            });
                        }

                        const branding = targetBrand($rootScope.currentUser);
                        const brandConfig = fetchBrandConfig(branding);

                        // set the static text
                        let offeredTextKey;
                        if (blueOcean) {
                            offeredTextKey = 'offered_bos_high_school';
                        } else if (isProgram) {
                            // The institutionName for Quantic is longer than the others, so
                            // we need a separate locale key in order to add a line break.
                            offeredTextKey = branding === Brand.quantic ? 'offered_program_quantic' : 'offered_program';
                        } else {
                            offeredTextKey = branding === Brand.quantic ? 'offered_quantic' : 'offered';
                        }

                        // date
                        const dateVal = isProgram
                            ? programInclusionWithCohort.graduatedAt
                            : stream.lesson_streams_progress.completed_at;
                        const dateText = _unsanitizeTranslation(moment(dateVal * 1000).format('LL'));
                        const dateSizes = _fittedSize(dateText, 20, 12, 2, {
                            width: 190,
                            height: 26,
                        });
                        const dateY = isProgram ? 116 : 126;

                        if (textIsASCII) {
                            doc.font(regularFontResponse.data)
                                .fontSize(16)
                                .fillColor(textColor)
                                .text(_unsanitizeTranslation(translationHelper.get('confirms')), 53, 216)
                                .text(_unsanitizeTranslation(translationHelper.get('completed')), 53, 297)
                                .text(
                                    _unsanitizeTranslation(
                                        translationHelper.get(offeredTextKey, {
                                            institutionName:
                                                branding === Brand.valar
                                                    ? brandConfig.brandNameStandard
                                                    : brandConfig.brandNameLong,
                                        }),
                                    ),
                                    53,
                                    375,
                                )
                                .fontSize(10)
                                .text(
                                    _unsanitizeTranslation(
                                        translationHelper.get('authenticity', {
                                            brandEmail: targetBrandConfig(
                                                $rootScope.currentUser,
                                            ).emailAddressForUsername('certificates'),
                                        }),
                                    ),
                                    0,
                                    579,
                                    {
                                        // weird, not adding height was pushing it to the second page -- https://github.com/devongovett/pdfkit/issues/149
                                        height: 12,
                                        width: 792,
                                        align: 'center',
                                    },
                                )
                                .fontSize(30)
                                .fillColor(blueOcean ? textColor : '#FFBE00');

                            doc.font(regularFontResponse.data)
                                .fontSize(dateSizes.fontSize)
                                .fillColor(textColor)
                                .text(dateText, 535, dateY, {
                                    width: 190,
                                    align: 'center',
                                });
                        } else {
                            doc.image(
                                textToImageHelper.getSingleLineImageText(
                                    _unsanitizeTranslation(translationHelper.get('confirms')),
                                    {
                                        width: 435,
                                        fontSize: 16,
                                        fillColor: textColor,
                                    },
                                ),
                                53,
                                216,
                                {
                                    width: 435,
                                },
                            );
                            doc.image(
                                textToImageHelper.getSingleLineImageText(
                                    _unsanitizeTranslation(translationHelper.get('completed')),
                                    {
                                        width: 435,
                                        fontSize: 16,
                                        fillColor: textColor,
                                    },
                                ),
                                53,
                                297,
                                {
                                    width: 435,
                                },
                            );
                            doc.image(
                                textToImageHelper.getSingleLineImageText(
                                    _unsanitizeTranslation(translationHelper.get(offeredTextKey)),
                                    {
                                        width: 435,
                                        fontSize: 16,
                                        fillColor: textColor,
                                    },
                                ),
                                53,
                                375,
                                {
                                    width: 435,
                                },
                            );
                            doc.image(
                                textToImageHelper.getSingleLineImageText(
                                    _unsanitizeTranslation(
                                        translationHelper.get('authenticity', {
                                            brandEmail: targetBrandConfig(
                                                $rootScope.currentUser,
                                                undefined,
                                            ).emailAddressForUsername('certificates'),
                                        }),
                                    ),
                                    {
                                        width: 792,
                                        fontSize: 10,
                                        align: 'center',
                                        fillColor: textColor,
                                    },
                                ),
                                285,
                                579,
                                {
                                    // weird, not adding height was pushing it to the second page -- https://github.com/devongovett/pdfkit/issues/149
                                    width: 792,
                                    align: 'center',
                                },
                            );
                            doc.image(
                                textToImageHelper.getSingleLineImageText(
                                    _unsanitizeTranslation(translationHelper.get('completion')),
                                    {
                                        width: 90,
                                        fontSize: 30,
                                        align: 'center',
                                        fillColor: blueOcean ? textColor : '#FFBE00',
                                    },
                                ),
                                605,
                                93,
                                {
                                    width: 90,
                                },
                            );

                            doc.image(
                                textToImageHelper.getSingleLineImageText(dateText, {
                                    width: 190,
                                    fontSize: dateSizes.fontSize,
                                    align: 'center',
                                    fillColor: textColor,
                                }),
                                570,
                                dateY,
                                { width: 190 },
                            );
                        }

                        if (isProgram && textIsASCII) {
                            doc.text(_unsanitizeTranslation(translationHelper.get('certificate')), 535, 86, {
                                width: 190,
                                align: 'center',
                            });
                        } else if (!isProgram && textIsASCII) {
                            doc.text(_unsanitizeTranslation(translationHelper.get('proof_of')), 535, 66, {
                                width: 190,
                                align: 'center',
                            }).text(_unsanitizeTranslation(translationHelper.get('completion')), 500, 96, {
                                width: 260, // wider to accomodate languages other than English
                                align: 'center',
                            });
                        } else if (isProgram && !textIsASCII) {
                            doc.image(
                                textToImageHelper.getSingleLineImageText(
                                    _unsanitizeTranslation(translationHelper.get('certificate')),
                                    {
                                        height: 12,
                                        width: 190,
                                        fontSize: dateSizes.fontSize,
                                        align: 'center',
                                        fillColor: blueOcean ? textColor : '#FFBE00',
                                    },
                                ),
                                605,
                                83,
                                {
                                    width: 190,
                                },
                            );
                        } else {
                            doc.image(
                                textToImageHelper.getSingleLineImageText(
                                    _unsanitizeTranslation(translationHelper.get('proof_of')),
                                    {
                                        width: 90,
                                        fontSize: 30,
                                        align: 'center',
                                        fillColor: blueOcean ? textColor : '#FFBE00',
                                    },
                                ),
                                605,
                                63,
                                {
                                    width: 90,
                                },
                            );
                        }

                        // set the dynamic text
                        const fullHeight = 56; // full height of the area between the subtexts

                        // name
                        const nameBounds = {
                            width: 435,
                            height: 50,
                        };

                        if (textToImageHelper.isASCII($rootScope.currentUser.name)) {
                            const nameSizes = _fittedSize($rootScope.currentUser.name, 42, 12, 2, nameBounds);
                            const nameOffset = (fullHeight - nameSizes.height) / 2; // center vertically
                            doc.font(semiboldFontResponse.data)
                                .fontSize(nameSizes.fontSize)
                                .fillColor('#000000')
                                .text($rootScope.currentUser.name, 53, 236 + nameOffset, {
                                    // if we specify a width here, there's a small chance it could wrap if the
                                    // fitted text size is slightly too large. So, we don't specify a width,
                                    // which tells PDFKit to let the text stay on one line
                                    height: 50,
                                });
                        } else {
                            doc.image(
                                textToImageHelper.getSingleLineImageText($rootScope.currentUser.name, {
                                    width: 435,
                                    fontSize: 42,
                                    font: 'Courier-Bold',
                                }),
                                53,
                                236,
                                {
                                    width: 435,
                                },
                            );
                        }

                        // title
                        const titleBounds = {
                            width: 435,
                            height: 34,
                        };
                        const titleText = isProgram
                            ? programInclusionWithCohort.cohort.title.toUpperCase()
                            : stream.title.toUpperCase();
                        const titleSizes = _fittedSize(titleText, 28, 12, 2, titleBounds);
                        const titleOffset = (fullHeight - titleSizes.height) / 2; // center vertically

                        if (textToImageHelper.isASCII(titleText)) {
                            doc.font(semiboldFontResponse.data)
                                .fontSize(titleSizes.fontSize)
                                .text(titleText, 53, 317 + titleOffset, {
                                    width: 435,
                                });
                        } else {
                            // Note: We shouldn't actually be using this clause yet because we don't have Chinese-titled
                            // content and we aren't allowing dynamically generated Arabic certificates yet.
                            doc.image(
                                textToImageHelper.getSingleLineImageText(titleText, {
                                    width: 435,
                                    fontSize: 42,
                                    font: 'Courier-Bold',
                                }),
                                53,
                                317 + titleOffset,
                                {
                                    width: 435,
                                },
                            );
                        }

                        // set the finish event
                        pdfStream.on('finish', () => {
                            const blob = pdfStream.toBlob('application/pdf');
                            try {
                                const fileName = `${[$rootScope.currentUser.name, titleText, 'Certificate'].join(
                                    '-',
                                )}.pdf`;

                                saveAs(blob, fileName);

                                scope.setDownloading(false);
                                safeDigest(scope);
                            } catch (e) {
                                $injector
                                    .get('ErrorLogService')
                                    .notify('Failed to write certificate blob data to window!', e);
                            }
                        });

                        doc.end();
                    });
                };
            },
        };
    },
]);
