import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/shared/in_dom_style_preloader.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('inDomStylePreloader', [
    '$injector',
    () => ({
        restrict: 'E',
        templateUrl,

        link() {},
    }),
]);
