import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import 'FrontRoyalUiBootstrap/tooltip';
import template from 'Lessons/angularModule/views/stream/playlist_map_stream_box.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('playlistMapStreamBox', [
    '$injector',

    function factory($injector) {
        const StreamActivationHelperMixin = $injector.get('StreamActivationHelperMixin');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                stream: '<',
            },
            link(scope) {
                StreamActivationHelperMixin.onLink(scope);
            },
        };
    },
]);
