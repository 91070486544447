import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/stream/stream_dashboard_next_up.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('streamDashboardNextUp', [
    '$injector',

    function factory($injector) {
        const Stream = $injector.get('Lesson.Stream');
        const RouteAnimationHelper = $injector.get('RouteAnimationHelper');
        const ContentAccessHelper = $injector.get('ContentAccessHelper');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                stream: '<',
                whenEmpty: '<',
            },
            link(scope) {
                let contentAccessHelper;

                //----------------------------
                // Misc Scope Logic
                //----------------------------

                scope.launch = () => {
                    // There are no expected cases where you can get a keep learning box
                    // with an unlaunchable lesson, but just to be sure. (To repro, start
                    // a stream and then change it to coming soon and go to the stream
                    // dashboard)
                    if (!contentAccessHelper.canLaunch) {
                        return;
                    }

                    scope.lesson.launch(`keep_learning_${scope.lesson.launchText}`);
                };

                scope.formattedLessonMinutes = lesson => String.padNumber(Math.ceil(lesson.approxLessonMinutes), 2);

                scope.openCourses = () => {
                    RouteAnimationHelper.animatePathChange('/courses', 'slide-left');
                };

                //----------------------------
                // Watches
                //----------------------------

                scope.$watch('stream', stream => {
                    scope.lesson = Stream.keepLearningLesson(stream);
                    contentAccessHelper = scope.lesson ? new ContentAccessHelper(scope.lesson) : null;
                });
            },
        };
    },
]);
