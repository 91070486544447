import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory('Stream.SummaryHelperMixin', [
    '$injector',

    $injector => {
        const EventLogger = $injector.get('EventLogger');

        return {
            onLink(scope) {
                scope.onLessonSummaryClick = (summary, stream, event) => {
                    event.stopImmediatePropagation();
                    EventLogger.log(
                        'course_summary_view',
                        angular.extend(
                            {
                                summary_id: summary.id,
                                label: summary.title,
                            },
                            stream.logInfo(),
                        ),
                    );
                    scope.loadUrl(summary.url, '_blank');
                };
            },
        };
    },
]);
