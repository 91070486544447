import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory('Lesson.Stream.StreamViewModel', [
    'AClassAbove',
    'guid',

    (AClassAbove, guid) =>
        AClassAbove.subclass(function () {
            /* ****************************************
        Publicly Accessible Properties
    ******************************************* */
            Object.defineProperty(this.prototype, 'activeLessonId', {
                get() {
                    return this.activeLesson && this.activeLesson.id;
                },
                set(val) {
                    this.activeLesson = this.stream.lessonForId(val);
                    return this.activeLesson.id;
                },
            });

            Object.defineProperty(this.prototype, 'activeFrameId', {
                get() {
                    return this.activePlayerViewModel && this.activePlayerViewModel.activeFrameId;
                },
                set(val) {
                    this.activePlayerViewModel.activeFrameId = val;
                },
            });

            Object.defineProperty(this.prototype, 'activeLesson', {
                get() {
                    if (!this._activeLesson) {
                        this._activeLesson = this.stream.orderedLessons[0];
                    }
                    return this._activeLesson;
                },
                set(lesson) {
                    if (lesson.stream() !== this.stream) {
                        throw new Error('Unexpected stream for lesson');
                    }
                    this._activeLesson = lesson;
                },
            });

            Object.defineProperty(this.prototype, 'activePlayerViewModel', {
                get() {
                    // if not activeLesson, then no activePlayerViewModel
                    if (!this.activeLesson) {
                        this._activePlayerViewModel = undefined;
                        return this._activePlayerViewModel;

                        // if there is already an activePlayerViewModel and the activeLesson has not changed, then keep using it
                    }
                    if (this._activePlayerViewModel && this.activeLesson === this._activePlayerViewModel.lesson) {
                        return this._activePlayerViewModel;

                        // create a new activePlayerViewModel if we don't have one yet for this lesson
                    }
                    this._activePlayerViewModel = this.activeLesson.createPlayerViewModel({
                        logProgress: this.logProgress,
                        scormMode: this.scormMode,
                    });
                    return this._activePlayerViewModel;
                },
            });

            return {
                initialize(stream, options) {
                    this.stream = stream;
                    this.id = guid.generate();
                    options = options || {};

                    this.logProgress = options.logProgress !== false;
                    this.scormMode = options.scormMode || false;
                },
            };
        }),
]);
