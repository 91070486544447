import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory('Lesson.LessonVersion', [
    'Iguana',
    Iguana =>
        Iguana.subclass(function () {
            this.alias('LessonVersion');
            this.embeddedIn('lesson');

            Object.defineProperty(this.prototype, 'currentlyPublished', {
                get() {
                    return this.lesson().published_version_id === this.version_id;
                },
            });

            Object.defineProperty(this.prototype, 'isMostRecentVersion', {
                get() {
                    return this.lesson().mostRecentVersion === this;
                },
            });

            Object.defineProperty(this.prototype, 'editorName', {
                get() {
                    const name = this.last_editor && this.last_editor.name;
                    return name || 'Unknown';
                },
            });
        }),
]);
