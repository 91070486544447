import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/stream/upload_unofficial_transcripts_modal.html';
import tooltipTemplate from 'Lessons/angularModule/views/stream/upload_unofficial_transcripts_modal_tooltip.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

// See comment near autoSuggestDropdownTemplate in organization_autocomplete_dir.js.
// We have the same issue here
cacheAngularTemplate(
    angularModule,
    'uploadUnofficialTranscriptsModal/upload_unofficial_transcripts_modal_tooltip.html',
    tooltipTemplate,
);

angularModule.directive('uploadUnofficialTranscriptsModal', [
    '$injector',

    function factory($injector) {
        const DialogModal = $injector.get('DialogModal');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');

        return {
            restrict: 'E',
            scope: {},
            templateUrl,
            link(scope) {
                NavigationHelperMixin.onLink(scope);

                scope.closeModal = () => {
                    DialogModal.hideAlerts();
                };

                scope.goToDocuments = () => {
                    scope.loadRoute('/settings/documents');
                    scope.closeModal();
                };
            },
        };
    },
]);
