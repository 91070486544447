import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import 'ExtensionMethods/array';
import transformKeyCase from 'Utils/transformKeyCase';

angularModule.factory('Lesson.FrameList.Frame', [
    'Iguana',
    'guid',
    'DeepExtend',
    '$q',
    (Iguana, guid, DeepExtend, $q) =>
        Iguana.subclass(function () {
            this.alias('Lesson.FrameList.Frame');
            this.setSciProperty('frame_type');
            this.embeddedIn('lesson');

            this.setCallback('after', 'copyAttrsOnInitialize', function () {
                this.annotations = this.annotations || {};
            });

            // overridden in componentized frame type
            Object.defineProperty(this.prototype, 'label', {
                get() {
                    return `Frame ${this.index() + 1}`;
                },
            });

            // overridden in componentized frame type
            Object.defineProperty(this.prototype, 'miniInstructions', {
                get() {
                    throw new Error('miniInstructions should be implemented in subclasses of Frame');
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'tutorBotDescription', {
                get() {
                    return this.tutor_bot_description;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'isPractice', {
                get() {
                    if (this.lesson() && this.lesson().isPractice) {
                        return true;
                    }

                    // when this frame is included in practice_frames, then it has
                    // to be marked as isPractice
                    return this.$$isPractice || false;
                },
                set(val) {
                    this.$$isPractice = val;
                },
            });

            Object.defineProperty(this.prototype, 'mainImage', {
                get() {
                    throw new Error('subclasses of Frame must implement mainImage');
                },
                set() {
                    throw new Error('subclasses of Frame must implement mainImage');
                },
                configurable: true, // specs
            });

            this.extend({
                // TODO: move this to iguana
                // this is here so that angular.forEach doesn't think all the frametype subclasses are
                // the same b/c angular uses toString for object equality
                toString() {
                    return this.alias();
                },

                // Return all of the subclasses that can actually be instantiated and saved into
                // the database, filtering out abstract classes
                subTypes() {
                    let klasses = [];
                    angular.forEach(this.subclasses, subclass => {
                        if (subclass.alias()) {
                            klasses.push(subclass);
                        }
                        klasses = klasses.concat(subclass.subTypes());
                    });
                    return klasses;
                },

                FrameViewModel: null, // subclasses should defined this

                title: null,
                thumbnail: null,
            });

            this.extendableObject('defaultAttrs');

            return {
                // subclass like 'no_interaction' for example must override this
                directiveName: null,
                reified: true,

                initialize($super, attrs) {
                    attrs = DeepExtend.extend(angular.copy(this.constructor.defaultAttrs()), attrs);
                    $super(attrs);

                    // FIXME: move this into AClassAbove.  Se objectId() below
                    this.$$objectId = guid.generate();
                    this.$$previewId = guid.generate();
                    this.id = this.id || guid.generate();

                    // FIXME: define getters and setters on the protoype.  Not in the initialize.

                    // FIXME: move this into iguana. See how it's used in edit_frame_list.
                    // It allows us to set all of the attribues at once, which allows us to
                    // bind that to a json input.  BUG: If attributes are removed, however, they
                    // will still hang around.
                    Object.defineProperty(this, '$$attributes', {
                        get() {
                            return this.asJson();
                        },
                        set(_attrs) {
                            this.copyAttrs(_attrs);
                        },
                    });
                },

                // see also: frame_list.js:reify()
                reify() {
                    return this;
                },

                preloadImages() {
                    // overridden in componentized. Just need resolved promise to make pass
                    return $q.when();
                },

                prevFrame() {
                    return this.lesson().frames[this.index() - 1];
                },

                nextFrame() {
                    return this.lesson().frames[this.index() + 1];
                },

                // FIXME: move this into Iguana
                index() {
                    return this.lesson().frames.indexOf(this);
                },

                // 1-indexed for prettiness in editor
                displayIndex() {
                    return this.index() + 1;
                },

                imageForLabel(label) {
                    if (!this.images) {
                        return undefined;
                    }

                    // eslint-disable-next-line no-restricted-syntax
                    for (const image of this.images) {
                        if (image.label === label) {
                            return image;
                        }
                    }

                    return undefined;
                },

                hasSection(section) {
                    return this.layout().includes(section);
                },

                /*
                'layout' is a description of the sections that exist in the frame.
                possible values are
                ['text','main-image','interactive']
                ['text','interactive']
            */
                layout() {
                    return ['text', 'main-image', 'interactive'];
                },

                addImage() {
                    this.images = this.images || [];
                    const obj = {
                        id: guid.generate(),
                    };
                    this.images.push(obj);
                    return obj;
                },

                removeImage(image) {
                    Array.remove(this.images, image);
                },

                deleteComment(index) {
                    this.author_comments.splice(index, 1);
                },

                addComment(currentUser) {
                    if (!this.$$author_comment || !this.$$author_comment.trim()) {
                        return;
                    }

                    const comment = {
                        text: this.$$author_comment,
                        timestamp: new Date().getTime(),
                        author_id: currentUser.id,
                        author_name: currentUser.name,
                    };

                    this.author_comments = this.author_comments || [];
                    this.author_comments.push(comment);

                    this.$$author_comment = undefined;
                },

                toggleCommentCompleted(index) {
                    const comment = this.author_comments[index];
                    comment.completed = !comment.completed;
                },

                createFrameViewModel(options) {
                    return new this.constructor.FrameViewModel(this, options);
                },

                toJasminePP() {
                    return `frame "${this.id}"`;
                },

                duplicate() {
                    const attrs = this.asJson();
                    // null out frame id, frame.js will populate it, if its not there
                    attrs.id = '';
                    return this.constructor.new(attrs);
                },

                getKeyTerms() {
                    throw new Error('Subclasses of Frame should define getKeyTerms');
                },

                useAlteredUrl(origUrl, alteredUrl) {
                    this.$$_alteredUrls = this.$$_alteredUrls || {};
                    this.$$_alteredUrls[origUrl] = alteredUrl;
                },

                getAlteredUrl(origUrl) {
                    this.$$_alteredUrls = this.$$_alteredUrls || {};
                    return this.$$_alteredUrls[origUrl] || origUrl;
                },

                expandExtraPanelsInitially() {
                    this.$$expandExtraPanelsInitially = true;
                },

                logInfo() {
                    return {
                        frame_id: this.id,
                        frame_index: this.index(),
                        editor_template: this.mainUiComponent ? this.mainUiComponent.editor_template : null,
                    };
                },

                preloadAssets() {
                    const frame = this;
                    return this.preloadImages().then(() => {
                        // provide a flag for our visual diff tool to validate on
                        frame.$$preloadingComplete = true;
                    });
                },

                containsText(text) {
                    return text && this.text_content && this.text_content.toLowerCase().includes(text.toLowerCase());
                },

                recursivelyGetImageUrls() {
                    // overridden in componentized. Just need resolved promise to make pass
                    return $q.when();
                },

                resetTutorBotDescription() {
                    this.tutor_bot_description = transformKeyCase(this.generateTutorBotDescription(), {
                        to: 'snakeCase',
                    });
                },

                generateTutorBotDescription() {
                    throw new Error('tutorBotDescription should be implemented in subclasses of Frame');
                },

                _hasMainImage() {
                    return !!this.mainImage;
                },
            };
        }),
]);
