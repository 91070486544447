import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory('Stream.StreamDashboardDirHelper', [
    () => {
        const StreamDashboardDirHelper = {
            onLink(scope) {
                scope.chapterPercent = stream => {
                    if (!stream) {
                        return 0;
                    }
                    return (100 * (stream.currentChapter().index + 1)) / stream.chapters.length;
                };
                scope.lessonsCompletePercent = stream => {
                    if (!stream) {
                        return 0;
                    }
                    return (100 * stream.lessonsCompletedCount) / stream.lessons.length;
                };
            },
        };

        return StreamDashboardDirHelper;
    },
]);
