import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import { setupStyleHelpers } from 'AppBranding';
import template from 'Lessons/angularModule/views/stream/stream_completed_link_box.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

import summaryIcon from 'vectors/summary.svg';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('streamCompletedLinkBox', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const RouteAnimationHelper = $injector.get('RouteAnimationHelper');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const CertificateHelperMixin = $injector.get('Stream.CertificateHelperMixin');
        const $filter = $injector.get('$filter');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                stream: '<',
            },
            link(scope) {
                scope.summaryIcon = summaryIcon;

                NavigationHelperMixin.onLink(scope);
                CertificateHelperMixin.onLink(scope);
                setupStyleHelpers($injector, scope);

                // Referenced inside of AppBrandMixin.
                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                scope.completedDateFormatted = $filter('amDateFormat')(
                    scope.stream.lesson_streams_progress.completed_at * 1000,
                    'LL',
                );

                scope.getStreamInfo = stream => {
                    RouteAnimationHelper.animatePathChange(stream.streamDashboardPath, 'slide-left');
                };

                scope.onLessonSummaryClick = (summary, event) => {
                    event.stopImmediatePropagation();
                    scope.loadUrl(summary.url, '_blank');
                };
            },
        };
    },
]);
