import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/stream/course_list_box.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('courseListBox', [
    '$injector',

    function factory() {
        return {
            restrict: 'E',
            templateUrl,
            scope: {
                courses: '<',
                showCaption: '<',
                eventName: '<',
                openStreamDashboard: '<',
                streamAvailable: '<',
                limit: '<',
            },
        };
    },
]);
