import angularModule from 'Lessons/angularModule/scripts/lessons_module';
// Odd that the template is in the Playlists model, but the directive
// is in the Lessons model.  Should probably be fixed.
import template from 'Lessons/angularModule/views/stream/playlist_box.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('playlistBox', [
    '$injector',

    function factory() {
        return {
            restrict: 'E',
            templateUrl,
            scope: {
                playlistInfo: '<',
                overrideActive: '<',
            },
            link() {},
        };
    },
]);
