import cacheAngularTemplate from 'cacheAngularTemplate';
import { DisconnectedError } from 'DisconnectedError';
import { orderBy, groupBy, keyBy, filter, flow } from 'lodash/fp';
import { targetBrandConfig } from 'AppBranding';
import { getRelevantCohorts } from 'Users';
import template from '../../views/stream/stream_dashboard_external_links.html';
import angularModule from '../lessons_module';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('streamDashboardExternalLinks', [
    '$injector',
    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const ExternalLinkMetadatum = $injector.get('ExternalLinkMetadatum');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const EventLogger = $injector.get('EventLogger');
        const isMobileMixin = $injector.get('isMobileMixin');

        return {
            scope: {
                stream: '<',
            },
            restrict: 'E',
            templateUrl,
            controllerAs: 'controller',
            link(scope) {
                //---------------------------
                // Initialization
                //---------------------------

                NavigationHelperMixin.onLink(scope);
                isMobileMixin.onLink(scope);

                scope.lessonsById = keyBy('id')(scope.stream.lessons);
                scope.recordsForStream = [];
                scope.institutionBrandName = targetBrandConfig($rootScope.currentUser).brandNameShort;

                //---------------------------
                // Data Loading
                //---------------------------

                ExternalLinkMetadatum.getAllForStream(
                    scope.stream.id,

                    // Use all the user's cohorts, since a user can view streams that are not attached to their
                    // active cohort
                    getRelevantCohorts($rootScope.currentUser).map(c => c.id),
                    {
                        'FrontRoyal.ApiErrorHandler': { background: true },
                    },
                )
                    .then(
                        records => {
                            scope.recordsForStream = flow(
                                filter('isWorking'),
                                orderBy(['required', 'sort'])(['desc', 'asc']),
                            )(records);
                            scope.recordsByLessonId = groupBy('lesson_id')(scope.recordsForStream);
                        },
                        () => {
                            // no-op
                        },
                    )
                    .catch(err => {
                        if (err.constructor !== DisconnectedError) {
                            throw err;
                        }
                    });

                //---------------------------
                // Functions
                //---------------------------

                scope.openExternalLink = ($event, url) => {
                    $event.preventDefault(); // don't follow href attribute, which is only there for a11y
                    EventLogger.log('stream_dashboard_external_links:clicked_external_link', { link: url });
                    scope.loadUrl(url, '_blank');
                };

                scope.setExpanded = shouldExpand => {
                    scope.isExpanded = shouldExpand;
                };

                //---------------------------
                // Watchers
                //---------------------------

                scope.$watch('isMobile', isMobile => {
                    scope.isExpanded = !isMobile;
                });
            },
        };
    },
]);
