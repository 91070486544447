/* eslint-disable no-restricted-syntax */
import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import 'ExtensionMethods/array';

angularModule.factory('Lesson.FrameList', [
    '$injector',
    $injector => {
        const Lesson = $injector.get('Lesson');
        const Frame = $injector.get('Lesson.FrameList.Frame');
        const FrameListPlayerViewModel = $injector.get('Lesson.FrameList.FrameListPlayerViewModel');

        return Lesson.subclass(function () {
            this.title = 'Frame List';
            this.alias('frame_list');
            this.embedsMany('frames', 'Lesson.FrameList.Frame');
            this.embedsMany('practice_frames', 'Lesson.FrameList.Frame');

            this.prototype._beforeProcessEmbeds = function (prop) {
                // don't attempt to re-build frames if they're not provided in the response, as they
                // might already exist in the instance
                // eslint-disable-next-line no-prototype-builtins
                if (!this.$$sourceAttrs.hasOwnProperty(prop)) {
                    return undefined;
                }
                const frameObjs = this.$$sourceAttrs[prop];
                const preloadCount = 0;
                this.$$sourceAttrs[prop] = frameObjs ? frameObjs.splice(0, preloadCount) : undefined;
                return frameObjs;
            };

            this.prototype._afterProcessEmbeds = function (prop, frameObjs) {
                const lesson = this;
                if (frameObjs) {
                    // this method is defined within the actual model in order to allow successive calls without error
                    frameObjs.forEach((frameObj, i) => {
                        frameObj.reify = () => {
                            delete frameObj.reify;
                            // eslint-disable-next-line no-multi-assign
                            const frame = (lesson[prop][i] = Frame.new(frameObj));

                            if (prop === 'practice_frames') {
                                frame.isPractice = true;
                            }

                            frame.$$embeddedIn = lesson;

                            return frame;
                        };
                    });

                    lesson.$$sourceAttrs[prop] = this.$$sourceAttrs[prop].concat(frameObjs);
                }
            };

            this.setCallback('around', 'processEmbeds', function (fn) {
                const objsForFrames = this._beforeProcessEmbeds('frames');
                const objsForPracticeFrames = this._beforeProcessEmbeds('practice_frames');
                fn();
                this._afterProcessEmbeds('practice_frames', objsForPracticeFrames);
                this._afterProcessEmbeds('frames', objsForFrames);
            });

            this.setCallback('after', 'copyAttrsOnInitialize', function () {
                this.frames = this.frames || [];
                this.frame_count = this.frame_count || this.frames.length;
            });

            this.extend({
                PlayerViewModel: FrameListPlayerViewModel,
            });

            this.setCallback('before', 'save', function () {
                this.frame_count = this.frames ? this.frames.length : undefined;
                if (!this.frames) {
                    return;
                }
                this.frames.forEach(frame => {
                    if (frame.beforeSave) {
                        frame.beforeSave();
                    }
                });
                this.resetKeyTerms();
                this.resetTutorBotDescriptions();
            });

            Object.defineProperty(this.prototype, 'hasFrames', {
                get() {
                    if (!this.frames) {
                        return false;
                    }
                    return this.frames.length > 0;
                },
            });

            Object.defineProperty(this.prototype, 'allContentLoaded', {
                get() {
                    return this.hasFrames;
                },
            });

            Object.defineProperty(this.prototype, '$$relatedStreams', {
                get() {
                    if (!this.$$_relatedStreams) {
                        this.$$_relatedStreams = [];
                    }
                    return this.$$_relatedStreams;
                },
                set(value) {
                    this.$$_relatedStreams = value;
                },
            });

            Object.defineProperty(this.prototype, 'frameIds', {
                get() {
                    return this.frames.map(f => f.id);
                },
            });

            Object.defineProperty(this.prototype, 'approxLessonMinutes', {
                get() {
                    if (!this.frame_count) {
                        return 0;
                    }
                    // see comment on https://trello.com/c/HMAS1zoR/218-feat-improve-calculations-of-the-average-lesson-time-based-on-real-usage
                    // and also https://sqlpad.pedago-tools.com/queries/BfqJQZc30UN46uTL
                    const estimatedSecondsPerSlide = 21;
                    const estimatedMinutesPerSlide = estimatedSecondsPerSlide / 60;
                    return this.frame_count * estimatedMinutesPerSlide;
                },
            });

            Object.defineProperty(this.prototype, 'frame_bookmark_id', {
                get() {
                    return this.lesson_progress && this.lesson_progress.frame_bookmark_id;
                },
                set(val) {
                    this.ensureLessonProgress().frame_bookmark_id = val;
                },
            });

            Object.defineProperty(this.prototype, 'possiblePaths', {
                get() {
                    /* eslint-disable no-loop-func */
                    const pointers = this.createNavigationPointers();
                    const completePaths = [];
                    const partialPaths = [];
                    const lesson = this;
                    this.frames.forEach(frame => {
                        frame.reify();
                    });

                    // add a frame to the end of a partial path
                    function concatPartialPath(partialPath, frame) {
                        const frameIdsClone = Object.create(partialPath.frameIds);
                        const clone = {
                            frames: partialPath.frames.concat([frame]),
                            frameIds: frameIdsClone,
                            loops: false,
                        };
                        if (partialPath.frameIds[frame.id]) {
                            clone.loops = true;
                        } else {
                            frameIdsClone[frame.id] = true;
                        }
                        return clone;
                    }

                    // determine what the next frames could be after the
                    // end of this path
                    function nextFramesForPath(partialPath) {
                        const lastFrame = _.last(partialPath.frames);
                        const pointer = pointers[lastFrame.id];
                        let nextFrames = [];

                        if (pointer.to.length > 0) {
                            nextFrames = pointer.to.map(frameId => lesson.frameForId(frameId));
                        } else {
                            const nextFrame = lesson.frames[lastFrame.index() + 1];
                            if (nextFrame) {
                                nextFrames.push(nextFrame);
                            }
                        }

                        return nextFrames;
                    }

                    if (this.frames[0]) {
                        partialPaths.push(
                            concatPartialPath(
                                {
                                    frames: [],
                                    frameIds: {},
                                },
                                this.frames[0],
                            ),
                        );
                    }

                    let i = 0;
                    // As long as there are partialPaths, grab one of them and
                    // either add the next frame to it or, in the branching case,
                    // split it into multiple paths.  When a path loops back on itself
                    // or reaches the end of the frame list, add it to the completePaths
                    // list
                    while (partialPaths.length > 0 && i < 100000) {
                        i += 1;
                        const partialPath = partialPaths.pop();
                        const nextFrames = nextFramesForPath(partialPath);

                        if (nextFrames.length > 0) {
                            nextFrames.forEach(nextFrame => {
                                const newPath = concatPartialPath(partialPath, nextFrame);
                                if (newPath.loops) {
                                    completePaths.push(newPath);
                                } else {
                                    partialPaths.push(newPath);
                                }
                            });
                        } else {
                            completePaths.push(partialPath);
                        }
                    }

                    // remove the frameIds map from the response for simplicity
                    return completePaths.map(path => ({
                        loops: path.loops,
                        frames: path.frames,
                    }));
                    /* eslint-enable no-loop-func */
                },
            });

            Object.defineProperty(this.prototype, 'shortestPathLength', {
                get() {
                    if (this.frames.length === 0) {
                        return 0;
                    }
                    let shortest = 99999999;
                    this.possiblePaths.forEach(path => {
                        const length = path.frames.length;
                        if (length < shortest) {
                            shortest = length;
                        }
                    });
                    return shortest;
                },
            });

            return {
                //---------------------------------
                // Directive Support
                //---------------------------------

                editorDirectiveName: 'edit-frame-list',
                showDirectiveName: 'show-frames-player',

                //---------------------------------
                // Frame Editing
                //---------------------------------

                addFrameWithDefaultType(index) {
                    // lazy loading this so we don't have to load the Componentized
                    // frame type in tests where it's not used
                    const FlexibleComponentized = $injector.get('Lesson.FrameList.Frame.FlexibleComponentized');
                    const frame = FlexibleComponentized.new();
                    frame.$$embeddedIn = this;
                    frame.applyDefaultEditorTemplate();
                    this.addFrame(frame, index);
                    return frame;
                },

                addFrame(frame, index) {
                    frame.$$embeddedIn = this; // FIXME: this is a hack.  How do we add things to embedded lists in Iguana
                    if (index !== undefined) {
                        this.frames.splice(index, 0, frame);
                    } else {
                        this.frames.push(frame);
                    }
                    return frame;
                },

                replaceFrame(oldFrame, frameInteractionTypeConfig) {
                    const newFrame = frameInteractionTypeConfig.FrameKlass.new();
                    const index = oldFrame.index();
                    this.removeFrame(oldFrame);
                    this.addFrame(newFrame, index);
                    if (oldFrame.author_comments) {
                        newFrame.author_comments = oldFrame.author_comments.slice(0);
                    }
                    if (oldFrame.annotations) newFrame.annotations = oldFrame.annotations;

                    // FlexibleComponentized frames have a setupFrame method on each individual frameInteractionTypeConfig
                    // that allows for passing through the desired template. Other frame types just have a distinct setup
                    // instance method on the frame that does not require any arguments
                    if (frameInteractionTypeConfig.setupFrame) frameInteractionTypeConfig.setupFrame(newFrame);
                    if (newFrame.setup) newFrame.setup();

                    this.replaceFrameReferences(oldFrame, newFrame);
                    newFrame.copyMainTextAndImagesFrom(oldFrame);

                    // When the Screen Type is changed, the modal text is temporarily lost because the formatted text on the modal is blown away.
                    // By reformatting all the text that is brought through to the new frame, the frame remains consistent through changes of type.
                    newFrame.formatAllText(true);
                    return newFrame;
                },

                // loop through the frame list looking for references pointing
                // to the old frame, and replace those references with references to the
                // specified new frame
                replaceFrameReferences(oldFrame, newFrame) {
                    this.frames.forEach(frame => {
                        frame.replaceFrameReferences(oldFrame, newFrame);
                    });

                    return this;
                },

                duplicate($super, params, meta) {
                    let proxy = this.constructor.new(this.asJson());
                    // turn vanilla object into iguana class
                    proxy.reifyAllFrames();
                    if (proxy.frames) {
                        proxy.frames.forEach((frame, i) => {
                            // turn vanilla object into iguana class
                            frame = frame.reify();
                            // copy to new frame, with new id
                            const newFrame = frame.duplicate();

                            // update any branching references to refer to new frame.id
                            proxy = proxy.replaceFrameReferences(frame, newFrame);

                            proxy.frames[i] = newFrame;
                        });
                    }

                    params.frames = proxy.asJson().frames;

                    return $super(params, meta);
                },

                duplicateFrame(frame) {
                    const newFrame = frame.duplicate();
                    this.addFrame(newFrame, this.frames.indexOf(frame) + 1);
                },

                formatAllText(force) {
                    _.invokeMap(this.frames, 'formatAllText', force);
                },

                addTransN(convertNonMathjax) {
                    _.invokeMap(this.frames, 'addTransN', convertNonMathjax);
                },

                transformMixedFractions() {
                    _.invokeMap(this.frames, 'transformMixedFractions');
                },

                importFrames(frames) {
                    frames.forEach(function (frame) {
                        this.addFrame(frame.duplicate());
                    }, this);
                },

                removeFrame(frame) {
                    Array.remove(this.frames, frame);
                },

                removeFrames(frames) {
                    frames.forEach(function (frame) {
                        this.removeFrame(frame);
                    }, this);
                },

                frameForId(frameId, allowUndefined) {
                    for (const frame of this.frames) {
                        if (frame.id === frameId) {
                            return frame;
                        }
                    }

                    if (!allowUndefined) {
                        throw new Error(`No frame found for guid="${frameId}"`);
                    }

                    return undefined;
                },

                tryToSetFrameBookmarkId(frameId) {
                    const frame = this.frameForId(frameId, true);
                    if (frame) {
                        this.frame_bookmark_id = frame.id;
                        return true;
                    }
                    return false;
                },

                reifyAllFrames() {
                    this.frames.forEach(frame => {
                        frame.reify();
                    });
                    return this;
                },

                createNavigationPointers() {
                    const navigationPointers = {};
                    this.frames.forEach((frame, frameIndex) => {
                        const nextFrame = this.frames[frameIndex + 1];
                        const nextFrameId = nextFrame && nextFrame.id;
                        if (!navigationPointers[frame.id]) {
                            navigationPointers[frame.id] = {
                                to: [],
                                from: [],
                            };
                        }

                        // check whether this frame has an answer override that points to us
                        if (frame.frameNavigator) {
                            for (const selectableAnswerNavigator of frame.frameNavigator.selectableAnswerNavigators) {
                                let _nextFrameId = selectableAnswerNavigator.next_frame_id;
                                if (!_nextFrameId || !this.frameForId(_nextFrameId, true)) {
                                    _nextFrameId = nextFrameId;
                                }
                                if (_nextFrameId) {
                                    navigationPointers[frame.id].to.push(_nextFrameId);

                                    if (!navigationPointers[_nextFrameId]) {
                                        navigationPointers[_nextFrameId] = {
                                            to: [],
                                            from: [],
                                        };
                                    }
                                    navigationPointers[_nextFrameId].from.push(frame.id);
                                }
                            }

                            // check whether this frame has a top level override that points to us
                            if (
                                frame.frameNavigator.next_frame_id &&
                                this.frameForId(frame.frameNavigator.next_frame_id, true)
                            ) {
                                navigationPointers[frame.id].to.push(frame.frameNavigator.next_frame_id);

                                if (!navigationPointers[frame.frameNavigator.next_frame_id]) {
                                    navigationPointers[frame.frameNavigator.next_frame_id] = {
                                        to: [],
                                        from: [],
                                    };
                                }
                                navigationPointers[frame.frameNavigator.next_frame_id].from.push(frame.id);
                            }
                        }
                    });
                    return navigationPointers;
                },

                grade() {
                    // lazy inject this dependency because it has many other dependencies.  Do not
                    // want to load them all in tests when we do not use them
                    const FrameListGrader = $injector.get('Lesson.FrameList.FrameListGrader');
                    return new FrameListGrader(this).grade();
                },

                resetKeyTerms() {
                    this.key_terms = this.getKeyTerms();
                },

                resetTutorBotDescriptions() {
                    this.frames.forEach(frame => {
                        frame.resetTutorBotDescription();
                    });
                },

                getKeyTerms() {
                    const keyTerms = [];
                    const keyTermsSet = {};
                    this.frames.forEach(frame => {
                        frame
                            .reify()
                            .getKeyTerms()
                            .forEach(keyTerm => {
                                if (!keyTermsSet[keyTerm]) {
                                    keyTerms.push(keyTerm);
                                    keyTermsSet[keyTerm] = true;
                                }
                            });
                    });
                    return keyTerms;
                },

                getScore(challengeScores, scoreMetadata) {
                    if (!challengeScores) {
                        return undefined;
                    }
                    let totalScore = 0;
                    const keys = Object.keys(challengeScores);
                    if (keys.length === 0) {
                        return undefined;
                    }
                    angular.forEach(keys, key => {
                        totalScore += challengeScores[key];
                    });
                    if (scoreMetadata) {
                        scoreMetadata.totalScore = totalScore;
                        scoreMetadata.totalChallenges = keys.length;
                    }
                    return totalScore / keys.length;
                },
            };
        });
    },
]);
