import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/shared/lesson_progress_bar.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { chunk } from 'lodash/fp';

const templateUrl = cacheAngularTemplate(angularModule, template);
const ROW_THRESHOLD = 15;
const LONG_ROW_SIZE = 15; // when row size is long enough that we should adjust the mobile CSS

angularModule.directive('lessonProgressBar', [
    '$injector',
    $injector => ({
        scope: {
            playerViewModel: '<',
        },
        restrict: 'E',
        templateUrl,
        link(scope) {
            const $rootScope = $injector.get('$rootScope');
            const AppHeaderViewModel = $injector.get('Navigation.AppHeader.AppHeaderViewModel');
            scope.appHeaderViewModel = AppHeaderViewModel;

            /** INITIALIZE SCOPE VALUEs */
            scope.count = 0;
            scope.indicators = [];
            scope.rows = [];
            scope.rowSize = 0;
            scope.displayModeType = {
                indicators: 'indicators',
                select: 'select',
                linearProgressBar: 'linearProgressBar',
            };
            scope.linearProgressBarPercent = 0;
            scope.activeFrameNumber = 1;
            scope.frameSelectConfig = {
                create: false,
                valueField: 'index',
                labelField: 'label',
                maxItems: 1,
                sortField: 'index',
                searchField: ['label'],
            };
            scope.frameSelectOptions = [];
            scope.frameSelect = { selectedFrameIndex: 0 };

            // Determine whether to display the progress bar as indicators, a select dropdown, or a linear progress bar
            Object.defineProperty(scope, 'displayMode', {
                get() {
                    const currentUserIsAdmin = $rootScope.currentUser?.hasAdminAccess;
                    const isExam = scope.playerViewModel?.test;
                    const isTooLongForIndicators =
                        scope.playerViewModel && scope.playerViewModel.frames.length > 3 * ROW_THRESHOLD;

                    if (isExam && isTooLongForIndicators && currentUserIsAdmin) {
                        return scope.displayModeType.select;
                    }

                    if (isExam && !currentUserIsAdmin) {
                        return scope.displayModeType.linearProgressBar;
                    }

                    return scope.displayModeType.indicators;
                },
                configurable: true,
            });

            /** WATCHERS */

            // When the playerViewModel changes, rebuild the progress bar indicators
            scope.$watch('playerViewModel', playerViewModel => {
                scope.count = playerViewModel ? playerViewModel.frames.length : 0;
                scope.frameSelectOptions = playerViewModel
                    ? playerViewModel.frames.map((_fr, i) => ({
                          label: `Frame ${i + 1}`,
                          index: i,
                      }))
                    : [];
                buildProgressBarIndicators();
            });

            // In 'select' display mode, navigate to the selected frame
            scope.$watch('frameSelect.selectedFrameIndex', selectedFrameIndex => {
                // It is briefly -1 during frame transition
                if (!scope.playerViewModel || selectedFrameIndex === -1) return;
                // Don't navigate if we're already on the selected frame
                if (scope.playerViewModel.activeFrameIndex === parseInt(selectedFrameIndex, 10)) return;
                scope.navigateTo(parseInt(selectedFrameIndex, 10), null);
            });

            // In 'linearProgressBar' display mode, update the progress bar percentage and the active frame number
            // for the displayed frame count
            scope.$watchGroup(
                ['playerViewModel.activeFrameIndex', 'playerViewModel.frames.length'],
                ([i, numFrames]) => {
                    if (i === undefined || numFrames === undefined) return;
                    const percent = ((i + 1) / numFrames) * 100;
                    // Don't update if the percent is 0, as it will cause the progress bar to flash.
                    // It is briefly 0 during frame transition.
                    if (percent === 0) return;
                    scope.linearProgressBarPercent = percent;
                    scope.activeFrameNumber = i + 1;
                },
            );

            // When the active frame changes, update the filled and canNavigate state of the indicators
            scope.$watch('playerViewModel.activeFrameIndex', activeFrameIndex => {
                // For 'select' display mode, update the selected frame
                if (activeFrameIndex !== undefined && activeFrameIndex !== null) {
                    scope.frameSelect.selectedFrameIndex = activeFrameIndex;
                }
                // wait half a second before updating fills in order
                // to line up with the frame transition.  This is a bit
                // hacky.  We could somehow tap in to the frame_visible
                // event, but it's a bit tricky to get access to that
                // from here.
                $injector.get('scopeTimeout')(scope, setIndicatorStates, 500);
            });

            // Add a class when the rowSize is high so that we can adjust the mobile CSS
            scope.$watch('rowSize', () => {
                const isLongRowSize = scope.rowSize >= LONG_ROW_SIZE;
                scope.rows.forEach(row => {
                    const addOrDelete = isLongRowSize ? 'add' : 'delete';
                    row.classes[addOrDelete]('long-row');
                    row.indicators.forEach(indicator => {
                        indicator.classes[addOrDelete]('long-row-indicator');
                    });
                });
            });

            scope.$watchGroup(['rows', 'displayMode'], ([rows, displayMode]) => {
                if (rows.length === 3 && displayMode === scope.displayModeType.indicators) {
                    // 3 rows of chiclets doesn't leave room for frame instructions, so hide them
                    scope.appHeaderViewModel.preventFrameInstructions = true;
                    return;
                }
                scope.appHeaderViewModel.preventFrameInstructions = false;
            });

            /** FUNCTIONS */

            // Convert the Set of classes to an Array for ng-class
            scope.getClassesArray = classSet => [...classSet];

            // Navigate to the frame when an indicator is clicked
            scope.navigateTo = (frameIndex, event) => {
                // remove frame history. otherwise the back
                // button gets really confusing as it jumps
                // back to the last frame you were on.
                scope.playerViewModel.frameHistory = [];
                scope.playerViewModel.gotoFrameIndex(frameIndex);
                event?.stopPropagation();
            };

            // Build the progress bar indicators based on the number of frames, adjust based on the number
            // of rows for balanced layout, and set the filled/canNavigate state of each indicator
            function buildProgressBarIndicators() {
                scope.indicators = new Array(scope.count ?? 0).fill(0).map((_, index) => ({
                    index,
                    filledState: 'unfilled',
                    active: false,
                    classes: new Set(),
                }));

                scope.rowSize = getRowSize();

                // Split the indicators into initial row chunks based on the row size
                const indicatorsByRow = chunk(scope.rowSize)(scope.indicators);

                // Create a row object for each row of indicators
                scope.rows = indicatorsByRow.map((rowIndicators, index) => ({
                    index,
                    indicators: rowIndicators,
                    classes: new Set(),
                }));

                // Make adjustments based on number of rows to get the correct layout
                const rowModifiers = {
                    0: () => {},
                    1: handleOneRow,
                    2: handleTwoRows,
                    3: handleThreeRows,
                };
                rowModifiers[scope.rows.length]?.();

                setIndicatorStates();
            }

            function getRowSize() {
                // Try to keep the number of indicators in each row close to the same
                if (scope.count <= ROW_THRESHOLD) {
                    // If the count is more than half of the threshold, prefer 2 rows
                    const isMoreThanHalfOfThreshold = scope.count > ROW_THRESHOLD / 2;
                    return isMoreThanHalfOfThreshold ? Math.ceil(scope.count / 2) : scope.count;
                }
                if (scope.count <= 2 * ROW_THRESHOLD) {
                    return Math.ceil(scope.count / 2);
                }
                if (scope.count <= 3 * ROW_THRESHOLD) {
                    return Math.ceil(scope.count / 3);
                }
                return ROW_THRESHOLD;
            }

            function handleOneRow() {
                scope.rows[0].classes.add('single-row');
            }

            function handleTwoRows() {
                // Apply an offset to the second of 2 rows if its evenness/oddness matches that of the first row
                if (scope.rows[1].indicators.length % 2 === scope.rows[0].indicators.length % 2) {
                    scope.rows[1].classes.add('row-offset');
                }
            }

            function handleThreeRows() {
                // Move the first item of the third row to the end of the second row for a balanced look
                // Where the second row is wider than the first and third rows.
                // The second row won't need an offset because it will have 1 more indicator than the first row,
                // so its evenness/oddness won't match the first row.
                scope.rows[1].indicators = [...scope.rows[1].indicators, scope.rows[2].indicators[0]];
                scope.rows[2].indicators = scope.rows[2].indicators.slice(1);

                // Apply a reverse offset to the modified third row if its evenness/oddness
                // matches that of the modified second row.
                if (scope.rows[1].indicators.length % 2 === scope.rows[2].indicators.length % 2) {
                    scope.rows[2].classes.add('row-reverse-offset');
                }

                scope.rows.forEach(row => {
                    row.classes.add('three-rows');
                });
            }

            // Set the filled/partially-filled/unfilled state of each indicator
            // Depending on whether the frame is completed, whether the frame is the current frame,
            // and whether the user can navigate to the frame
            function setIndicatorStates() {
                const playerViewModel = scope.playerViewModel;
                if (!playerViewModel) {
                    return;
                }

                const currentIndex = playerViewModel.activeFrameIndex;
                // The highest frame visited by the user
                let highWaterMark = 0;

                for (let index = scope.indicators.length - 1; index >= 0; index--) {
                    const indicator = scope.indicators[index];

                    const frame = scope.playerViewModel.lesson.frames[index];
                    const completed = scope.playerViewModel.completedFrames[frame.id];

                    // if the current frame is the highest visited
                    // one, or any completed frame is shown as filled
                    if (index === currentIndex || completed) {
                        indicator.filledState = 'filled';
                    }

                    // a frame that is before the highest visited
                    // but that is not completed is shown as
                    // partially filled. (But the filled state can
                    // never go backwards from filled to partialy filled)
                    else if (index < highWaterMark && !completed && indicator.filledState !== 'filled') {
                        indicator.filledState = 'partially-filled';
                    }

                    // Set whether the indicator can be clicked to navigate to the frame
                    if (index === currentIndex) {
                        indicator.canNavigate = false;
                    } else if (scope.playerViewModel.canNavigateFreely) {
                        indicator.canNavigate = true;
                    } else if (
                        indicator.filledState === 'filled' &&
                        scope.playerViewModel.canNavigateBackToCompletedFrames
                    ) {
                        indicator.canNavigate = true;
                    } else {
                        indicator.canNavigate = false;
                    }

                    if (!highWaterMark && indicator.filledState === 'filled') {
                        highWaterMark = index;
                    }

                    // if the current frame is not the highest
                    // visited one, then it gets special styling
                    if (index === currentIndex && index < highWaterMark) {
                        indicator.activeHighlight = true;
                    } else {
                        indicator.activeHighlight = false;
                    }
                }
            }
        },
    }),
]);
