import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/stream/top_message.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { targetBrandConfig } from 'AppBranding';
import { studentDashboardConfig } from 'AdmissionsGuidance';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('topMessage', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const ClientStorage = $injector.get('ClientStorage');
        const DateHelper = $injector.get('dateHelper');
        const TranslationHelper = $injector.get('TranslationHelper');
        const translationHelper = new TranslationHelper('lessons.stream.top_message');
        const AppHeaderViewModel = $injector.get('Navigation.AppHeader.AppHeaderViewModel');

        return {
            restrict: 'E',
            templateUrl,
            scope: {},
            link(scope) {
                // Initialization
                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                    configurable: true,
                });

                Object.defineProperty(scope, 'userBrandConfig', {
                    get() {
                        return targetBrandConfig(scope.currentUser);
                    },
                    configurable: true,
                });

                scope.viewModel = AppHeaderViewModel;

                scope.$watch(
                    () => studentDashboardConfig($injector),
                    currentStudentDashboardConfig => {
                        scope.studentDashboardConfig = currentStudentDashboardConfig;
                    },
                );

                scope.programTitle = scope.currentUser?.relevantCohort?.standaloneShortProgramTitle;

                scope.topMessageConfigs = [
                    {
                        storageKeys: [
                            'mobile_top_message_hidden', // backwards compat
                            'mobile_top_message_explore_the_curriculum_hidden',
                        ],
                        classes: ['institution-colors'],
                        showCaret: false, // only visible on mobile
                        priority: 1, // when multiple top messages are enabled, this flag will be used to determine the order in which to show them
                        enabled: () =>
                            // This particular top message is currently only used for students
                            // who are in a program that has a normal program application/admission round process.
                            scope.currentUser?.relevantCohort?.supportsAdmissionRounds &&
                            scope.studentDashboardConfig &&
                            !!scope.studentDashboardConfig.desktopTopMessageLocaleKey &&
                            !!scope.studentDashboardConfig.mobileTopMessageLocaleKey,
                        showLogoAndTextBubble: true,
                        mobileMessage: () =>
                            translationHelper.get(scope.studentDashboardConfig?.mobileTopMessageLocaleKey, {
                                programTitle: scope.programTitle,
                            }),
                        desktopMessage: () =>
                            translationHelper.get(scope.studentDashboardConfig?.desktopTopMessageLocaleKey, {
                                institutionName: scope.userBrandConfig.brandNameLong,
                                applicationDeadlineDate: DateHelper.formattedUserFacingMonthDayLong(
                                    scope.currentUser.relevantCohort?.applicationDeadline(),
                                    true, // use date threshold
                                ),
                                programType: scope.programTitle,
                            }),
                    },
                    {
                        storageKeys: ['mobile_top_message_settings_has_moved_hidden'],
                        classes: ['centered-text', 'lg-text'],
                        showCaret: true,
                        priority: 0,
                        enabled: () => {
                            // The goal here is to enable this tooltip for two months after launching this feature
                            // ONLY for existing users who are aware of where the settings button used to be.
                            const featureLaunchDate = new Date('2023-11-22');
                            const userCreatedAfterFeatureLaunch =
                                scope.currentUser?.created_at > featureLaunchDate.getTime() / 1000;
                            if (userCreatedAfterFeatureLaunch) return false;

                            const twoMonthsAfterLaunch = new Date(
                                featureLaunchDate.setMonth(featureLaunchDate.getMonth() + 2),
                            );
                            if (Date.now() > twoMonthsAfterLaunch.getTime()) return false;

                            return true;
                        },
                        showLogoAndTextBubble: false,
                        mobileMessage: () => translationHelper.get('top_message_settings_has_moved'),
                        desktopMessage: () => null,
                    },
                ];

                scope.messageIsDismissed = storageKeys =>
                    storageKeys.some(key => ClientStorage.getItem(key) === 'true');

                scope.setMessageDismissed = (storageKeys, shouldDismiss) => {
                    storageKeys.forEach(key => {
                        ClientStorage.setItem(key, !!shouldDismiss);
                    });

                    scope.setMessageToShow();
                };

                scope.messageToShow = () => {
                    const unDismissedAndEnabledTopMessageConfigs = scope.topMessageConfigs.filter(
                        config => !scope.messageIsDismissed(config.storageKeys) && config.enabled(),
                    );

                    if (!unDismissedAndEnabledTopMessageConfigs.length) {
                        return null;
                    }

                    const topMessageConfig = unDismissedAndEnabledTopMessageConfigs.reduce((prev, curr) =>
                        prev.priority < curr.priority ? prev : curr,
                    );

                    return topMessageConfig;
                };

                scope.setMessageToShow = () => {
                    const message = scope.messageToShow();

                    if (!message) {
                        scope.shownMessage = null;
                        AppHeaderViewModel.hasTopMessage = false;
                        AppHeaderViewModel.showCaret = false;
                        return;
                    }

                    scope.shownMessage = message;
                    AppHeaderViewModel.hasTopMessage = true;
                    AppHeaderViewModel.showCaret = message.showCaret;
                };

                scope.$on('$destroy', () => {
                    AppHeaderViewModel.hasTopMessage = false;
                    AppHeaderViewModel.showCaret = false;
                });

                // Watch
                scope.$watch('studentDashboardConfig.stateKey', scope.setMessageToShow);
            },
        };
    },
]);
