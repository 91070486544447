/* eslint-disable no-prototype-builtins */
import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory('Lesson.Stream.Chapter', [
    'Iguana',

    Iguana =>
        Iguana.subclass(function () {
            this.alias('Lesson.Stream.Chapter');
            this.embeddedIn('stream');

            Object.defineProperty(this.prototype, 'index', {
                get() {
                    if (!this.stream()) {
                        return -1;
                    }
                    return this.stream().chapters.indexOf(this);
                },
            });

            Object.defineProperty(this.prototype, 'complete', {
                get() {
                    return this.progressStatus() === 'completed';
                },
            });

            Object.defineProperty(this.prototype, 'started', {
                get() {
                    return this.progressStatus() !== 'not_started';
                },
            });

            Object.defineProperty(this.prototype, 'completedLessons', {
                get() {
                    return this.lessons.filter(
                        lesson => lesson && lesson.lesson_progress && lesson.lesson_progress.complete,
                    );
                },
                configurable: true,
            });

            // FIXME: Iguana should handle this
            Object.defineProperty(this.prototype, 'lessons', {
                get() {
                    const lessons = [];
                    this.lesson_hashes.forEach(lessonHash => {
                        const lessonId = lessonHash.lesson_id;

                        // Pass true as the second argument so this
                        // will not blow up if the lesson is not found.  If
                        // the lesson is not published, it might not be found.
                        const lesson = this.stream().lessonForId(lessonId, true);
                        if (lesson) {
                            lessons.push(lesson);
                        }
                    });
                    return lessons;
                },
            });

            Object.defineProperty(this.prototype, 'lessonIds', {
                get() {
                    if (this.lesson_hashes) {
                        return this.lesson_hashes.map(lessonHash => lessonHash.lesson_id);
                    }
                    return this.lesson_ids;
                },
            });

            Object.defineProperty(this.prototype, 'comingSoonLessons', {
                get() {
                    const lessons = [];
                    this.lesson_hashes.forEach(lessonHash => {
                        if (!lessonHash.coming_soon) {
                            return;
                        }

                        const lessonId = lessonHash.lesson_id;

                        // Pass true as the second argument so this
                        // will not blow up if the lesson is not found.  If
                        // the lesson is not published, it might not be found.
                        const lesson = this.stream().lessonForId(lessonId, true);
                        if (lesson) {
                            lessons.push(lesson);
                        }
                    });
                    return lessons;
                },
            });

            Object.defineProperty(this.prototype, 'launchText', {
                get() {
                    if (this.complete) {
                        return 'RETAKE';
                    }
                    if (this.started) {
                        return 'RESUME';
                    }
                    return 'START';
                },
            });

            return {
                initialize($super, attrs) {
                    if (!attrs.hasOwnProperty('title')) {
                        attrs.title = '';
                    }

                    if (!attrs.hasOwnProperty('lesson_ids')) {
                        attrs.lesson_ids = [];
                    }

                    // just-in-time backfill of lesson_hashes
                    if (!attrs.hasOwnProperty('lesson_hashes')) {
                        attrs.lesson_hashes = attrs.lesson_ids.map(lessonId => ({
                            lesson_id: lessonId,
                            coming_soon: false,
                        }));
                    }

                    $super(attrs);
                },

                // backwards compatibility backfill method
                // must be called before stream is saved
                backfillLessonIds() {
                    // HACK: backfill the lesson_ids value to match lesson_hashes
                    // this is because the server still depends on this value
                    // once we've manually saved all streams to have lesson_hashes
                    // in their chapter JSONs, we will release a new version of the server
                    // that doesn't have this requirement, and can then remove this hack.
                    this.lesson_ids = this.lesson_hashes.map(val => val.lesson_id);
                },

                // remove the chapter from the stream
                remove() {
                    if (this.stream()) {
                        this.stream().removeChapter(this);
                    }
                },

                addLesson(lesson, comingSoon) {
                    if (this.lessonIds.includes(lesson.id)) {
                        return;
                    }
                    comingSoon = comingSoon === true;

                    // update the lessons and the current chapter's lesson guid collections
                    this.stream().addLesson(lesson);
                    this.lesson_hashes.push({
                        lesson_id: lesson.id,
                        coming_soon: comingSoon,
                    });
                },

                removeLesson(lesson) {
                    this.lesson_hashes = _.filter(this.lesson_hashes, lessonHash => lessonHash.lesson_id !== lesson.id);
                    this.stream().removeLessonsIfUnused([lesson]);
                },

                togglePending() {
                    this.pending = !this.pending;
                },

                toggleLessonComingSoon(lesson) {
                    const index = this.lessonIds.indexOf(lesson.id);
                    const comingSoon = this.lesson_hashes[index].coming_soon === true;
                    this.lesson_hashes[index].coming_soon = !comingSoon;
                },

                isLessonComingSoon(lesson) {
                    const index = this.lessonIds.indexOf(lesson.id);
                    return this.lesson_hashes[index].coming_soon === true;
                },

                lessonPositionFor(lesson) {
                    const index = this.lessonIds.indexOf(lesson.id);
                    if (index < 0) {
                        throw new Error('Lesson not in chapter');
                    }
                    return {
                        index,
                        lessonCount: this.lesson_hashes.length,
                    };
                },

                progressStatus() {
                    const statuses = {
                        in_progress: 0,
                        not_started: 0,
                        completed: 0,
                    };

                    const length = this.lessons.length;

                    this.lessons.forEach(lesson => {
                        statuses[lesson.progressStatus()] = statuses[lesson.progressStatus()] + 1;
                    });

                    if (statuses.completed === length) {
                        return 'completed';
                    }
                    if (statuses.not_started === length) {
                        return 'not_started';
                    }
                    return 'in_progress';
                },
            };
        }),
]);
