import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    This class tracks whether the stream or chapter was just completed
    so we know whether to animate it when returning to the stream dashboard
*/
angularModule.factory('StreamProgressionStatus', [
    'AClassAbove',
    AClassAbove => {
        const StreamProgressionStatus = AClassAbove.subclass(function () {
            Object.defineProperty(this.prototype, 'completedStreamIndex', {
                writable: true,
            });

            return {};
        });

        return StreamProgressionStatus;
    },
]);
