import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/stream/course_talk_review_widget.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule // HACK: hard-coded map of streams to course talk IDs
    // remove or add to this list to enable or disable the course talk widget for a page.
    .constant('SUPPORTED_COURSE_TALK_STREAMS', {
        '95437d2a-0211-4899-825d-e2107c6729e6': 47031, // Microeconomics I: Supply and Demand
        'd79316c4-a419-41ff-ae42-be14fe70af44': 47030, // Data Collection
        '56e93418-1abf-457e-a2ab-1510a9b344e2': 47029, // Two-Variable Statistics
        '222d17ff-718d-4bbc-b4df-444e71e7aec9': 47028, // Finance: Time Value of Money
        '8d720fb5-0320-4bf4-8c40-50808267a1be': 47027, // One-Variable Statistics
        '50eeda2d-1b44-4a1d-b425-dead8982526f': 47026, // Accounting I: Fundamentals
        '1cff0dc6-4522-4ec4-950d-f9a84785bddd': 81193, // Pricing I: Fundamentals
        'e08c8202-595b-462a-a161-534e40ab7448': 75264, // Probability Fundamentals
        '87b85177-fdf6-4cf8-a29a-c44b0ef77530': 75245, // Blue Ocean Strategy
        '33d2c41c-cc5b-4abe-9b5e-0aa8d4b728bf': 75247, // Cost of Capital II: The Modigliani-Miller Theorem
        '964fe746-9fb7-46c8-883c-4b653579733d': 75249, // Personal Investing
        '136267fd-0b9d-4420-a433-b94e793da97f': 75265, // Valuation I: Discounted Free Cash Flow
        'fe51c70d-dee9-4259-94b9-fbb3cbabf141': 75248, // Developing a Corporate Philosophy
        'eb375ff7-8a8f-467c-ae09-aa3c634bb336': 75259, // Operations Management: Managing Uncertainty of Demand
        '3106919b-8b93-46b0-97b1-e08ef9093d02': 75242, // Accounting II: Revenues and Expenses
        'c70acae7-374f-4272-811e-1f797569700f': 75243, // Accounting III: Financial Statements
        '64064f30-c285-4cb3-bf65-8252c9465e5e': 75254, // Macroeconomics: International Trade
        'abd47e21-4b59-4ccf-910d-fb1c18b2e9df': 75255, // Marketing Fundamentals
        '5a5f3969-5778-4b4d-9f7c-b4571ecdc93a': 75244, // Accounting IV: Working with Ratios
        'f6296448-533f-456f-bdc3-1b0c83cf039f': 75251, // Macroeconomics: Basics
        '97ec237c-bbcb-4d8d-ae0f-6528e7d1da7a': 75261, // Organizational Structure and Culture
        '86a3abd6-edba-4e7f-ab71-08185c12469d': 75262, // Probability and Combinatorics
        '7724f79b-67da-4e24-a44f-4e8e48a5b6c0': 75246, // Cost of Capital I: Capital Structure
        'b4e8c5b1-322f-4f23-af88-78f8732dc0b1': 75253, // Macroeconomics: Economic Fluctuations
        '624fed26-ac6c-4ff9-91ad-e9ab537ac39e': 75256, // Microeconomics II: Shape Your Economic Worldview
        'dccbfe7e-325c-4df6-8ce4-a6ea12a5dec0': 75257, // Microeconomics III: Markets and Externalities
        'f370e8ce-55a5-4612-b6de-d80a57eb795a': 75258, // Operations Management Fundamentals
        'bcd0644f-fb79-4409-850c-feccb698d776': 75260, // Organizational Behavior: Working in Groups and Teams
        '34a3fc30-d065-462b-b12c-c83411a8e230': 75263, // Probability Distributions
        '81f7aca3-06d7-4879-be6e-00124e68ed61': 81240, // Strategy III: International Strategy
        '336f8b4f-dcdd-473e-a45b-bb9e211d0feb': 81187, // Coffee Break I
        'bb9596a2-1258-49bc-a9f5-347a358fa0f4': 81191, // Introduction to Evernote
        'aae8ec62-2181-49fd-b3c4-9672c85a2aa2': 81236, // Project Management
        '85bde255-ab11-496c-828d-bdd0e70e0492': 81238, // Strategy I: Business-level Strategy
        '6084cb67-e2da-4e78-8bfc-27083d93eb16': 81239, // Strategy II: Corporate-Level Strategy
        '1deeff68-ed22-4399-a967-e6689c0aed3f': 81185, // A/B Testing For Marketers
        '21238803-e474-43ee-be48-d1e82a51ae57': 81186, // Advanced Statistical Inference
        '5192ccb3-0dd2-458f-b238-cc8d15748aec': 81189, // Digital Marketing Fundamentals
        '34865ee9-ca79-4684-aad2-81d127865af9': 81192, // Marketing Mechanics
        'caac28bb-062e-4e0b-a758-bb63be632a85': 81194, // Pricing II: Price Segmentation to Maximize Profit
        'e0575521-49b8-4311-b722-a7d75c4b3524': 81241, // Theories of Leadership
        'abb5ef84-d2c9-4f64-be0a-8cd6e3187d48': 81242, // Why Blended Learning Matters
        '00fc8e2c-a307-40ca-a13a-e903ded95024': 81190, // Statistical Inference: Making Data-Driven Decisions
        'c94d702d-d6ef-4b86-9cbe-d229c7cea733': 80666, // Stocks and Bonds
        '29337992-e1cb-455d-b86e-86440822d780': 81237, // Regression Analysis
    })

    .directive('courseTalkReviewWidget', [
        '$injector',
        function factory($injector) {
            const $rootScope = $injector.get('$rootScope');
            const $window = $injector.get('$window');
            const streamToCourseTalkId = $injector.get('SUPPORTED_COURSE_TALK_STREAMS');
            const offlineModeManager = $injector.get('offlineModeManager');

            return {
                scope: {
                    stream: '<',
                },
                restrict: 'E',
                templateUrl,
                controllerAs: 'controller',

                link(scope) {
                    // Only show if found a valid ID and not an external-institution user
                    scope.courseTalkId = streamToCourseTalkId[scope.stream.id];
                    scope.courseTalkEnabled =
                        scope.courseTalkId &&
                        scope.courseTalkId > 0 &&
                        !$window.CORDOVA && // don't show in native mobile app
                        !offlineModeManager.inOfflineMode &&
                        !($rootScope.currentUser && $rootScope.currentUser.hasExternalInstitution); // don't show to external-institution users
                },
            };
        },
    ]);
