import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/stream/student_dashboard_schedule_event_group.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import formattedDateForEvent from 'StudentNetwork/formattedDateForEvent';
import { groupBy, orderBy } from 'lodash/fp';

import moment from 'moment';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('studentDashboardScheduleEventGroup', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const EventLogger = $injector.get('EventLogger');
        const chinaRegionMode = $injector.get('ConfigFactory').getSync(true).chinaRegionMode();
        const TranslationHelper = $injector.get('TranslationHelper');
        const isMobileMixin = $injector.get('isMobileMixin');

        return {
            restrict: 'E',
            scope: {
                events: '<',
                onPopoverCloseClick: '&',
            },
            templateUrl,
            link(scope) {
                NavigationHelperMixin.onLink(scope);
                isMobileMixin.onLink(scope);

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                scope.$watch('xsOrSm', xsOrSm => {
                    if (xsOrSm) scope.onPopoverCloseClick();
                });

                /* *****************
                We now group Upcoming Events with the same name into a single popover, and we
                group those similar events by start date within the popover -- https://trello.com/c/MQuvb7jx
                ***************** */

                const dateStringNoTime = date => moment(date).format('YYYY-MM-DD');

                // Helper: provide an array of events that share a name, and group them by start date
                scope.eventsByStartDate = groupBy(event => dateStringNoTime(event.startTime), scope.events);

                // Helper: provide an array of events that share a name, and get their sorted start dates
                scope.orderedEventStartDates = orderBy(
                    [dateStringNoTime],
                    ['asc'],
                    Object.keys(scope.eventsByStartDate),
                );

                scope.formattedEventGroupDate = date => {
                    if (dateStringNoTime(date) === dateStringNoTime()) {
                        /* date is today */
                        return new TranslationHelper('lessons.stream.student_dashboard_schedule')
                            .get('today')
                            .toLocaleUpperCase();
                    }
                    return moment(date).format('dddd, MMMM D').toLocaleUpperCase();
                };

                scope.formattedDateForEvent = event => {
                    const timezone = chinaRegionMode ? 'Asia/Shanghai' : scope.currentUser.timezone;
                    return formattedDateForEvent(event, timezone);
                };

                scope.logDashboardToNetworkEvent = eventId => {
                    EventLogger.log('student-dashboard:navigate-to-student-network', {
                        student_network_event_id: eventId,
                    });
                };

                scope.onEventClick = event => {
                    scope.logDashboardToNetworkEvent(event.id);
                    scope.loadRoute(`/student-network?event-id=${event.id}`);
                };
            },
        };
    },
]);
