import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    This is a class that holds the logic that a PlayerViewModel will need when showing practice frames.

    In the wild, today, we do not use practice frames, so this mixin is unused.
*/
angularModule.factory('Lesson.PracticeFramesPlayerViewModel', [
    '$injector',
    $injector => {
        const PlayerViewModel = $injector.get('Lesson.PlayerViewModel');
        const PlayerViewModelWithFrames = $injector.get('PlayerViewModelWithFrames');
        const Lesson = $injector.get('Lesson');
        const Stream = $injector.get('Lesson.Stream');
        const SessionTracker = $injector.get('SessionTracker');

        return PlayerViewModel.subclass(function subclass() {
            this.include(PlayerViewModelWithFrames);

            //-------------------------------------------------
            // Callbacks defined in PlayerViewModel
            //-------------------------------------------------

            this.setCallback('after', 'initialized', function afterInit() {
                this.frames = [];
            });

            // even though this getter/setter seems to do nothing,
            // PlayerViewModelWithFrames requires it be defined this way.
            Object.defineProperty(this.prototype, 'frames', {
                get() {
                    return this._frames;
                },
                set(frames) {
                    this._frames = frames;

                    // eslint-disable-next-line no-setter-return
                    return this._frames;
                },
            });

            Object.defineProperty(this.prototype, 'ready', {
                get() {
                    return _.some(this.frames);
                },
            });

            Object.defineProperty(this.prototype, 'shouldShowPreviousButton', {
                get() {
                    return this.activeFrameIndex > 0;
                },
            });

            const PLAYER_SESSION_EXPIRY_MINUTES = $injector.get('PLAYER_SESSION_EXPIRY_MINUTES');
            Object.defineProperty(this.prototype, 'sessionId', {
                get() {
                    // After 20 minutes of inactivity, we consider it a new session.

                    // FIXME: Maybe we want to use a more specific identifier so that practicing
                    // different content counts as different sessions.  Not sure how to define this, though,
                    // since there is not a single id here.  Can reconsider once we actually start using this.
                    return SessionTracker.pingCurrentSession(
                        'lessonPracticePlayer',
                        PLAYER_SESSION_EXPIRY_MINUTES * 60 * 1000,
                    ).id;
                },
            });

            return {
                loadContent(courseOrLesson, id) {
                    // we use 'course' instead of 'stream' here
                    // because this is user-facing in the url.  Once we're
                    // into internal code, we switch back to stream
                    if (courseOrLesson === 'lesson') {
                        return this._loadLesson(id);
                    }
                    if (courseOrLesson === 'course') {
                        return this._loadStream(id);
                    }

                    return undefined;
                },

                gotoNext() {
                    if (!this.activeFrame) {
                        return;
                    }
                    let index = this.activeFrameIndex + 1;
                    if (!this.frames[index]) {
                        index = 0;
                    }
                    this.gotoFrameIndex(index);
                },

                gotoPrev() {
                    if (!this.activeFrame || this.activeFrameIndex === 0) {
                        return;
                    }
                    const index = this.activeFrameIndex - 1;
                    this.gotoFrameIndex(index);
                },

                _loadLesson(id) {
                    const self = this;
                    Lesson.show(id, {
                        filters: {
                            published: true,
                        },
                        'fields[]': ['practice_frames', 'lesson_type'],
                    }).then(response => {
                        self._addFrames(response.result.practice_frames);
                    });
                },

                _loadStream(id) {
                    const self = this;
                    Stream.show(id, {
                        filters: {
                            published: true,
                        },
                        'fields[]': ['lessons'],
                        'lesson_fields[]': ['id', 'lesson_type', 'practice_frames', 'lesson_progress'],
                    }).then(response => {
                        const frames = _.chain(response.result.lessons)
                            .filter(lesson => lesson.complete)
                            .map('practice_frames')
                            .flattenDeep()
                            .value();
                        self._addFrames(frames);
                    });
                },

                _addFrames(frames) {
                    this.frames = this.frames.concat(_.shuffle(frames));
                    if (!this.activeFrame) {
                        this.activeFrame = this.frames[0];
                    }
                },
            };
        });
    },
]);
