import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import { setupBrandNameProperties } from 'AppBranding';
import template from 'Lessons/angularModule/views/stream/stream_completed.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

import miyaMiyaCertificate from 'images/certificate_without_data_gold_miyamiya.png';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('streamCompleted', [
    '$injector',
    '$location',
    function factory($injector, $location) {
        const Stream = $injector.get('Lesson.Stream');
        const AppHeaderViewModel = $injector.get('Navigation.AppHeader.AppHeaderViewModel');
        const StreamProgressionStatus = $injector.get('StreamProgressionStatus');
        const ShareService = $injector.get('Navigation.ShareService');
        const SiteMetadata = $injector.get('SiteMetadata');
        const $rootScope = $injector.get('$rootScope');
        const ErrorLogService = $injector.get('ErrorLogService');
        const TranslationHelper = $injector.get('TranslationHelper');
        const offlineModeManager = $injector.get('offlineModeManager');

        return {
            scope: {
                streamId: '@',
            },

            restrict: 'E',
            templateUrl,
            controllerAs: 'controller',
            link(scope) {
                scope.miyaMiyaCertificate = miyaMiyaCertificate;
                setupBrandNameProperties($injector, scope);
                scope.offlineModeManager = offlineModeManager;

                const translationHelper = new TranslationHelper('lessons.stream.stream_completed');

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                function updateText() {
                    if (scope.stream.exam) {
                        scope.completedText = translationHelper.get('exam_complete');
                        scope.continueButtonText = translationHelper.get('return_to_dashboard');
                        scope.completionScreenTitle = translationHelper.get('great_job');
                        scope.completionScreenDescription = translationHelper.get('exam_is_complete');
                    } else if (scope.currentUser && !scope.currentUser.canShare) {
                        scope.completedText = translationHelper.get('course_complete');
                        scope.continueButtonText = translationHelper.get('continue');
                        scope.completionScreenTitle = translationHelper.get('great_job');
                    } else {
                        scope.completedText = translationHelper.get('course_complete');
                        scope.continueButtonText = translationHelper.get('continue');
                        scope.completionScreenTitle = translationHelper.get('great_job');
                        scope.completionScreenDescription = translationHelper.get('share_with_friend', {
                            brandName: scope.brandNameShort,
                        });
                    }
                }

                scope.$watch('stream', stream => {
                    if (stream) {
                        AppHeaderViewModel.toggleVisibility(false);
                        AppHeaderViewModel.setBodyBackground('completion-blue');
                        if (stream.imageSrc()) {
                            $('.course-icon').css('backgroundImage', `url(${stream.imageSrc()})`);
                        }
                    }
                });

                // cleanup the header sizing hacks on scope destroy
                scope.$on('$destroy', () => {
                    AppHeaderViewModel.toggleVisibility(true);
                    AppHeaderViewModel.setBodyBackground();
                });
                /**/
                scope.share = provider => {
                    const shareInfo =
                        scope.currentUser.blueOcean && scope.stream.isBlueOcean
                            ? SiteMetadata.blueOceanShareInfo(scope.currentUser)
                            : SiteMetadata.contentCompletedShareInfo(scope.currentUser, scope.stream);
                    ShareService.share('stream', provider, shareInfo, scope.stream.logInfo());
                };

                const gotoStreamDashboard = () => {
                    StreamProgressionStatus.completedStreamIndex = scope.streamId;
                    $location.url(scope.stream.streamDashboardPath);
                };

                // Return to stream dashboard
                scope.onClickContinue = () => {
                    scope.continueClicked = true;

                    // if progress is not yet saved, wait before moving on
                    if (scope.stream.savePromise) {
                        scope.stream.savePromise.then(gotoStreamDashboard);
                    } else {
                        gotoStreamDashboard();
                    }
                };

                scope.$watch('stream.lesson_streams_progress', streamProgress => {
                    if (streamProgress && !streamProgress.certificateImageSrc() && !offlineModeManager.inOfflineMode) {
                        ErrorLogService.notify(new Error('Stream completed page rendered without a certificate.'));
                    }
                });

                scope.$watch('streamId', streamId => {
                    Stream.getCachedOrShow(streamId).then(stream => {
                        scope.stream = stream;
                        updateText();
                        // If stream is not complete, redirect to stream home
                        if (stream.progressStatus() !== 'completed') {
                            $location.url(`/course/${scope.streamId}`);
                        }
                    });
                });
            },
        };
    },
]);
