import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    A SearchAttempt represents a single attempt by a user to search
    for streams and lessons on the library page.  It exists only
    for logging purposes.  It has no affect on the
    user experience.

    Admittedly, the SearchAttempt is probably not structured as best
    it could be for how we're using it for logging.  This is because
    Nate initially had one idea about what kind of logging we needed
    and turned out the be wrong.  But the existing SearchAttempt code
    still allowed us to get what we wanted, so it didn't seem worth
    refactoring.

    The major piece of complexity and the one surpising thing here is
    also kind of what turned out the be wrong. It is how the beginning and end
    of a single SearchAttempt is defined.  A new SearchAttempt is created
    when a user selects a filter or enters a search in the library, or when
    an existing search attempt is changed.  "changed" means that an existing
    filter is removed/replaced or all or part of an existing text search is
    deleted.  The philosophy behind this was that either of these actions means
    that the user did not find anything of interest and is changing or broadening
    the search to look for more results.  The reason that this is not really
    useful is that the UI encourages the user to click around on all the
    topic filters.  It's more of a browse than a search, so changing a
    filter does not really mean that the user "failed" to find something,

    In any case, this class supports the reports that we want, so leaving
    it as it is.

*/
angularModule.factory('SearchAttempt', [
    '$injector',
    $injector => {
        const SuperModel = $injector.get('SuperModel');
        const EventLogger = $injector.get('EventLogger');
        const guid = $injector.get('guid');
        const $route = $injector.get('$route');
        const $rootScope = $injector.get('$rootScope');

        const SearchAttempt = SuperModel.subclass(function () {
            this.extend({
                // Start watching the scope of browse-courses
                // directive.  When filters are updated, create
                // or update SearchAttempts as necessary.  There
                // is only one 'currentSearchAttempt' at a time.
                watchCoursesScope(coursesScope) {
                    const cancelers = [];
                    cancelers.push(
                        coursesScope.$on('filtersUpdated', () => {
                            if (this.currentSearchAttempt) {
                                this.currentSearchAttempt.filtersAdded(coursesScope, true);
                            }

                            const hasFilters = coursesScope.topicsToFilterBy.length > 0 || !!coursesScope.searchText;
                            if (hasFilters && !this.currentSearchAttempt) {
                                this.currentSearchAttempt = new SearchAttempt(coursesScope);
                            }
                        }),
                    );

                    cancelers.push(
                        coursesScope.$on('$destroy', () => {
                            cancelers.forEach(canceler => {
                                canceler();
                            });
                        }),
                    );
                },
            });

            // When the route changes, update the current SearchAttempt
            $rootScope.$on('$routeChangeSuccess', (evt, next) => {
                if (this.currentSearchAttempt) {
                    this.currentSearchAttempt.routeChange(evt, next);
                }
            });

            return {
                initialize(coursesScope) {
                    this.id = guid.generate();
                    this.filtersAdded(coursesScope, false);
                    this._log('library_search:start');
                },

                destroyIfChanged(coursesScope) {
                    // if a topic filter has been removed, then the user
                    // apparently did not find anything good, and is trying
                    // something else.
                    //
                    // We are purposely ignoring progressOptionsToFilterBy,
                    // because this seems less indicative of the success of the search.
                    //
                    // If someone clicks completed and then 'not started', that does
                    // not feel like a failed search.  At least not to me
                    for (const topic of this.filters.topicsToFilterBy) {
                        if (!coursesScope.topicsToFilterBy.includes(topic)) {
                            this.destroy();
                            return true;
                        }
                    }

                    // If the user just added to the search, then they are refining
                    // it further.  This is not a failure.  However, if they have
                    // deleted what they already had, then that indicates they
                    // did not find what they were looking for, and this search failed.
                    if (this.filters.searchText && !coursesScope.searchText.match(this.filters.searchText)) {
                        this.destroy();
                        return true;
                    }
                },

                filtersAdded(coursesScope, logUpdate) {
                    if (this.filters) {
                        if (this.destroyIfChanged(coursesScope)) {
                            return;
                        }
                    }

                    this.filters = {
                        // for topics, return the whole object, because the id refers
                        // to something in the db, which might be useful, and the name
                        // allows this event to be useful on it's own without joining
                        topicsToFilterBy: coursesScope.topicsToFilterBy.slice(0),

                        // for progress options, just return the id.  This does not
                        // refer to something in the database, and the id is human-readable
                        // just as much as the name (in_progress vs. 'In Progress')
                        progressOptionsToFilterBy: coursesScope.progressOptionsToFilterBy.map(opt => opt.id),
                        searchText: coursesScope.searchText,
                    };

                    this.result_stream_ids = (coursesScope.filteredStreams || []).map(s => s.id);

                    if (logUpdate) {
                        this._log('library_search:update');
                    }
                },

                routeChange(evt, next) {
                    const directive = next && next.$$route && next.$$route.directive && next.$$route.directive;
                    const params = ($route.current && $route.current.params) || {};
                    const routeIsForResultStream = this.result_stream_ids.includes(params.stream_id);

                    if (directive === 'stream-dashboard' && routeIsForResultStream) {
                        this.viewedStreamDashboardId = params.stream_id;
                        // do not close.  We still want to see if they launch the stream
                    } else if (directive === 'show-stream' && routeIsForResultStream) {
                        this.launchedLessonId = params.lesson_id;
                        this.launchedStreamId = params.stream_id;
                        this.destroy();
                    } else {
                        this.destroy();
                    }
                },

                destroy() {
                    if (SearchAttempt.currentSearchAttempt === this) {
                        SearchAttempt.currentSearchAttempt = undefined;
                    }
                    if (!this.destroyed) {
                        this._log('library_search:finish', {
                            aborted: !this.viewedStreamDashboardId && !this.launchedLessonId,
                            launched_lesson_id: this.launchedLessonId,
                            viewed_stream_dashboard_id: this.viewedStreamDashboardId,
                            launched_stream_id: this.launchedStreamId,
                        });
                    }
                    this.destroyed = true;
                },

                _log(eventType, obj = {}) {
                    EventLogger.log(
                        eventType,
                        angular.extend(
                            {
                                search_attempt_id: this.id,
                                topics_to_filter_by: this.filters.topicsToFilterBy,
                                progress_options_to_filter_by: this.filters.progressOptionsToFilterBy,
                                search_text: this.filters.searchText,
                                result_stream_ids: this.result_stream_ids,
                                has_some_results: this.result_stream_ids.length > 0,
                            },
                            obj,
                        ),
                    );
                },
            };
        });

        return SearchAttempt;
    },
]);
