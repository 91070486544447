import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory('Lesson.MessagingService', [
    '$injector',
    $injector => {
        const SuperModel = $injector.get('SuperModel');
        const $rootScope = $injector.get('$rootScope');
        const safeApply = $injector.get('safeApply');
        const $compile = $injector.get('$compile');
        const guid = $injector.get('guid');

        const MessagingService = SuperModel.subclass(function () {
            Object.defineProperty(this.prototype, 'hasMessage', {
                get() {
                    return !!this._messageInfo;
                },
                configurable: true,
            });

            ['correct', 'correctExists'].forEach(prop => {
                Object.defineProperty(this.prototype, prop, {
                    get() {
                        return this._messageInfo ? this._messageInfo[prop] : undefined;
                    },
                });
            });

            return {
                initialize() {
                    this.messageId = undefined;
                    this._messageInfo = undefined;
                    this._elements = [];
                    this._scopes = [];
                },

                showMessage(messageInfo) {
                    this._scopes.forEach(scope => {
                        scope.$destroy();
                    });
                    this._scopes = [];

                    this._elements.forEach(el => {
                        el.remove();
                    });
                    this._elements = [];

                    this._messageInfo = messageInfo;
                    this.messageId = messageInfo ? guid.generate() : undefined;

                    // we need to call safeApply explicitly to deal
                    // with the possibility that this is shared across windows
                    safeApply($rootScope);
                },

                clearMessage() {
                    this.showMessage(undefined);
                },

                getElement() {
                    // since this element may exist in multiple places in the UI,
                    // we need to generate separate elements for the same message
                    const scope = $rootScope.$new();
                    this._scopes.push(scope);
                    const element = $(this._messageInfo.content);
                    this._elements.push(element);

                    angular.forEach(this._messageInfo.scope || {}, (val, key) => {
                        scope[key] = val;
                    });
                    $compile(element)(scope);
                    return element;
                },
            };
        });

        return new MessagingService();
    },
]);
