import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import { setupStyleHelpers } from 'AppBranding';
import template from 'Lessons/angularModule/views/stream/student_dashboard_completed_courses.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

import iconTrophy from 'images/icon_trophy.png';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('studentDashboardCompletedCourses', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');

        return {
            scope: {
                completedStreams: '<',
            },
            restrict: 'E',
            templateUrl,
            controllerAs: 'controller',

            link(scope) {
                scope.iconTrophy = iconTrophy;

                setupStyleHelpers($injector, scope);

                // Referenced inside of AppBrandMixin.
                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                // Only show first X completed courses
                scope.showInfo = {
                    initialDisplayed: 3,
                    completedStreams: scope.completedStreams,
                };

                scope.showInfo.showCompletedLimit = scope.showInfo.initialDisplayed;

                scope.showAllCompleted = () => {
                    scope.showInfo.showCompletedLimit = undefined; // no limit
                };
            },
        };
    },
]);
