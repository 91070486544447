import { formatScore } from 'FormatScore';
import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/stream/assessment_score_arc.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('assessmentScoreArc', [
    '$injector',

    function factory() {
        return {
            scope: {
                score: '<?',
                radius: '<?',
                strokeWidth: '<?',
                arcTrackColor: '<?',
                arcFilledColor: '<?',
                scoreFontSize: '<?',
                scoreFont: '<?',
                scoreFontWeight: '<?',
            },

            restrict: 'E',
            templateUrl,
            controllerAs: 'controller',

            link(scope) {
                // Defaults
                scope.radius = angular.isUndefined(scope.radius) ? 30 : scope.radius;
                scope.strokeWidth = angular.isUndefined(scope.strokeWidth) ? 8 : scope.strokeWidth;
                scope.arcTrackColor = angular.isUndefined(scope.arcTrackColor) ? '#DFD9D2' : scope.arcTrackColor;
                scope.arcFilledColor = angular.isUndefined(scope.arcFilledColor) ? '#1DD0B4' : scope.arcFilledColor;
                scope.scoreFont = angular.isUndefined(scope.scoreFont) ? 'ProximaNovaSoft' : scope.scoreFont;
                scope.scoreFontWeight = angular.isUndefined(scope.scoreFontWeight) ? 'bold' : scope.scoreFontWeight;
                scope.scoreFontSize = angular.isUndefined(scope.scoreFontSize) ? 18 : scope.scoreFontSize;
                scope.scoreTextColor = angular.isUndefined(scope.score) ? scope.arcTrackColor : scope.arcFilledColor;

                // from http://stackoverflow.com/questions/5736398/how-to-calculate-the-svg-path-for-an-arc-of-a-circle
                function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
                    const angleInRadians = (angleInDegrees * Math.PI) / 180.0;
                    const x = centerX + radius * Math.cos(angleInRadians);
                    const y = centerY + radius * Math.sin(angleInRadians);
                    return [x, y];
                }

                scope.angleToPointString = angleInDegrees =>
                    polarToCartesian(scope.center, scope.center, scope.radius, angleInDegrees).join(',');

                const padding = 2; // don't know why we need this, but we get cut off on the edges otherwise
                scope.size = 2 * scope.radius + scope.strokeWidth + 2 * padding;
                scope.center = scope.radius + scope.strokeWidth / 2 + padding;
                scope.startAngle = 135; // bottom left
                scope.finishAngle = 360 + 45; // bottom right

                const scoreForCalculations = scope.score || 0;
                // NOTE: The rounding strategy used here should mimic the rounding strategy used when calculating
                // the examScore in stream_dashboard_dir.js. Otherwise, for exam streams that have only one lesson
                // the exam score could be slightly different from the lesson score.
                scope.displayScore = formatScore(scoreForCalculations);

                const totalDegrees = scope.finishAngle - scope.startAngle;
                const filledDegrees = scoreForCalculations * totalDegrees;
                scope.largeArcFlag = filledDegrees < 180 ? 0 : 1;
                scope.filledFinishAngle = scope.startAngle + filledDegrees;
            },
        };
    },
]);
