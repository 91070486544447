import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import 'FrontRoyalUiBootstrap/tooltip';
import template from 'Lessons/angularModule/views/stream/stream_link_box.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

import starWhite from 'vectors/star_white.svg';
import comingSoonIcon from 'images/coming_soon_icon.png';
import trackIconLocked from 'vectors/track_icon_locked.svg';
import cloudWCheckmark from 'vectors/cloud-w-checkmark.svg';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('streamLinkBox', [
    '$injector',

    function factory($injector) {
        const StreamActivationHelperMixin = $injector.get('StreamActivationHelperMixin');
        const offlineModeManager = $injector.get('offlineModeManager');
        const safeApply = $injector.get('safeApply');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                stream: '<',
                navigationEnabled: '<?',
                bookmarkEnabled: '<?',
                keepLearning: '<?',
                playlistTrack: '<?',
                showProgress: '<',
                keepLearningStream: '<',
                listView: '<',
            },
            link(scope) {
                scope.starWhite = starWhite;
                scope.comingSoonIcon = comingSoonIcon;
                scope.trackIconLocked = trackIconLocked;
                scope.cloudWCheckmark = cloudWCheckmark;
                scope.inOfflineMode = offlineModeManager.inOfflineMode;
                StreamActivationHelperMixin.onLink(scope);
                scope.proxy = {};

                Object.defineProperty(scope, 'showBookmarkToggle', {
                    get() {
                        return scope.currentUser && scope.bookmarkEnabled && !scope.locked;
                    },
                });

                //---------------------------
                // Interactivity
                //---------------------------

                scope.toggleBookmark = (stream, evt) => {
                    // this is most likely buried inside an href, so ...
                    evt.preventDefault();
                    evt.stopImmediatePropagation();

                    // update bookmark
                    if (scope.currentUser) {
                        scope.currentUser.toggleBookmark(stream);
                    }
                };

                //----------------------------
                // Display Helpers
                //----------------------------

                // produces completion statistics for the stream
                function buildStats() {
                    const stream = scope.stream;
                    const streamStatistics = [];

                    if (stream.progressStatus() === 'not_started' || scope.playlistTrack) {
                        streamStatistics.push({
                            title: scope.translationHelper.get('lessons'),
                            count: stream.lessons ? stream.lessons.length : '?',
                            fraction: false,
                            total: '',
                        });
                    } else {
                        streamStatistics.push({
                            title: scope.translationHelper.get('lessons_completed'),
                            count: stream.lessonsCompletedCount,
                            fraction: true,
                            total: stream.lessons ? stream.lessons.length : '?',
                        });
                    }

                    const showAvgLessonTime = false;
                    let showTimeLimit = false;
                    // NOTE: Accreditation has forced us to remove time estimates for now.
                    if (stream.progressStatus() !== 'completed' && !stream.exam) {
                        // showAvgLessonTime = true;
                    } else if (scope.playlistTrack && !stream.exam) {
                        // showAvgLessonTime = true;
                    } else if (scope.playlistTrack && stream.exam) {
                        showTimeLimit = true;
                    }
                    if (showAvgLessonTime) {
                        streamStatistics.push({
                            title: scope.translationHelper.get('avg_lesson_time'),
                            count: Math.ceil(stream.approxAverageLessonMinutes),
                            fraction: false,
                            total: scope.translationHelper.get('minute_abbr'),
                        });
                    }
                    if (showTimeLimit) {
                        streamStatistics.push({
                            title: scope.translationHelper.get('time_limit'),
                            count: stream.timeLimitHours,
                            fraction: false,
                            total: scope.translationHelper.get('hours_abbr'),
                        });
                    }

                    scope.streamStatistics = streamStatistics;
                }

                const getCompletionCount = () => {
                    if (!scope.stream?.lessons?.length || scope.stream?.progressStatus() !== 'in_progress') return;

                    const data = { count: scope.stream.lessons.length, completed: scope.stream.lessonsCompletedCount };

                    scope.lessonCompletionCount = scope.translationHelper.get('lesson_completion_count', data);
                };

                scope.getStreamClasses = stream => {
                    if (!stream) {
                        return undefined;
                    }

                    const classes = [];
                    if (scope.locked) {
                        classes.push('locked');
                    } else if (scope.keepLearning) {
                        classes.push('keep-learning');
                    } else {
                        classes.push(stream.progressStatus());
                    }

                    if (scope.playlistTrack) {
                        classes.push('playlist-track');
                    }

                    return classes;
                };

                //----------------------------
                // Watches
                //----------------------------

                scope.$watch('stream', async () => {
                    if (scope.stream) {
                        buildStats();
                        getCompletionCount();

                        offlineModeManager.streamIsAvailableOffline(scope.stream).then(availableOffline => {
                            // When there is no playlistTrack, there is text in the bottom
                            // right, so we can't put the cloud icon there.  In that case,
                            // we move it up above the header box.
                            if (availableOffline) {
                                if (scope.playlistTrack) {
                                    scope.proxy.offlineIconPosition = 'header-box';
                                } else {
                                    scope.proxy.offlineIconPosition = 'topics-box';
                                }
                            } else {
                                scope.proxy.offlineIconPosition = null;
                            }

                            // streamIsAvailableOffline returns a native promise
                            safeApply(scope);
                        });
                    }
                });

                // debug
                scope.$watch('stream', stream => {
                    if (stream) {
                        stream.notifyOnDisagreementBetweenLessonAndStreamProgress();
                    }
                });
            },
        };
    },
]);
