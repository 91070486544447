import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import { targetBrandConfig } from 'AppBranding';
import { setupStyleHelpers, setupBrandNameProperties } from 'AppBranding';
import { getActivePlaylistShowButtonLocaleKey } from 'StudentDashboard';

import template from 'Lessons/angularModule/views/stream/student_dashboard_learning_box.html';
import activePlaylistViewTemplate from 'Lessons/angularModule/views/stream/learning_box_active_playlist_view.html';
import coursesViewTemplate from 'Lessons/angularModule/views/stream/learning_box_courses_view.html';
import playlistsViewTemplate from 'Lessons/angularModule/views/stream/learning_box_playlists_view.html';

import cacheAngularTemplate from 'cacheAngularTemplate';

import comingSoonIcon from 'images/coming_soon_icon.png';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('studentDashboardLearningBox', [
    '$injector',

    function factory($injector) {
        const Playlist = $injector.get('Playlist');
        const EventLogger = $injector.get('EventLogger');
        const RouteAnimationHelper = $injector.get('RouteAnimationHelper');
        const AppHeaderViewModel = $injector.get('Navigation.AppHeader.AppHeaderViewModel');
        const TranslationHelper = $injector.get('TranslationHelper');
        const $timeout = $injector.get('$timeout');
        const Cohort = $injector.get('Cohort');
        const $rootScope = $injector.get('$rootScope');
        const isMobileMixin = $injector.get('isMobileMixin');
        const ContentAccessHelper = $injector.get('ContentAccessHelper');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                hasConcentrations: '<',
                learningBoxMode: '<',
                currentUser: '<',
                playlists: '<',
                streams: '<',
                activePlaylist: '<',
                keepLearningStream: '<',
                keepLearningLesson: '<',
                recommendedPlaylist: '<',
                activatePlaylist: '<',
                relevantCohort: '<',
                readyToShowPlaylistMap: '<',
            },
            link(scope) {
                isMobileMixin.onLink(scope);

                scope.activePlaylistViewTemplate = activePlaylistViewTemplate;
                scope.coursesViewTemplate = coursesViewTemplate;
                scope.playlistsViewTemplate = playlistsViewTemplate;
                scope.comingSoonIcon = comingSoonIcon;

                // If the user has a relevant cohort, then the courses shown here are coming
                // from that cohort, so it makes sense to use that as the branding.  If the
                // user does not have a relevant cohort, this will fall back to the user's
                // branding, or if there is no user then the domain's.
                const brandingOpts = { branding: () => scope.currentUser?.relevantCohort?.branding };
                setupBrandNameProperties($injector, scope, brandingOpts);
                setupStyleHelpers($injector, scope, brandingOpts);
                scope.defaultPlaylistIcon = Playlist.DEFAULT_ICON;

                // We just add this timeout to break the digest change and
                // prevent a 10-digest error
                $timeout().then(() => {
                    scope.waitedABeat = true;
                });

                //---------------------------
                // Initialization
                //---------------------------

                const translationHelper = new TranslationHelper('lessons.stream.student_dashboard_learning_box');

                scope.Playlist = Playlist;

                scope.concentrationPlaylists = scope.relevantCohort
                    ? scope.relevantCohort.getConcentrationPlaylists(scope.playlists)
                    : scope.playlists;

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                    configurable: true,
                });

                Object.defineProperty(scope, 'showCompletePlaylistHeader', {
                    get() {
                        return scope.activePlaylist && scope.activePlaylist.complete;
                    },
                });

                Object.defineProperty(scope, 'showCompletePlaylistsHeader', {
                    get() {
                        return (
                            scope.activePlaylist &&
                            scope.activePlaylist.complete &&
                            scope.recommendedPlaylist &&
                            scope.activePlaylist !== scope.recommendedPlaylist
                        );
                    },
                });

                Object.defineProperty(scope, 'playlistsPercentComplete', {
                    get() {
                        return (
                            scope.relevantCohort &&
                            scope.relevantCohort.getSelectedCoursesPercentComplete(scope.playlists)
                        );
                    },
                });

                Object.defineProperty(scope, 'justStarting', {
                    get() {
                        return scope.streams && !scope.keepLearningStream;
                    },
                });

                Object.defineProperty(scope, 'playlistsViewDescriptionLocaleKey', {
                    get() {
                        // FIXME: seems like the relevantCohort?.learningBoxDescriptionKey should
                        // be unnecessary, but MiyaMiya users have the "external" programType for some reason
                        return (
                            scope.currentUser?.relevantCohort?.learningBoxDescriptionKey ||
                            Cohort.learningBoxDescriptionKey(scope.currentUser?.programType) ||
                            'description_playlists'
                        );
                    },
                });

                Object.defineProperty(scope, 'playlistsViewTitleKey', {
                    get() {
                        // FIXME: seems like the relevantCohort?.learningBoxDescriptionKey should
                        // be unnecessary, but MiyaMiya users have the "external" programType for some reason
                        return (
                            scope.currentUser?.relevantCohort?.learningBoxTitleKey ||
                            Cohort.learningBoxTitleKey(scope.currentUser?.programType) ||
                            'playlists'
                        );
                    },
                });

                Object.defineProperty(scope, 'activePlaylistViewShowButtonLocaleKey', {
                    get() {
                        return getActivePlaylistShowButtonLocaleKey(scope.currentUser, Cohort);
                    },
                });

                Object.defineProperty(scope, 'programTitle', {
                    get() {
                        return scope.currentUser?.relevantCohort?.standaloneShortProgramTitle;
                    },
                });

                scope.numUnlockedConcentrationPlaylists = scope.concentrationPlaylists.filter(playlist =>
                    ContentAccessHelper.canLaunch(playlist),
                ).length;

                // For a user with no unlocked playlists (i.e. an exec ed user before the start of their cohort),
                // show the playlists view, in which everything will be locked. Do not show the active_playlist view,
                // even if they have an active playlist.
                if (scope.numUnlockedConcentrationPlaylists === 0 && scope.concentrationPlaylists.length) {
                    scope.learningBoxMode.mode = 'playlists';
                }

                //---------------------------
                // Navigation
                //---------------------------

                scope.launchKeepLearningStream = () => {
                    if (!scope.keepLearningStream) return;

                    EventLogger.log('student-dashboard:keep-learning');
                    RouteAnimationHelper.animatePathChange(scope.keepLearningStream.streamDashboardPath, 'slide-left');
                };

                scope.launchKeepLearningLesson = () => {
                    scope.keepLearningLesson.launch(`keep_learning_${scope.keepLearningLesson.launchText}`);
                };

                scope.switchLearningBoxMode = mode => {
                    scope.learningBoxMode.mode = mode;
                };

                //----------------------------
                // Watches
                //----------------------------

                scope.$watchCollection('learningBoxMode', learningBoxMode => {
                    AppHeaderViewModel.learningBoxMode = learningBoxMode;
                });

                scope.$watchGroup(
                    [() => AppHeaderViewModel.learningBoxMode?.mode, 'windowInnerWidth'],
                    ([mode, windowInnerWidth]) => {
                        // set the title based on the learningBoxMode
                        if (mode === 'playlists') {
                            if (scope.currentUser.hasExternalInstitution) {
                                AppHeaderViewModel.setTitleHTML(translationHelper.get('select_playlist_title'));
                            } else {
                                AppHeaderViewModel.setTitleHTML(translationHelper.get('select_concentration_title'));
                            }
                        } else {
                            const brandConfig = targetBrandConfig(scope.currentUser);
                            const requiresShortTitle = windowInnerWidth <= 400;
                            const institutionName = requiresShortTitle
                                ? brandConfig.brandNameShort
                                : brandConfig[brandConfig.appHeaderHomeTitleNameProperty];
                            // We don't need the 'small' class if the title is short enough
                            const small = !requiresShortTitle;
                            AppHeaderViewModel.setTitleHTML(
                                translationHelper.get('home_title', { institutionName }).toLocaleUpperCase(),
                                { small },
                            );
                        }
                    },
                );

                scope.$on('$destroy', () => {
                    AppHeaderViewModel.learningBoxMode = null;
                });
            },
        };
    },
]);
