import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory('StudentDashboard', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');

        return Iguana.subclass(function () {
            this.setCollection('student_dashboards');
            this.alias('StudentDashboard');
            this.embedsOne('active_playlist', 'Playlist');
            this.embedsMany('lesson_streams', 'Lesson.Stream');
            this.embedsMany('available_playlists', 'Playlist');
            this.embedsMany('past_due_projects', 'LearnerProject');

            return {};
        });
    },
]);
