import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import 'ExtensionMethods/array';
import template from 'Lessons/angularModule/views/stream/browse_courses.html';
import toggleableCourseListButtons from 'Lessons/angularModule/views/stream/toggleable_course_list_buttons.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

import closeIconBeigeDarker from 'vectors/close_icon_beige_darker.svg';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('browseCourses', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const $location = $injector.get('$location');
        const ClientStorage = $injector.get('ClientStorage');
        const Stream = $injector.get('Lesson.Stream');
        const StreamDashboardDirHelper = $injector.get('Stream.StreamDashboardDirHelper');
        const AppHeaderViewModel = $injector.get('Navigation.AppHeader.AppHeaderViewModel');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const EventLogger = $injector.get('EventLogger');
        const stemmer = $injector.get('stemmer');
        const SearchAttempt = $injector.get('SearchAttempt');
        const SiteMetadata = $injector.get('SiteMetadata');
        const HasToggleableDisplayMode = $injector.get('HasToggleableDisplayMode');
        const TranslationHelper = $injector.get('TranslationHelper');
        const ContentAccessHelper = $injector.get('ContentAccessHelper');
        const Locale = $injector.get('Locale');
        const safeDigest = $injector.get('safeDigest');

        return {
            scope: {},
            restrict: 'E',
            templateUrl,
            controllerAs: 'controller',

            link(scope) {
                scope.closeIconBeigeDarker = closeIconBeigeDarker;
                scope.toggleableCourseListButtons = toggleableCourseListButtons;

                const translationHelper = new TranslationHelper('lessons.stream.browse_courses');
                scope.Locale = $injector.get('Locale');

                //------------------------------
                // Initialization
                //------------------------------

                SearchAttempt.watchCoursesScope(scope);

                scope.searchPlaceholder = translationHelper.get('search_placeholder');

                // Get current user onto the scope
                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                Object.defineProperty(scope, 'blueOceanUser', {
                    get() {
                        return scope.currentUser && scope.currentUser.blueOcean;
                    },
                    configurable: true, // specs
                });

                StreamDashboardDirHelper.onLink(scope);
                NavigationHelperMixin.onLink(scope);
                HasToggleableDisplayMode.onLink(scope, 'toggleableCourseListLibrary', false);

                // Set up header
                AppHeaderViewModel.setBodyBackground('beige');
                AppHeaderViewModel.showAlternateHomeButton = false;
                AppHeaderViewModel.setTitleHTML(translationHelper.get('courses_title'));

                // Default title
                SiteMetadata.updateHeaderMetadata();

                scope.topicsToFilterBy = [];
                scope.progressOptionsToFilterBy = [];
                scope.selectFiltersForm = {
                    topicsToFilterBy: [],
                    progressOptionsToFilterBy: [],
                    selectedStatus: 'any_status',
                };
                scope.proxy = {};

                const itemsToFiltersMap = {};

                // available topics will be build once streams are loaded
                scope.availableTopics = [];

                // availableProgressOptions is hard-coded, since we know what options exist
                scope.availableProgressOptions = [
                    {
                        id: 'not_started',
                        name: translationHelper.get('not_started'),
                    },
                    {
                        id: 'in_progress',
                        name: translationHelper.get('in_progress'),
                    },
                    {
                        id: 'completed',
                        name: translationHelper.get('completed'),
                    },
                ];

                // availableStatusOptions is hard-coded, since we know what options exist
                scope.availableStatusOptions = {};
                ['any_status', 'available', 'new_courses', 'updated', 'beta', 'coming_soon', 'elective'].forEach(
                    status => {
                        const statusInfo = {
                            id: status,
                            name: translationHelper.get(status),
                            display: false,
                        };

                        if (status === 'new_courses') {
                            statusInfo.itemProperty = 'just_added';
                        } else if (status === 'updated') {
                            statusInfo.itemProperty = 'just_updated';
                        } else if (status === 'beta') {
                            statusInfo.itemProperty = 'beta';
                        } else if (status === 'coming_soon') {
                            statusInfo.itemProperty = 'coming_soon';
                        } else if (status === 'elective') {
                            statusInfo.itemProperty = 'elective';
                        }

                        scope.availableStatusOptions[status] = statusInfo;
                    },
                );

                //---------------------------
                // Saved Search Params
                //---------------------------

                let savedTopicsToFilter;

                let savedProgressesToFilter;
                let savedSearchText;
                let savedSelectedStatus;
                let savedFiltersLoaded = false;

                // check query params; if any are set, they take precedence
                // this allows us to override whatever is the last saved search when linking to a search
                if (
                    $location.search()['topics[]'] ||
                    $location.search()['progress[]'] ||
                    $location.search().searchText ||
                    $location.search().selectedStatus
                ) {
                    savedTopicsToFilter = $location.search()['topics[]'] || [];
                    // if just one param is specified, it comes back as a string from .search()
                    if (savedTopicsToFilter.constructor !== Array) {
                        savedTopicsToFilter = [savedTopicsToFilter];
                    }

                    savedProgressesToFilter = $location.search()['progress[]'] || [];
                    // if just one param is specified, it comes back as a string from .search()
                    if (savedProgressesToFilter.constructor !== Array) {
                        savedProgressesToFilter = [savedProgressesToFilter];
                    }
                    savedSearchText = $location.search().searchText;
                    savedSelectedStatus = $location.search().selectedStatus;
                } else {
                    // otherwise check ClientStorage
                    try {
                        savedTopicsToFilter = JSON.parse(ClientStorage.getItem('librarySearchTopic'));
                        // eslint-disable-next-line no-empty
                    } catch (e) {}
                    try {
                        savedProgressesToFilter = JSON.parse(ClientStorage.getItem('librarySearchProgress'));
                        // eslint-disable-next-line no-empty
                    } catch (e) {}
                    try {
                        savedSearchText = JSON.parse(ClientStorage.getItem('librarySearchText'));
                        // eslint-disable-next-line no-empty
                    } catch (e) {}
                    try {
                        savedSelectedStatus = JSON.parse(ClientStorage.getItem('librarySelectedStatus'));
                        // eslint-disable-next-line no-empty
                    } catch (e) {}
                }

                function saveSearchParams() {
                    if (!savedFiltersLoaded) {
                        return;
                    }

                    const searchText = scope.searchText.length > 0 ? scope.searchText : undefined;
                    const selectedStatus = scope.selectedStatus;
                    const topics = scope.topicsToFilterBy;
                    const progresses = scope.progressOptionsToFilterBy.map(p => p.name);

                    // update search params and save to localstorage
                    $location.search('topics[]', topics);
                    $location.search('progress[]', progresses);
                    $location.search('searchText', searchText);
                    $location.search('selectedStatus', selectedStatus);

                    ClientStorage.setItem('librarySearchTopic', JSON.stringify(topics));
                    ClientStorage.setItem('librarySearchProgress', JSON.stringify(progresses));
                    ClientStorage.setItem('librarySearchText', JSON.stringify(searchText));
                    ClientStorage.setItem('librarySelectedStatus', JSON.stringify(selectedStatus));
                }

                //---------------------------
                // Navigation
                //---------------------------

                scope.log = message => {
                    EventLogger.log(`browse-courses-dashboard:${message}`);
                };

                //----------------------------
                // Utility
                //----------------------------

                scope.alphabeticalSort = (array, key) => {
                    const newArray = array;
                    return newArray.sort((a, b) => {
                        let aValue;
                        let bValue;

                        if (key) {
                            aValue = a[key];
                            bValue = b[key];
                        } else {
                            aValue = a;
                            bValue = b;
                        }

                        const aValueCaps = aValue.toUpperCase();
                        const bValueCaps = bValue.toUpperCase();

                        if (aValueCaps < bValueCaps) {
                            return -1;
                        }

                        if (bValueCaps < aValueCaps) {
                            return 1;
                        }

                        return 0;
                    });
                };

                //---------------------------
                // Data Loading & Ordering
                //---------------------------

                function loadStreams() {
                    scope.streamGroups = undefined;
                    const params = {
                        filters: { published: true },
                        summary: true,
                        include_progress: true,
                    };

                    if (!scope.currentUser) params.filters.in_locale_or_en = Locale.activeCode;

                    Stream.indexForCurrentUser(params).then(response => {
                        // Update streams and counts of various statistics
                        const streams = response.result;
                        scope.streams = streams;
                        scope.addFilters(scope.streams, 'stream');

                        // lookup the saved values
                        const topics = scope.availableTopics.filter(
                            name => savedTopicsToFilter && savedTopicsToFilter.includes(name),
                        );
                        const progresses = scope.availableProgressOptions.filter(
                            p => savedProgressesToFilter && savedProgressesToFilter.includes(p.name),
                        );

                        if (topics) {
                            scope.selectFiltersForm.topicsToFilterBy = topics;
                        }
                        if (progresses) {
                            scope.selectFiltersForm.progressOptionsToFilterBy = progresses;
                        }
                        if (savedSearchText) {
                            scope.selectFiltersForm.searchText = savedSearchText;
                        }
                        if (savedSelectedStatus) {
                            scope.selectFiltersForm.selectedStatus = savedSelectedStatus;
                        }

                        // start allowing things to be saved
                        savedFiltersLoaded = true;

                        // HasToggleableDisplayMode controls DOM pre-rendering
                        scope.preloadAllDisplayModes();
                    });
                }

                scope.availableTopics = [];
                loadStreams();

                scope.orderStreams = streams => scope.alphabeticalSort(streams, 'title');
                scope.orderStreamsGroupedByTopic = streamsMap => {
                    const streamsGroupedByTopic = {};
                    const topicsToShow = Object.keys(streamsMap).filter(topic => {
                        if (scope.selectFiltersForm.topicsToFilterBy.length === 0) {
                            return true;
                        }

                        return scope.selectFiltersForm.topicsToFilterBy.indexOf(topic) > -1;
                    });

                    scope.alphabeticalSort(topicsToShow).forEach(topic => {
                        const streams = streamsMap[topic];
                        streams.forEach(stream => {
                            if (Object.prototype.hasOwnProperty.call(streamsGroupedByTopic, topic)) {
                                streamsGroupedByTopic[topic] = [...streamsGroupedByTopic[topic], stream];
                            } else {
                                streamsGroupedByTopic[topic] = [stream];
                            }
                        });
                    });

                    return streamsGroupedByTopic;
                };

                //----------------------------
                // Lazy Loading
                //----------------------------

                const INITIAL_LAZY_LOAD_LIMIT = 20;
                scope.lazyLoadLimit = INITIAL_LAZY_LOAD_LIMIT;
                scope.lazyLoadStreams = () => {
                    if (scope.dashboardDisplayMode.key === 'flat' && scope.orderedStreams) {
                        scope.lazyLoadedStreams = scope.orderedStreams.slice(0, scope.lazyLoadLimit);
                    }

                    if (scope.dashboardDisplayMode.key === 'topic' && scope.orderedStreamsGroupedByTopic) {
                        let counter = 0;
                        const lazyLoadedStreamsGroupedByTopic = {};
                        Object.keys(scope.orderedStreamsGroupedByTopic).forEach(topic => {
                            const streams = scope.orderedStreamsGroupedByTopic[topic];
                            streams.forEach(stream => {
                                if (counter >= scope.lazyLoadLimit) {
                                    return;
                                }

                                if (Object.prototype.hasOwnProperty.call(lazyLoadedStreamsGroupedByTopic, topic)) {
                                    lazyLoadedStreamsGroupedByTopic[topic] = [
                                        ...lazyLoadedStreamsGroupedByTopic[topic],
                                        stream,
                                    ];
                                } else {
                                    lazyLoadedStreamsGroupedByTopic[topic] = [stream];
                                }

                                counter += 1;
                            });
                        });

                        scope.lazyLoadedStreamsGroupedByTopic = lazyLoadedStreamsGroupedByTopic;
                    }
                };

                const observerCallback = (entries, obs) => {
                    entries.forEach(entry => {
                        if (entry.isIntersecting && scope.lazyLoadLimit < scope.filteredStreams.length) {
                            scope.lazyLoadLimit += 10;
                            obs.unobserve(entry.target);
                            scope.lazyLoadStreams();
                            safeDigest(scope);
                        }
                    });
                };
                const observerOptions = { rootMargin: '0px 0px 600px 0px' };
                const observer = new IntersectionObserver(observerCallback, observerOptions);

                scope.$watchGroup(['orderedStreams', 'orderedStreamsGroupedByTopic'], () => {
                    scope.lazyLoadStreams();
                });

                scope.$watchGroup(['lazyLoadedStreams', 'lazyLoadedStreamsGroupedByTopic'], () => {
                    if (scope.filteredStreams?.length) {
                        setTimeout(() => {
                            const selector =
                                scope.dashboardDisplayMode.key === 'flat'
                                    ? '.stream:last-of-type'
                                    : '.topic-course-list:last-of-type .stream:last-of-type';

                            const selectorElement = document.querySelector(selector);
                            if (selectorElement) {
                                observer.observe(selectorElement);
                            }
                        }, 0);
                    }
                });

                //------------------------------
                // Topic / Progress Filtering
                //------------------------------

                /*
                        addFilters runs when streams are loaded.

                        It loops through the loaded records and re-formats the information
                        in a way that will be optimized for filtering later.

                        Each record gets an entry in the itemsToFiltersMap.  The entry
                        stores the topics and the progress status for the record.
                    */
                scope.addFilters = records => {
                    // reset display
                    angular.forEach(scope.availableStatusOptions, (statusOption, key) => {
                        statusOption.display = ['available', 'any_status'].includes(key);
                    });

                    scope.showExtendedStatusOptions = false;

                    records.forEach(record => {
                        itemsToFiltersMap[record.id] ||= {};

                        const itemsToFiltersEntry = itemsToFiltersMap[record.id];
                        itemsToFiltersEntry.topics = {};
                        itemsToFiltersEntry.progressStatus = record.progressStatus();

                        // flatten the record values into a single string so we can conduct substr searches
                        itemsToFiltersEntry.indexedSearchTerms = Object.keys(record.getSearchTermsSet()).join(' ');

                        record.contentTopicNames.forEach(topicName => {
                            if (!scope.availableTopics[topicName]) {
                                scope.availableTopics.push(topicName);

                                // Note: availableTopics is an array, but we add properties to
                                // it to keep track of what we already added
                                scope.availableTopics[topicName] = true;
                            }

                            itemsToFiltersEntry.topics[topicName] = true;
                        });

                        angular.forEach(scope.availableStatusOptions, statusOption => {
                            if (statusOption.itemProperty && record[statusOption.itemProperty]) {
                                statusOption.display = true;
                                scope.showExtendedStatusOptions = true;
                            }
                        });
                    });

                    scope.availableTopics.sort();
                };

                /*
                    shouldFilter out is run on a single stream.

                    It returns true if, given the current filters that the user
                    has selected, the item should be removed from the screen.
                */
                function shouldFilterOut(item) {
                    const itemsToFiltersEntry = itemsToFiltersMap[item.id];

                    // exclusive
                    if (scope.selectedStatus !== 'any_status') {
                        const selectedOption = scope.availableStatusOptions[scope.selectedStatus];
                        if (scope.selectedStatus === 'available' && !ContentAccessHelper.canLaunch(item)) {
                            return true;
                        }
                        if (scope.selectedStatus !== 'available' && !item[selectedOption.itemProperty]) {
                            return true;
                        }
                    }

                    // inclusive among topic types
                    if (scope.topicsToFilterBy.length > 0) {
                        let hasSomeTopic = false;

                        scope.topicsToFilterBy.forEach(topic => {
                            if (itemsToFiltersEntry.topics[topic]) {
                                hasSomeTopic = true;
                            }
                        });

                        if (!hasSomeTopic) {
                            return true;
                        }
                    }

                    // inclusive among progress types
                    if (scope.progressOptionsToFilterBy.length > 0) {
                        let hasSomeProgressFilter = false;
                        for (let j = 0; j < scope.progressOptionsToFilterBy.length; j++) {
                            const progressStatus = scope.progressOptionsToFilterBy[j].id;
                            if (itemsToFiltersEntry.progressStatus === progressStatus) {
                                hasSomeProgressFilter = true;
                                break;
                            }
                        }
                        if (!hasSomeProgressFilter) {
                            return true;
                        }
                    }

                    // exclusive
                    if (scope.searchText) {
                        let foundAllSearchTerms = true;
                        const searchTexts = scope.searchText.split(/\s+/);

                        // iterate through each word, stemming and performing substr against indexed terms
                        searchTexts.forEach(searchText => {
                            stemmer.stemmedWords(searchText).forEach(searchTerm => {
                                if (!itemsToFiltersEntry.indexedSearchTerms.includes(searchTerm)) {
                                    foundAllSearchTerms = false;
                                }
                            });
                        });

                        if (!foundAllSearchTerms) {
                            return true;
                        }
                    }

                    // defaults to allowing
                    return false;
                }

                /*
                    filterResults is run when records are loaded or when filters are selected
                    by the user.

                    It loops through the existing streams and selects
                    the ones that should be visible given the current filters that the user
                    has selected.
                */
                function filterResults() {
                    if (scope.lazyLoadLimit > INITIAL_LAZY_LOAD_LIMIT) {
                        // Resetting the lazy load limit here helps performance when changing display mode, changing filters, etc.
                        // Since all of the UI elements that trigger this function are at the top of the page,
                        // the user will not notice the stream list being reduced as they are all off screen.
                        scope.lazyLoadLimit = INITIAL_LAZY_LOAD_LIMIT;
                    }

                    if (!scope.streams) {
                        scope.filteredStreams = [];
                        return;
                    }

                    let filteredStreams = [];

                    if (
                        scope.topicsToFilterBy.length === 0 &&
                        scope.progressOptionsToFilterBy.length === 0 &&
                        !scope.searchText &&
                        scope.selectedStatus === 'any_status'
                    ) {
                        filteredStreams = scope.streams;
                    } else if (scope.streams) {
                        scope.streams.forEach(stream => {
                            if (!shouldFilterOut(stream)) {
                                filteredStreams.push(stream);
                            }
                        });
                    }

                    scope.filteredStreams = filteredStreams;

                    if (scope.dashboardDisplayMode.key === 'flat') {
                        scope.orderedStreams = scope.orderStreams(filteredStreams);
                    }

                    if (scope.dashboardDisplayMode.key === 'topic') {
                        scope.orderedStreamsGroupedByTopic = scope.orderStreamsGroupedByTopic(
                            // update the topic groups using helper method added to scope in HasToggleableDisplayMode
                            scope.createTopicGroups(filteredStreams),
                        );
                    }

                    scope.$emit('filtersUpdated');
                }

                // Topics
                scope.toggleTopicFilter = topic => {
                    if (scope.selectFiltersForm.topicsToFilterBy.includes(topic)) {
                        Array.remove(scope.selectFiltersForm.topicsToFilterBy, topic);
                    } else {
                        scope.selectFiltersForm.topicsToFilterBy.push(topic);
                    }
                };

                // Progress
                scope.toggleProgressFilter = progressOption => {
                    if (scope.selectFiltersForm.progressOptionsToFilterBy.includes(progressOption)) {
                        Array.remove(scope.selectFiltersForm.progressOptionsToFilterBy, progressOption);
                    } else {
                        scope.selectFiltersForm.progressOptionsToFilterBy.push(progressOption);
                    }
                    scope.selectFiltersForm.comingSoonFilter = undefined;
                };

                scope.toggleAnyProgressFilter = () => {
                    scope.selectFiltersForm.progressOptionsToFilterBy = [];
                };

                Object.defineProperty(scope, 'anyProgressToggled', {
                    get() {
                        return scope.selectFiltersForm.progressOptionsToFilterBy.length === 0;
                    },
                });

                // Publishing Status
                scope.toggleStatusFilter = status => {
                    if (scope.selectedStatus === status) {
                        scope.selectFiltersForm.selectedStatus = 'any_status';
                    } else {
                        scope.selectFiltersForm.selectedStatus = status;
                        if (status === 'coming_soon') {
                            scope.selectFiltersForm.progressOptionsToFilterBy = []; // can't have progress
                        }
                    }
                };

                //------------------------------
                // Form Modification
                //------------------------------

                function onFormChange() {
                    if (!scope.proxy.showFilterSelectFormOnMobile) {
                        scope.commitSelectFiltersFormChanges();
                    }
                }

                scope.commitSelectFiltersFormChanges = () => {
                    scope.topicsToFilterBy = scope.selectFiltersForm.topicsToFilterBy.slice(0);
                    scope.progressOptionsToFilterBy = scope.selectFiltersForm.progressOptionsToFilterBy.slice(0);
                    scope.proxy.showFilterSelectFormOnMobile = false;

                    // prevent regexp errors by removing special chars
                    scope.searchText = scope.selectFiltersForm.searchText
                        ? scope.selectFiltersForm.searchText.replace(/[{}/[\]\\()$^*+.,:?|]/g, '')
                        : '';
                    scope.selectedStatus = scope.selectFiltersForm.selectedStatus;

                    saveSearchParams();
                };

                scope.resetFilterSelectForm = () => {
                    scope.selectFiltersForm.topicsToFilterBy = scope.topicsToFilterBy.slice(0, 9999);
                    scope.selectFiltersForm.progressOptionsToFilterBy = scope.progressOptionsToFilterBy.slice(0);
                    scope.selectFiltersForm.searchText = scope.searchText;
                    scope.selectFiltersForm.selectedStatus = scope.selectedStatus;
                    scope.proxy.showFilterSelectFormOnMobile = false;

                    saveSearchParams();
                };

                scope.clearFilterAndSearch = () => {
                    scope.searchText = '';
                    scope.selectFiltersForm.searchText = '';
                    scope.selectedStatus = 'any_status';
                    scope.selectFiltersForm.selectedStatus = 'any_status';
                    scope.selectFiltersForm.progressOptionsToFilterBy = [];
                    scope.selectFiltersForm.topicsToFilterBy = [];

                    saveSearchParams();
                };

                //------------------------------
                // Watches
                //------------------------------

                // watches that actually invoke the filtering
                scope.$watchCollection('topicsToFilterBy', () => filterResults());
                scope.$watchCollection('progressOptionsToFilterBy', () => filterResults());
                scope.$watchCollection('searchText', () => filterResults());
                scope.$watch('selectedStatus', () => filterResults());
                scope.$watch('streams', () => filterResults());
                scope.$watch('dashboardDisplayMode.key', () => filterResults());

                // watches that do some sort of data manipulation prior to filtering
                scope.$watchCollection('selectFiltersForm.topicsToFilterBy', onFormChange);
                scope.$watchCollection('selectFiltersForm.progressOptionsToFilterBy', onFormChange);
                scope.$watch('selectFiltersForm.searchText', onFormChange);
                scope.$watch('selectFiltersForm.selectedStatus', onFormChange);

                scope.$watch('proxy.showFilterSelectFormOnMobile', val => {
                    AppHeaderViewModel.toggleVisibility(!val);
                    $injector.get('scrollHelper').scrollToTop();
                });
            },
        };
    },
]);
