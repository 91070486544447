/* eslint-disable func-names */
/* eslint-disable no-setter-return */
import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import { GOTO_FRAME_TRACING_NAME } from 'ErrorLogging';

/*

    This is a mixin that is included in both the FrameListPlayerViewModel and PracticeFramesPlayerViewModel
    classes.

    In both of those cases, the player is dealing with frames. But, in the FrameListPlayerViewModel there is an
    associated lesson that has a list of frames in a fixed order. In the PracticeFramesPlayerViewModel case, there
    is not a fixed list of frames or a fixed order. In order to fulfill the requirements of these different cases, we
    split the logic up into different mixins.
*/
angularModule.factory('PlayerViewModelWithFrames', [
    '$injector',

    $injector => {
        const AModuleAbove = $injector.get('AModuleAbove');
        const $window = $injector.get('$window');
        const ErrorLogService = $injector.get('ErrorLogService');
        const $q = $injector.get('$q');
        const Event = $injector.get('EventLogger.Event');
        const timerSingleton = $injector.get('timerSingleton');
        const $rootScope = $injector.get('$rootScope');

        return new AModuleAbove({
            included(target) {
                target.defineCallbacks('frame_started');
                target.defineCallbacks('frame_unloaded');
                target.defineCallbacks('frame_completed');

                target.setCallback('after', 'initialized', function () {
                    this.stopListeningForFramePreload = () => {};
                    this.targetFrame = null;
                });

                target.setCallback('before', 'destroyed', function () {
                    this.frameThatWasActiveWhenDestroyed = this.activeFrame; // used in the editor
                    this.activeFrame = null; // do any logging on frame unload
                    if (this.stopListeningForFramePreload) {
                        this.stopListeningForFramePreload();
                    }
                });

                target.setCallback('before', 'frame_unloaded', function () {
                    this._lastUnloadEvent = this._logFrameUnload({
                        segmentio: false, // no need to log this to segmentio until we need it there
                    });
                });

                target.setCallback('around', 'frame_started', function (changeActiveFrame) {
                    const self = this;
                    changeActiveFrame();

                    // showStartScreen has to be set after setting the activeFrame, so that
                    // setInitialActiveFrame is not called
                    self.showStartScreen = false;
                    self.showFinishScreen = false;
                    self.createFrameViewModel();

                    self._logFrameStart();

                    timerSingleton.finishTimer(this._gotoFrameTimerKey(this.activeFrame), 'frame_navigation_timer');
                    $rootScope.$broadcast('lesson:frame_started', this.activeFrame);

                    self.preloadAssetsForNextFrame();

                    // listen for completed event from componentized frames (non-componentized
                    // have no on method)
                    if (self.activeFrameViewModel.on) {
                        const frameViewModel = self.activeFrameViewModel;
                        self.activeFrameViewModel.on('completed', () => {
                            if (frameViewModel !== self.activeFrameViewModel) {
                                ErrorLogService.notify(
                                    'frame completed event observed for frame that is no longer active.',
                                    null,
                                    {
                                        observedOnFrameId: frameViewModel.frame.id,
                                        activeFrameId: self.activeFrameId,
                                    },
                                );
                            }
                            self.runCallbacks('frame_completed', () => {});
                        });
                    }
                });

                target.setCallback('after', 'frame_completed', function () {
                    this._logFrameFinish();
                });

                target.setCallback('after', 'frame_unloaded', function () {
                    this.activeFrameViewModel = undefined;
                    this._framePlayEvent = undefined;
                    this._frameFinishEvent = undefined;
                });

                const logInfo = target.prototype.logInfo;
                target.prototype.logInfo = function () {
                    const obj = logInfo.apply(this);
                    // we use the internal _activeFrame variable here because we want to log the
                    // leave event event if the frame has been removed from the
                    // frame list for some reason.  This may not be a real issue,
                    // but it makes philosophical sense to me and it makes tests pass,
                    // so I'm doing it.
                    const activeFrame = this._activeFrame;
                    const frameInfo = activeFrame ? activeFrame.logInfo() : {};

                    return angular.extend(obj, frameInfo, {
                        frame_play_id: this.activeFrameViewModel ? this.activeFrameViewModel.id : undefined,
                    });
                };

                Object.defineProperty(target.prototype, 'activeFrame', {
                    get() {
                        // just being defensive against developer error.  Anything that might remove a frame from the frames list
                        // should make sure that it is not the active frame
                        if (this._activeFrame && !this.frames.includes(this._activeFrame) && this.frames.length > 0) {
                            // TODO: this error sometimes masks legit angular errors
                            // for example: simple issue like undeclared variables in csv importers propagate to this
                            // exception instead of the descriptive angular-generated error
                            throw new Error('activeFrame has been removed from the frame list');
                        }

                        // we do not want to set the activeFrame automatically, because sometimes we need
                        // to set it to null (see EditFrameInfoPanelFrameTypeMixin `scope.$watch('options.frame_type')`)
                        return this._activeFrame;
                    },
                    set(frame) {
                        if (this.destroyed) {
                            // This might not cause any issues, but it is probably an indication that you're acting
                            // on the wrong lesson play. For example, see comment on
                            // https://trello.com/c/mTF2hWv3/710-bug-here-is-one-way-to-create-activeframe-removed-from-list#
                            if ($window.RUNNING_IN_TEST_MODE) {
                                throw new Error('Setting activeFrame on destroyed playerViewModel.');
                            }
                            ErrorLogService.notify('Setting activeFrame on destroyed playerViewModel.', null, {
                                level: 'warning',
                            });
                        }
                        this.stopListeningForFramePreload();

                        if (frame === this._activeFrame) {
                            return this._activeFrame;
                        }

                        this._changeActiveFrameAndRunNecessaryCallbacks(frame);

                        return frame;
                    },
                });

                Object.defineProperty(target.prototype, 'activeFrameId', {
                    get() {
                        return this.activeFrame && this.activeFrame.id;
                    },
                    set() {
                        throw new Error(
                            'activeFrameId must be defined by classes that include PlayerViewModelWithFrames',
                        );
                    },
                    configurable: true,
                });

                Object.defineProperty(target.prototype, 'frames', {
                    get() {
                        throw new Error('frames must be defined by classes that include PlayerViewModelWithFrames');
                    },
                    configurable: true,
                });

                Object.defineProperty(target.prototype, 'activeFrameIndex', {
                    get() {
                        return this.frames.indexOf(this.activeFrame);
                    },
                    set(index) {
                        this.activeFrame = this.frames[index];
                        return this.activeFrame;
                    },
                });

                Object.defineProperty(target.prototype, 'isCompletedTest', {
                    get() {
                        // This is overridden in FrameListPlayerViewModel
                        return false;
                    },
                    configurable: true,
                });
            },

            preloadAssetsForNextFrame() {
                // if there is no activeFrame yet, activeFrameIndex will be -1, and we'll preload the
                // first frame
                const nextIndex = this.activeFrameIndex + 1;
                const frame = this.frames[nextIndex];
                if (frame) {
                    return this.preloadAssetsForFrame(frame);
                }
                return $q.when();
            },

            skipFrame() {
                this.log('lesson:frame:skip');
                this.gotoNext();
            },

            gotoNext() {
                throw new Error('Classes that include PlayerViewModelWithFrames should define gotoNext()');
            },

            gotoPrev() {
                throw new Error('Classes that include PlayerViewModelWithFrames should define gotoPrev()');
            },

            gotoFrame(frame) {
                // If the user has an test open that was finished in another browser, we want to force them to the finish screen
                // See: https://trello.com/c/OYRYIHb7/376-1-user-with-store-enabled-can-get-stuck-unable-to-save-progress-for-completed-test-lesson
                if (this.isCompletedTest === true) {
                    this.showFinishScreen = true;
                    return;
                }
                if (!frame) {
                    throw new Error('No frame provided');
                }

                const timer = timerSingleton.startTimer(this._gotoFrameTimerKey(frame), {
                    sentryTransactionName: GOTO_FRAME_TRACING_NAME,
                });
                frame = this._reifyFrame(frame);
                this.stopListeningForFramePreload();

                this.activeFrame = undefined;
                this.targetFrame = frame;

                this._logFrameActivate(frame);

                this._ensureFrameReadyAndThenGotoFrame(frame, timer).finally(() => {
                    if (this.targetFrame === frame) {
                        this.targetFrame = null;
                    }
                });
            },

            _ensureFrameReadyAndThenGotoFrame(frame, timer) {
                const self = this;

                let canceled = false;
                this.stopListeningForFramePreload = () => {
                    canceled = true;
                };
                const preloadAssetsForFrameStep = timer.sentryTransaction?.startChild({
                    description: 'preloadAssetsForFrame',
                });
                const ensureRecentProgressSavedStep = timer.sentryTransaction?.startChild({
                    description: 'ensureRecentProgressSaved',
                });
                return $q
                    .all([
                        self.preloadAssetsForFrame(frame).then(() => {
                            preloadAssetsForFrameStep?.finish();
                        }),
                        self.ensureRecentProgressSaved(frame).then(() => {
                            ensureRecentProgressSavedStep?.finish();
                        }),
                    ])
                    .then(() => {
                        if (!canceled) {
                            self.activeFrame = frame;
                        }
                    });
            },

            gotoFrameIndex(index) {
                const frame = this.frames[index];
                this.gotoFrame(frame);
            },

            gotoFrameId(id) {
                const frame = _.find(this.frames, {
                    id,
                });
                this.gotoFrame(frame);
            },

            createFrameViewModel() {
                // This can happen, for example, if someone
                // clicks preview on a lesson with no frames yet
                if (!this.activeFrame) {
                    return;
                }
                this.activeFrameViewModel = this.activeFrame.createFrameViewModel();
                this.activeFrameViewModel.playerViewModel = this;
            },

            // See https://trello.com/c/WlSPo3eQ
            // We save progress once someone has completed all of the challenges on a frame. In most cases,
            // this should be complete before the user clicks the continue button, so this function will resolve
            // immediately.
            ensureRecentProgressSaved(frameNavigatingTo) {
                const progressFlushPromises = this._progressFlushPromises ?? {};
                const indexOfFrameNavigatingTo = this.frames.indexOf(frameNavigatingTo);

                const promisesThatMustResolveBeforeTransitioningToNextFrame = Object.values(
                    progressFlushPromises,
                ).filter(promise => {
                    if (!promise.metadata) {
                        return false;
                    }

                    // Using the frame index here only works as intended when the user is moving chronologically
                    // through the frames. On branching frames, we might skip some indexes. This isn't ideal,
                    // but it's pretty rare and the only effect is that there is a chance of someone seeing a
                    // (short-lived) loading spinner that they wouldn't otherwise see.
                    const { activeFrameIndex } = promise.metadata;

                    // for exams, ensure that any frames before the frame we're navigating to is saved
                    if (this.testOrAssessment && activeFrameIndex < indexOfFrameNavigatingTo) {
                        return true;
                    }

                    // for all other lessons, ensure that frame N-2 is saved before moving to frame N
                    if (!this.testOrAssessment && indexOfFrameNavigatingTo - activeFrameIndex >= 2) {
                        return true;
                    }

                    return false;
                });

                return $q.all(promisesThatMustResolveBeforeTransitioningToNextFrame);
            },

            //---------------------------------
            // Styling
            //---------------------------------

            mainContentStyles(frameViewModel) {
                const classes = ['animated', 'frame'];
                if (frameViewModel) {
                    classes.push(frameViewModel.frame.frame_type);
                }
                return classes;
            },

            //---------------------------------
            // Preloading
            //---------------------------------

            preloadAssetsForFrame(frame) {
                return this._reifyFrame(frame)
                    .preloadAssets()
                    .catch(err => {
                        // We are just catching loading errors here, which means that the
                        // user is gonna be stuck on the "Do you want to wait for this frame to load"
                        // screen even though we know it is never gonna load.  Maybe we should do
                        // something else, but what?
                        // see also: ImageModel:preload for explicit handling of image load failures
                        if (!err?.message?.includes('Failed to load')) {
                            throw err;
                        }
                    });
            },

            preloadAllImages() {
                angular.forEach(
                    this.frames,
                    function (frame) {
                        frame = this._reifyFrame(frame);
                        frame.preloadImages();
                    },
                    this,
                );
            },

            //---------------------------------
            // Logging
            //---------------------------------

            _changeActiveFrameAndRunNecessaryCallbacks(newActiveFrame) {
                const self = this;
                const changeActiveFrame = () => {
                    if (self.activeFrameViewModel) {
                        self.activeFrameViewModel.destroy();
                        self._activeFrame.$$expandExtraPanelsInitially = false;
                    }

                    // change the activeFrame, create a new frame play for it,
                    // log events, and store progress
                    self._activeFrame = newActiveFrame;
                };

                let unloadWithUnloadCallback;
                let unloadWithStartAndUnloadCallbacks;
                if (self._activeFrame) {
                    unloadWithUnloadCallback = () => {
                        self.runCallbacks('frame_unloaded', changeActiveFrame);
                    };
                } else {
                    unloadWithUnloadCallback = changeActiveFrame;
                }

                if (newActiveFrame) {
                    newActiveFrame = this._reifyFrame(newActiveFrame);
                    unloadWithStartAndUnloadCallbacks = () => {
                        self.runCallbacks('frame_started', unloadWithUnloadCallback);
                    };
                } else {
                    unloadWithStartAndUnloadCallbacks = unloadWithUnloadCallback;
                }
                unloadWithStartAndUnloadCallbacks();
            },

            _reifyFrame(frame) {
                if (frame.reified) {
                    return frame;
                }

                const index = this.frames.indexOf(frame);
                if (index < 0) {
                    ErrorLogService.notify('frame passed to _reifyFrame is not in frame list');
                }
                const reified = frame.reify();
                if (index > -1) {
                    this.frames[index] = reified;
                }
                return reified;
            },

            _logFrameActivate(frame) {
                // We don't actually want to log this event, because these things
                // were 7% of our database, but we do want to save it so we can
                // addDurationInfo later.
                //
                // But, they are actually useful in editor and preview mode, since
                // we're not logging anything else.  They let you see which frame
                // a person was on when they hit an error or something.
                const payload = _.extend({}, this.logInfo(), frame.logInfo());

                if (this.editorMode || this.previewMode) {
                    this._frameActivateEvent = this.logInAllModes('lesson:frame:activate', payload);
                } else {
                    this._frameActivateEvent = new Event('lesson:frame:activate', payload);
                }
            },

            _logFrameStart() {
                // We don't actually want to log this event, because these things
                // were 7% of our database, but we do want to save it so we can
                // addDurationInfo later
                this._framePlayEvent = new Event('lesson:frame:play', this.logInfo());

                // if someone called activeFrame = directly during preloading, then the _frameActivateEvent might
                // not match this active frame.  In that case, just get rid of the _frameActivateEvent.
                if (this._frameActivateEvent && this._frameActivateEvent.properties.frame_id !== this._activeFrame.id) {
                    this._frameActivateEvent = undefined;
                }

                if (this._frameActivateEvent) {
                    // add the time it took since gotoFrame was called
                    this._framePlayEvent.addDurationInfo(this._frameActivateEvent, 'time_to_activate');
                }
            },

            _logFrameFinish() {
                if (!this._activeFrame) {
                    return undefined;
                }

                const eventName = 'lesson:frame:finish';

                // not sure how you could get into a state where there is no
                // play event and you are trying to unload
                if (!this._framePlayEvent) {
                    throw new Error(`No frameViewModelEvent when trying to log ${eventName}`);
                }

                // We don't actually want to log this event, because these things
                // were 7% of our database, but we do want to save it so we can
                // addDurationInfo later
                const event = new Event(eventName, this.logInfo());
                this._frameFinishEvent = event;

                if (this._framePlayEvent.properties.frame_id !== this._activeFrame.id) {
                    $injector
                        .get('ErrorLogService')
                        .notify('Unexpected frameViewModelEvent when logging frame finish', undefined, {
                            finishingFrame: {
                                id: event.properties.frame_id,
                                index: event.properties.frame_index,
                            },
                            frameViewModelEventFrame: {
                                id: this._framePlayEvent.properties.frame_id,
                                index: this._framePlayEvent.properties.frame_index,
                            },
                        });
                } else {
                    event.addDurationInfo(this._framePlayEvent, 'time_to_finish');
                }

                return event;
            },

            _logFrameUnload(options) {
                if (!this._activeFrame) {
                    return undefined;
                }

                const eventName = 'lesson:frame:unload';

                // not sure how you could get into a state where there is no
                // play event and you are trying to unload
                if (!this._framePlayEvent) {
                    throw new Error(`No frameViewModelEvent when trying to log ${eventName}`);
                }

                options = options || {};
                const event = this.log(eventName, {}, options);

                if (this._framePlayEvent.properties.frame_id !== this._activeFrame.id) {
                    $injector
                        .get('ErrorLogService')
                        .notify('Unexpected frameViewModelEvent when logging frame finish', undefined, {
                            finishingFrame: {
                                id: event.properties.frame_id,
                                index: event.properties.frame_index,
                            },
                            frameViewModelEventFrame: {
                                id: this._framePlayEvent.properties.frame_id,
                                index: this._framePlayEvent.properties.frame_index,
                            },
                        });
                } else {
                    event.addDurationInfo(this._framePlayEvent);
                }

                // This stuff tested in player_events_integration_spec.js
                this._addDurationInfoToUnloadEvent(event, this._frameFinishEvent, 'time_to_finish');
                this._addDurationInfoToUnloadEvent(event, this._framePlayEvent, 'time_to_activate');

                return event;
            },

            _addDurationInfoToUnloadEvent(unloadEvent, otherEvent, namespace) {
                if (!otherEvent) {
                    return;
                }

                if (otherEvent.properties.frame_id !== this._activeFrame.id) {
                    $injector
                        .get('ErrorLogService')
                        .notify(`Unexpected frameViewModelEvent when adding duration info (${namespace})`, undefined, {
                            finishingFrame: {
                                id: unloadEvent.properties.frame_id,
                                index: unloadEvent.properties.frame_index,
                            },
                            frameViewModelEventFrame: {
                                id: otherEvent.properties.frame_id,
                                index: otherEvent.properties.frame_index,
                            },
                        });
                } else {
                    unloadEvent.properties[namespace] = otherEvent.properties[namespace];
                }
            },

            _gotoFrameTimerKey(frame) {
                return `gotoFrame-${frame.id}`;
            },
        });
    },
]);
