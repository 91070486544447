import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import { setupBrandNameProperties } from 'AppBranding';
import template from 'Lessons/angularModule/views/stream/student_dashboard_welcome_box.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('studentDashboardWelcomeBox', [
    '$injector',

    function factory($injector) {
        return {
            restrict: 'E',
            templateUrl,
            scope: {
                currentUser: '<',
                streams: '<',
            },
            link(scope) {
                setupBrandNameProperties($injector, scope);
            },
        };
    },
]);
