import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/stream/share_stream_sidebar.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('shareStreamSidebar', [
    '$injector',

    function factory($injector) {
        const ShareService = $injector.get('Navigation.ShareService');
        const SiteMetadata = $injector.get('SiteMetadata');
        const $rootScope = $injector.get('$rootScope');
        const offlineModeManager = $injector.get('offlineModeManager');

        return {
            scope: {
                stream: '<',
            },
            restrict: 'E',
            templateUrl,
            controllerAs: 'controller',

            link(scope) {
                scope.offlineModeManager = offlineModeManager;

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                scope.share = provider => {
                    ShareService.share(
                        'stream',
                        provider,
                        SiteMetadata.contentDefaultShareInfo(scope.currentUser, scope.stream),
                        scope.stream.logInfo(),
                    );
                };
            },
        };
    },
]);
