import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/shared/slide_message.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('slideMessage', [
    '$injector',

    $injector => {
        const MessagingService = $injector.get('Lesson.MessagingService');

        return {
            restrict: 'E',
            scope: {
                frameViewModel: '<',
            },
            templateUrl,

            link(scope, elem) {
                scope.MessagingService = MessagingService;

                scope.onClick = event => {
                    // Don't close the slide message if it's a click on an external link
                    if (event && $(event.target).is('a.external-link')) {
                        return;
                    }
                    MessagingService.clearMessage();
                };

                function updateMessageClasses() {
                    if (!MessagingService.hasMessage) {
                        scope.messageClasses = [];
                        return;
                    }

                    scope.messageClasses = {
                        'slide-message-display': true,
                        correct: MessagingService.correctExists && MessagingService.correct,
                        incorrect: MessagingService.correctExists && !MessagingService.correct,
                        neutral: !MessagingService.correctExists,
                        'continue-button-visible': scope.frameViewModel
                            ? scope.frameViewModel.continueButtonVisible
                            : false,
                        'hint-button-visible': scope.frameViewModel ? scope.frameViewModel.showHintButton : false,
                    };
                }

                scope.$watchGroup(['frameViewModel.continueButtonVisible', 'frameViewModel.showHintButton'], () => {
                    updateMessageClasses();
                });

                scope.$watch('MessagingService.messageId', (_newMessageId, oldMessageId) => {
                    // hack: re-apply the 'updated' class in order to allow re-triggering of animations
                    // also re-apply the class that indicates correctness
                    if (oldMessageId) {
                        const messageDisplay = elem.find('.slide-message-display').get(0);

                        if (messageDisplay) {
                            const displayClassList = messageDisplay.classList;
                            displayClassList.remove('updated');
                            displayClassList.add('updated');

                            ['correct', 'incorrect', 'neutral'].forEach(cssClass => {
                                if (displayClassList.contains(cssClass)) {
                                    displayClassList.remove(cssClass);
                                    displayClassList.add(cssClass);
                                }
                            });
                        }
                    }

                    updateMessageClasses();

                    const messageContent = elem.find('.message-element');
                    messageContent.empty();
                    if (MessagingService.hasMessage) {
                        messageContent.append(MessagingService.getElement());
                    }
                });
            },
        };
    },
]);
