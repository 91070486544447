import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import { setupBrandNameProperties } from 'AppBranding';
import template from 'Lessons/angularModule/views/stream/stream_dashboard.html';
import streamTimerBoxTemplate from 'Lessons/angularModule/views/stream/stream_timer_box.html';
import streamExamScheduleBoxTemplate from 'Lessons/angularModule/views/stream/stream_exam_schedule_box.html';
import isContentItemLocales from 'ContentItem/locales/content_item/is_content_item_mixin-en.json';
import contentAccessHelperLocales from 'Lessons/locales/lessons/shared/content_access_helper-en.json';
import footerLocales from 'Lessons/locales/lessons/stream/dashboard_footer-en.json';
import setSpecLocales from 'Translation/setSpecLocales';
import streamExamScoreBoxTemplate from 'Lessons/angularModule/views/stream/stream_exam_score_box.html';
import streamDashboardResourceLinks from 'Lessons/angularModule/views/stream/stream_dashboard_resource_links.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { gsap } from 'FrontRoyalGsap';
import {
    examVerificationRecordForExamVerificationSession,
    getOrGenerateExamVerificationSessionId,
    mustVerifyIdentityToLaunchStream,
} from 'ExamVerification';
import { BioSigMixin } from 'BioSig';

import lockGreen from 'vectors/lock_green.svg';
import starWhite from 'vectors/star_white.svg';
import summaryIcon from 'vectors/summary.svg';
import accordionArrowDown from 'vectors/accordion_arrow_down.svg';
import trackIconLocked from 'vectors/track_icon_locked.svg';
import miyaMiyaCertificate from 'images/certificate_without_data_gold_miyamiya.png';
import { formatScore } from 'FormatScore';

const templateUrl = cacheAngularTemplate(angularModule, template);

cacheAngularTemplate(angularModule, 'Lessons/stream_timer_box.html', streamTimerBoxTemplate);
cacheAngularTemplate(angularModule, 'Lessons/stream_exam_schedule_box.html', streamExamScheduleBoxTemplate);
cacheAngularTemplate(angularModule, 'Lessons/stream_exam_score_box.html', streamExamScoreBoxTemplate);
cacheAngularTemplate(angularModule, 'Lessons/stream_dashboard_resource_links.html', streamDashboardResourceLinks);

setSpecLocales(isContentItemLocales);
setSpecLocales(footerLocales);
setSpecLocales(contentAccessHelperLocales);

angularModule.directive('streamDashboard', [
    '$injector',
    '$location',

    function factory($injector, $location) {
        const Stream = $injector.get('Lesson.Stream');
        const $rootScope = $injector.get('$rootScope');
        const $route = $injector.get('$route');
        const $timeout = $injector.get('$timeout');
        const isMobileMixin = $injector.get('isMobileMixin');
        const StreamDashboardDirHelper = $injector.get('Stream.StreamDashboardDirHelper');
        const AppHeaderViewModel = $injector.get('Navigation.AppHeader.AppHeaderViewModel');
        const StreamProgressionStatus = $injector.get('StreamProgressionStatus');
        const SiteMetadata = $injector.get('SiteMetadata');
        const RouteAnimationHelper = $injector.get('RouteAnimationHelper');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const DialogModal = $injector.get('DialogModal');
        const FileTypeIcon = $injector.get('FileTypeIcon');
        const ContentAccessHelper = $injector.get('ContentAccessHelper');
        const StreamSummariesHelper = $injector.get('Stream.StreamSummariesHelper');
        const TranslationHelper = $injector.get('TranslationHelper');
        const CertificateHelperMixin = $injector.get('Stream.CertificateHelperMixin');
        const SummaryHelperMixin = $injector.get('Stream.SummaryHelperMixin');
        const scrollHelper = $injector.get('scrollHelper');
        const scopeInterval = $injector.get('scopeInterval');
        const hoursMinutesSecondsFilter = $injector.get('hoursMinutesSecondsFilter');
        const SUPPORTED_COURSE_TALK_STREAMS = $injector.get('SUPPORTED_COURSE_TALK_STREAMS');
        const dateHelper = $injector.get('dateHelper');
        const offlineModeManager = $injector.get('offlineModeManager');
        const Cohort = $injector.get('Cohort');
        const timerSingleton = $injector.get('timerSingleton');
        const ConfigFactory = $injector.get('ConfigFactory');

        return {
            scope: {
                streamId: '@',
            },

            restrict: 'E',
            templateUrl,
            controllerAs: 'controller',

            link(scope, elem) {
                scope.lockGreen = lockGreen;
                scope.starWhite = starWhite;
                scope.summaryIcon = summaryIcon;
                scope.accordionArrowDown = accordionArrowDown;
                scope.trackIconLocked = trackIconLocked;
                scope.miyaMiyaCertificate = miyaMiyaCertificate;

                //---------------------------
                // Initialization
                //---------------------------

                // Setup localization keys
                const translationHelper = new TranslationHelper('lessons.stream.stream_dashboard');
                const contentAccessTranslationHelper = new TranslationHelper('lessons.shared.content_access_helper');

                AppHeaderViewModel.setTitleHTML(translationHelper.get('course_details'));
                isMobileMixin.onLink(scope);
                scope.dateHelper = dateHelper;
                const contentAccessHelpers = {};

                scope.offlineModeManager = offlineModeManager;

                // Get current user onto the scope
                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                Object.defineProperty(scope, 'blueOceanUser', {
                    get() {
                        return scope.currentUser && scope.currentUser.blueOcean;
                    },
                    configurable: true, // specs
                });

                StreamDashboardDirHelper.onLink(scope);
                NavigationHelperMixin.onLink(scope);
                StreamSummariesHelper.onLink(scope);
                CertificateHelperMixin.onLink(scope);
                SummaryHelperMixin.onLink(scope);
                BioSigMixin.onLink(scope, $injector);
                setupBrandNameProperties($injector, scope);

                // Set up header
                AppHeaderViewModel.setBodyBackground();
                AppHeaderViewModel.showAlternateHomeButton = true;

                //---------------------------
                // Navigation
                //---------------------------

                scope.scrollToKeyTerms = () => {
                    scrollHelper.scrollToElement($('#key-terms-caption'), true, -20);
                };

                scope.openStreamDashboard = stream => {
                    if (!ContentAccessHelper.canLaunch(stream)) {
                        return;
                    }
                    $rootScope.pushBackButtonHistory($location.$$url, $route.current.$$route.directive);
                    RouteAnimationHelper.animatePathChange(stream.streamDashboardPath, 'slide-left');
                };

                function navigateToLogin(searchParams) {
                    DialogModal.hideAlerts();
                    const loc = $location.url('/sign-in');
                    if (searchParams) {
                        loc.search(searchParams);
                    }
                }

                function navigateToHome() {
                    DialogModal.hideAlerts();
                    scope.gotoMarketingHome();
                }

                // When using aoi refactoring, see https://github.com/quanticedu/back_royal/pull/10676 for details
                function contentAccessHelper(contentItem) {
                    // prevent recalculating this for multiple content items on every digest
                    if (!contentAccessHelpers[contentItem.id]) {
                        contentAccessHelpers[contentItem.id] = new ContentAccessHelper(contentItem);
                    }
                    return contentAccessHelpers[contentItem.id];
                }

                scope.canLaunch = contentItem => contentAccessHelper(contentItem).canLaunch;

                scope.launchText = contentItem => contentAccessHelper(contentItem).launchText;

                scope.reason = contentItem => contentAccessHelper(contentItem).reason;
                scope.reasonMessage = contentItem => contentAccessHelper(contentItem).reasonMessage;

                const handleBioSig = () => {
                    scope.verifyBioSig({
                        stream_locale_pack_id: scope.stream.localePackId,
                        // getOrGenerateExamVerificationSessionId is responsible for JIT generating an ExamVerificationSession
                        // if we don't already have one for this device / Stream.
                        exam_verification_session_id: getOrGenerateExamVerificationSessionId(scope.stream),
                        lesson_stream_title: scope.stream.title,
                        lesson_stream_id: scope.stream.id,
                    });
                };

                const handleNoUser = () => {
                    const content =
                        `<div><div class="upsell-text">${translationHelper.get('join_for_upsell_desc', {
                            brandName: scope.brandNameShort,
                        })}</div>` +
                        '<div class="button-container">' +
                        `<button type="button" ng-click="navigateToLogin()" class="flat white-and-beige outline modal-secondary-button">${translationHelper.get(
                            'login',
                        )}</button>` +
                        `<button type="button" ng-click="navigateToHome()" class="flat green arrow-right-white modal-action-button">${translationHelper.get(
                            'learn_more',
                        )}</button>` +
                        '</div></div>';

                    // Pop up the selected directive in a modal
                    const modalOptions = {
                        content,
                        title: translationHelper.get('join', { brandName: scope.brandNameShort }),
                        scope: {
                            navigateToLogin,
                            navigateToHome,
                        },

                        // unfortunately, modal.js performs logic if 'fade' class is found,
                        // so this can't just be overridden in CSS
                        animated: !scope.isMobile,
                    };

                    DialogModal.alert(modalOptions);
                };

                const handleTimeLimit = launchLessonCallback => {
                    DialogModal.alert({
                        title: translationHelper.get('ready_to_begin'),
                        content:
                            '<p class="message" translate-once="lessons.stream.stream_dashboard.you_will_have_x_hours" translate-values="{numHours: numHours}"></p>' +
                            '<button class="go modal-action-button" ng-click="launchLessonCallback()" translate-once="lessons.stream.stream_dashboard.start_exam"></button>',
                        size: 'small',
                        closeOnClick: false,
                        scope: {
                            launchLessonCallback,
                            numHours: scope.stream.timeLimitHours,
                        },
                        blurTargetSelector: 'div[ng-controller]',
                    });
                };

                const launchLesson = (lesson, linkId) => {
                    lesson.launch(`stream_dashboard_${linkId}`);
                    DialogModal.hideAlerts();
                };

                scope.showMobileLockedAlert = lesson => {
                    DialogModal.alert({
                        title: contentAccessTranslationHelper.get('reason_title_lesson_default'),
                        content: `<p class="message">${scope.tooltipMessage(lesson)}</p>`,
                        size: 'small',
                    });
                };

                scope.tryToLaunchLesson = (lesson, linkId) => {
                    if (!scope.lessonClickable(lesson)) {
                        return;
                    }

                    if (scope.reason(lesson) === 'requires_biosig_verification') {
                        handleBioSig();
                    }

                    // Show registration modal in lieu of opening the stream, if the user doesn't have an account
                    if (scope.reason(lesson) === 'no_user') {
                        handleNoUser();
                    }

                    if (!scope.canLaunch(lesson)) {
                        return;
                    }

                    if (scope.stream.hasTimeLimit && !scope.stream.started) {
                        handleTimeLimit(() => {
                            launchLesson(lesson, linkId);
                        });
                        return;
                    }

                    launchLesson(lesson, linkId);
                };

                scope.navigateBack = () => {
                    $location.url('/dashboard');
                };

                scope.launchKeyTerm = lesson => {
                    scope.tryToLaunchLesson(lesson, 'key_term');
                };

                //---------------------------
                // Bookmarking
                //---------------------------

                scope.toggleBookmark = stream => {
                    $rootScope.currentUser.toggleBookmark(stream);
                };

                //---------------------------
                // Timer support
                //---------------------------

                let lastDisplayTime;
                scope.$watch('stream', stream => {
                    if (stream && stream.hasTimeLimit && !stream.complete) {
                        scope.showTime = true;

                        // keep track of previous time. only update in interval below if the display is actually different.
                        lastDisplayTime = hoursMinutesSecondsFilter(stream.msLeftInTimeLimit);
                        elem.find('.timer-box .time-left').text(lastDisplayTime);

                        scopeInterval(
                            scope,
                            () => {
                                const newDisplayTime = hoursMinutesSecondsFilter(stream.msLeftInTimeLimit);

                                // don't invalidate layout if we don't need to!
                                if (newDisplayTime !== lastDisplayTime) {
                                    elem.find('.timer-box .time-left').text(
                                        hoursMinutesSecondsFilter(stream.msLeftInTimeLimit),
                                    );
                                    lastDisplayTime = newDisplayTime;
                                }
                            },
                            500,
                            0,
                            false,
                        );
                    }
                });

                //---------------------------
                // Display Helpers
                //---------------------------

                scope.padNumber = String.padNumber;

                scope.scheduleViewModel = {
                    userClickedToUnhideSchedule: false,
                };
                scope.$watchGroup(['xsOrSm', 'scheduleViewModel.userClickedToUnhideSchedule', 'stream.started'], () => {
                    if (
                        scope.xsOrSm &&
                        scope.stream &&
                        scope.stream.started &&
                        !scope.scheduleViewModel.userClickedToUnhideSchedule
                    ) {
                        scope.showSchedule = false;
                    } else {
                        scope.showSchedule = true;
                    }
                });

                scope.formattedLessonMinutes = lesson => String.padNumber(Math.ceil(lesson.approxLessonMinutes), 2);

                scope.canUserPlayLockedChapters = () => {
                    if (!scope.currentUser) {
                        return false;
                    }
                    return scope.currentUser.canEdit();
                };

                scope.isChapterPending = chapter => !!chapter.pending;

                scope.isChapterActive = index =>
                    scope.stream.currentChapter().index === index && scope.stream.progressStatus() !== 'completed';

                const expandedChapters = {};

                scope.toggleChapterExpand = (chapter, $event) => {
                    $event.stopPropagation();
                    expandedChapters[chapter.index] = expandedChapters[chapter.index]
                        ? !expandedChapters[chapter.index]
                        : true;
                };

                scope.isChapterExpanded = chapter => expandedChapters[chapter.index];

                scope.downloadTypeClasses = downloadType => FileTypeIcon.getFontAwesomeIconClass(downloadType);

                scope.startCourseBoxLocaleKey = () => {
                    if (scope.mustVerifyIdentityToLaunchStream) {
                        if (scope.stream.hasLessonProgress) {
                            return 'ready_to_continue_verify_identity';
                        }
                        return 'ready_to_begin_verify_identity';
                    }
                    if (scope.stream.exam) {
                        if (scope.hasExamVerificationRecordForExamVerificationSession) {
                            return 'verified_identity_thanks';
                        }
                        return 'ready_to_begin_exam';
                    }
                    return 'ready_to_begin_start_learning';
                };

                scope.startCourseBoxButtonLocaleKey = () => {
                    if (scope.mustVerifyIdentityToLaunchStream) {
                        return 'verify_identity';
                    }
                    if (scope.stream.exam) {
                        return 'start';
                    }
                    return 'start_course';
                };

                // calculate exam score
                // NOTE: The rounding strategy used here should mirror the rounding strategy used
                // in assessment_score_arc_dir.js. Otherwise, for exam streams that only have one
                // lesson the exam score could be slightly different from the lesson score.

                Object.defineProperty(scope, 'examScore', {
                    get() {
                        if (
                            scope.stream.exam &&
                            scope.stream.complete &&
                            scope.stream.lesson_streams_progress.official_test_score
                        ) {
                            const score = formatScore(scope.stream.lesson_streams_progress.official_test_score);
                            return `${score}%`;
                        }
                        return null;
                    },
                });

                //---------------------------
                // Upload Unofficial Transcripts Modal (see https://trello.com/c/oGP9fbb4)
                //---------------------------

                function shouldShowUploadUnofficialTranscriptsModal() {
                    if (
                        !scope.currentUser ||
                        // Even though we're not updating the has_seen_unofficial_transcripts_popup flag when we show
                        // the modal to the user, we want to keep the flag around in case we need to manually disable
                        // the "reminder" for some students.
                        scope.currentUser.has_seen_unofficial_transcripts_popup ||
                        !scope.currentUser.career_profile ||
                        !scope.stream ||
                        !Cohort.supportsDocumentUpload(scope.currentUser.programType)
                    ) {
                        return false;
                    }

                    return (
                        scope.currentUser.roleName() === 'learner' &&
                        scope.currentUser.emailDomain !== 'pedago.com' &&
                        scope.currentUser.hasPendingProgramApplication &&
                        scope.currentUser.career_profile.numRequiredTranscriptsUploaded === 0 &&
                        scope.currentUser.career_profile.numRequiredTranscriptsNotWaived > 0 && // modal is irrelevant if all transcripts have been waived
                        scope.currentUser.relevantCohort.foundations_lesson_stream_locale_pack_ids.includes(
                            scope.stream.localePackId,
                        )
                    );
                }

                function showUploadUnofficialTranscriptsModal() {
                    DialogModal.alert({
                        title: new TranslationHelper('lessons.stream.upload_unofficial_transcripts_modal').get(
                            'submit_your_transcripts',
                        ),
                        content: '<upload-unofficial-transcripts-modal></upload-unofficial-transcripts-modal>',
                        size: 'small',
                        classes: ['upload-unofficial-transcripts'],
                        closeOnClick: false,
                        hideCloseButton: true,
                    });

                    // We want to continue bugging users until they upload their transcripts
                    // or move out of the state where this flag is relevant.
                    //
                    // NOTE: If we ever bring this back, we'll have to think about how to handle
                    // this in offline mode since the stream dashboard is available offline.
                    // if (!scope.currentUser.ghostMode) {
                    //     scope.currentUser.has_seen_unofficial_transcripts_popup = true;
                    //     scope.currentUser.save();
                    // }
                }

                //---------------------------
                // Course Summaries
                //---------------------------

                let lessonsById = {};

                scope.getCompletionInstructionsForSummary = summary => {
                    let titlesText = '';

                    // build out a nicely formatted string based on lessons list
                    summary.lessons.forEach((lessonId, i) => {
                        const lesson = lessonsById[lessonId];
                        const isLast = i === summary.lessons.length - 1;

                        // handle HTML formatting and appropriate delimiter
                        if (lesson && lesson.title) {
                            if (titlesText) {
                                titlesText += isLast ? ` ${translationHelper.get('or')} ` : ', ';
                            }
                            titlesText += `<strong>"${lesson.title}"</strong>`;
                        }
                    });

                    const translation = translationHelper.get(
                        'completion_instructions_for_summary',
                        {
                            titlesText,
                        },
                        null,
                    );

                    return translation;
                };
                Object.defineProperty(scope, 'streamSummaries', {
                    get() {
                        return scope.summariesForStreamOrLesson(scope.stream, scope.currentUser);
                    },
                    configurable: true, // specs
                });

                scope.lessonSummaries = lesson =>
                    scope.summariesForStreamOrLesson(scope.stream, scope.currentUser, lesson);

                //---------------------------
                // Lesson details
                //---------------------------

                scope.showAssessmentArc = (lesson, hideInProgress = false) => {
                    const hasWaiver = lesson.waiver !== null;
                    const noBestScore = [null, undefined].includes(lesson.bestScore);

                    if (lesson.assessment && lesson.complete && hasWaiver && noBestScore) return false;

                    if (hideInProgress && lesson.assessment && !lesson.complete) return false;

                    return (
                        (lesson.assessment && (lesson.complete || !lesson.started)) ||
                        (scope.stream.exam && scope.stream.complete)
                    );
                };

                scope.formattedBestScore = lesson => formatScore(lesson.bestScore || 0);

                scope.lessonClickable = lesson =>
                    scope.canLaunch(lesson) ||
                    scope.reason(lesson) === 'no_user' ||
                    scope.reason(lesson) === 'requires_biosig_verification';

                scope.lockedTooltipEnable = lesson => !scope.canLaunch(lesson);

                scope.tooltipMessage = lesson =>
                    scope.lockedTooltipEnable(lesson) ? scope.reasonMessage(lesson) : null;

                //---------------------------
                // Exam Verification / Proctoring
                //---------------------------

                // FIXME: just reassign contentAccessHelpers to an empty object?
                const uncacheContentAccessHelperForStreamAndLessons = () => {
                    // Force recalculation of canLaunchStream
                    delete contentAccessHelpers[scope.stream.id];
                    scope.stream.lessons.forEach(lesson => {
                        // Force recalculation of canLaunchLesson for each child lesson
                        delete contentAccessHelpers[lesson.id];
                    });
                };

                const determineExamVerificationRequirements = (uncache = false) => {
                    // We use $evalAsync here because in the best case, it will push this task onto the
                    // queue and process it in the same tick / digest cycle as the caller. In the worst
                    // case, this will be processed on the next tick / digest cycle, which is also fine.
                    scope.$evalAsync(() => {
                        scope.mustVerifyIdentityToLaunchStream = mustVerifyIdentityToLaunchStream(
                            scope.currentUser,
                            scope.stream,
                            ConfigFactory.getSync(),
                        );
                        scope.hasExamVerificationRecordForExamVerificationSession =
                            !!examVerificationRecordForExamVerificationSession(scope.currentUser, scope.stream);

                        // Optionally delete the cached canLaunch values for the Stream and its Lessons so they will be recalculated.
                        // Not required on initial render / Stream load, but required when we receive changes to the user's
                        // ExamVerification records.
                        if (uncache) {
                            uncacheContentAccessHelperForStreamAndLessons();
                        }
                    });
                };

                // Watch for changes to currentUser.exam_verifications sent down in push messages
                scope.$watch(
                    () => scope.currentUser?.exam_verifications,
                    () => {
                        // Prevent this from running on initial render before Stream.getCachedOrShow happens.
                        if (!scope.stream) return;

                        // Something relevant changed so let's re-determine verification status.
                        determineExamVerificationRequirements(true);
                    },
                );

                //---------------------------
                // Data Loading
                //---------------------------

                scope.$watch('streamId', streamId => {
                    const params = {
                        summary: true,
                        include_progress: !!scope.currentUser,
                    };

                    // In the logged out case, we make a special request to attempt to load only SMARTER courses
                    // if this request fails, we want to redirect to the sign-in screen, rather than pop-up a login
                    // window and then retry that special request. This way, when you successfully log in, the student
                    // will be sent back to this route and we'll have a chance to construct a new, logged-in request
                    // for the stream.
                    const options = {
                        'FrontRoyal.ApiErrorHandler': {
                            redirectOnLoggedOut: true,
                        },
                    };

                    Stream.getCachedOrShow(streamId, params, options).then(
                        stream => {
                            AppHeaderViewModel.viewedStream = stream;

                            scope.stream = stream;

                            // After we get the stream, we should immediately check whether or not we need
                            // to verify identity in order to launch it.
                            determineExamVerificationRequirements();

                            scope.chaptersCompletedCount = stream.chaptersCompletedCount;
                            scope.lessonsCompletedCount = stream.lessonsCompletedCount;

                            new ContentAccessHelper(stream).showModalIfPrereqsNotMet();

                            const isCurrentlyMobile = scope.isMobile;

                            // Delay setting key terms to allow for transition to finish and the rest of the page to settle
                            scope.keyTerms = stream.getKeyTerms();

                            // delay more on desktop, since mobile is already delayed via hidingExtraContentFromMobile
                            const keyTermDelay = isCurrentlyMobile ? 1000 : 1750;

                            // show key-terms, no need for preview on mobile
                            $timeout(() => {
                                if (isCurrentlyMobile) {
                                    scope.keyTermsPreview = scope.keyTerms.slice(0, 6);
                                    scope.currentKeyTerms = scope.keyTermsPreview;
                                } else {
                                    scope.currentKeyTerms = scope.keyTerms;
                                }
                            }, keyTermDelay);

                            // Update header metadata for things like sharing, etc
                            if (scope.stream.entity_metadata) {
                                SiteMetadata.updateHeaderMetadata({
                                    title: scope.stream.entity_metadata.title,
                                    description: scope.stream.entity_metadata.description,
                                    path: scope.stream.entity_metadata.canonical_url,
                                    image: scope.stream.entity_metadata.imageSrc(),
                                });
                            } else {
                                // should not happen (see also: content_item_mixin.rb)
                                $injector
                                    .get('ErrorLogService')
                                    .notify('Received empty entity_metadata for stream', null, scope.stream.logInfo());
                            }

                            // Load a list of streams so we can map an ID to data needed for the related/recommended courses
                            const otherStreamIds = stream.recommended_stream_ids.concat(stream.related_stream_ids);
                            if (!offlineModeManager.inOfflineMode && otherStreamIds.length > 0) {
                                Stream.indexForCurrentUser({
                                    filters: {
                                        published: true,
                                        id: otherStreamIds,
                                    },
                                    summary: true,
                                    include_progress: true, // need to load up progress in case someone follows the link
                                }).then(response => {
                                    // Map the resulting streams to a map and put on the scope
                                    const streams = response.result;
                                    const streamMap = {};
                                    streams.forEach(str => {
                                        streamMap[str.id] = str;
                                    });
                                    scope.recommendedStreams = [];
                                    scope.relatedStreams = [];

                                    function mapStreamAndPush(id, list) {
                                        // eslint-disable-next-line no-shadow
                                        const stream = streamMap[id];
                                        if (stream && !stream.coming_soon) {
                                            list.push(stream);
                                        }
                                    }
                                    stream.recommended_stream_ids.forEach(id => {
                                        mapStreamAndPush(id, scope.recommendedStreams);
                                    });
                                    stream.related_stream_ids.forEach(id => {
                                        mapStreamAndPush(id, scope.relatedStreams);
                                    });
                                });
                            }
                        },
                        () => {
                            // no-op. Since we're using redirectOnLoggedOut, it's possible for this request to error
                            // on a 401.  There may also be the possibility of hitting here after a 404.  It doesn't
                            // seem like that should be possible, but git history suggests that at some point someone
                            // at least believed that it was. (In the 401 case, we'll be redirected away from this directive
                            // anyway)
                        },
                    );
                });

                scope.$watch('stream', stream => {
                    if (stream) {
                        // expand all incomplete chapters
                        for (let i = 0; i < scope.stream.chapters.length; i++) {
                            expandedChapters[i] = !scope.stream.chapters[i].complete;
                        }

                        // build lesson lookup
                        lessonsById = _.zipObject(_.map(scope.stream.lessons, 'id'), scope.stream.lessons);

                        // show or hide the course info section based on whether or not there's any valid data to display
                        scope.showAdditionalCourseInformation =
                            _.some(scope.stream.what_you_will_learn) ||
                            _.some(scope.recommendedStreams) ||
                            _.some(scope.relatedStreams) ||
                            _.some(scope.stream.credits) ||
                            (scope.courseTalkEnabled && SUPPORTED_COURSE_TALK_STREAMS[scope.stream.id]);
                    }
                });

                //---------------------------
                // Certificate
                //---------------------------

                scope.$on('ngRepeatFinished', () => {
                    /*
                            StreamProgressionStatus tracks whether the stream or chapter was just completed
                            so we know whether to animate it when returning to the stream dashboard
                        */

                    // stream completion animation ...
                    const streamCompletedIndex = StreamProgressionStatus.completedStreamIndex;
                    if (streamCompletedIndex !== undefined && streamCompletedIndex === scope.streamId) {
                        // reset the completedStreamIndex
                        StreamProgressionStatus.completedStreamIndex = undefined;

                        const certificate = elem.find('.certificate-container');

                        gsap.fromTo(
                            certificate,
                            {
                                y: -300,
                            },
                            {
                                duration: 1.2,
                                y: 0,
                                ease: 'bounce.out',
                            },
                        );

                        gsap.to(certificate, {
                            duration: 0.25,
                            scale: 1.1,
                            delay: 1.5,
                            ease: 'power1.in',
                        });
                        gsap.to(certificate, {
                            duration: 0.25,
                            scale: 1,
                            delay: 2,
                            ease: 'power1.out',
                        });

                        if (shouldShowUploadUnofficialTranscriptsModal()) {
                            showUploadUnofficialTranscriptsModal();
                        }
                    }
                });

                //---------------------------
                // On mobile, improve performance by hiding stuff that is off the screen anyway
                // until the view is done sliding onto the screen.
                //---------------------------

                scope.$watch('isMobile', (newValue, oldValue) => {
                    // if changing to a desktop screen size, show everything
                    if (newValue === false && oldValue === true) {
                        scope.keyTermsHidden = false;
                        scope.courseInformationHidden = false;
                        scope.hidingExtraContentFromMobile = false;
                    }

                    // if changing to a mobile screen size, hide the stuff
                    // that is hidden for space issues, but leave the stuff that
                    // is hidden for performance issues (i.e. do not touch hidingExtraContentFromMobile)
                    // since the animation is already finished.
                    else if (newValue === true && oldValue === false) {
                        scope.courseInformationHidden = true;
                        scope.keyTermsHidden = true;
                    }

                    // When link is first run, base everything on isMobile
                    else {
                        scope.hidingExtraContentFromMobile = scope.isMobile;
                        scope.courseInformationHidden = scope.isMobile;
                        scope.keyTermsHidden = scope.isMobile;
                    }
                });

                scope.$on('RouteAnimationHelper.animationFinished', () => {
                    scope.hidingExtraContentFromMobile = false;
                });

                scope.showCourseInformation = () => {
                    scope.courseInformationHidden = false;
                };

                scope.showKeyTerms = () => {
                    scope.currentKeyTerms = scope.keyTerms;
                    scope.keyTermsHidden = false;
                };

                // Empirically, we have seen that once scope.stream is set, the directive appears to be done loading,
                // so that's when we stop the timer that we started when a user clicked to navigate here.
                scope.$watch('stream', stream => {
                    if (stream) {
                        $timeout().then(() => {
                            timerSingleton.finishTimer(
                                `clickedToNavigateToStreamDashboard-${stream.id}`,
                                'rendered_stream_dashboard',
                                stream.logInfo(),
                            );
                            scope.$emit('renderedStreamDashboard');
                        });
                    }
                });

                //---------------------------
                // Lifecycle
                //---------------------------

                scope.$on('$destroy', () => {
                    AppHeaderViewModel.viewedStream = null;
                });

                //---------------------------
                // Debug
                //---------------------------
                scope.$watch('stream', stream => {
                    if (stream) {
                        stream.notifyOnDisagreementBetweenLessonAndStreamProgress();
                    }
                });
            },
        };
    },
]);
