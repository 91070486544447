import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import { setupBrandScopeProperties } from 'AppBranding';
import template from 'Lessons/angularModule/views/stream/accepted_application.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

import fbIconWhite from 'vectors/fb-icon-white.svg';
import twitterIconWhite from 'vectors/twitter-icon-white.svg';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('applicationAccepted', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const DialogModal = $injector.get('DialogModal');
        const dateHelper = $injector.get('dateHelper');

        return {
            restrict: 'E',
            templateUrl,
            scope: {},
            link(scope) {
                scope.fbIconWhite = fbIconWhite;
                scope.twitterIconWhite = twitterIconWhite;

                setupBrandScopeProperties($injector, scope, ['acceptedApplicationBadge', 'acceptedApplicationLogo'], {
                    branding: () => scope.currentUser?.relevantCohort?.branding,
                });

                // Get current user onto the scope
                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                Object.defineProperty(scope, 'showClass', {
                    get() {
                        return scope.currentUser?.relevantCohort?.supportsSchedule;
                    },
                });

                if (scope.showClass && scope.currentUser.relevantCohort) {
                    scope.classDateString = dateHelper.formattedUserFacingMonthYearLong(
                        scope.currentUser.relevantCohort.graduationDate,
                        false,
                    );
                }

                scope.onGetStarted = () => {
                    DialogModal.hideAlerts();
                };
            },
        };
    },
]);
