import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/stream/student_dashboard_lesson_link_box.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('studentDashboardLessonLinkBox', [
    '$injector',

    function factory($injector) {
        const TranslationHelper = $injector.get('TranslationHelper');
        const EventLogger = $injector.get('EventLogger');
        const DialogModal = $injector.get('DialogModal');
        const RouteAnimationHelper = $injector.get('RouteAnimationHelper');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                mode: '<',
                keepLearningStream: '<',
                keepLearningLesson: '<',
            },
            link(scope) {
                //---------------------------
                // Initialization
                //---------------------------

                const streamTranslationHelper = new TranslationHelper('lessons.stream.stream_dashboard');

                //---------------------------
                // Navigation
                //---------------------------

                function launchLesson() {
                    scope.keepLearningLesson.launch(`keep_learning_${scope.keepLearningLesson.launchText}`);
                    DialogModal.hideAlerts();
                }

                scope.launchKeepLearningStream = () => {
                    if (!scope.keepLearningStream) {
                        return;
                    }
                    EventLogger.log('student-dashboard:keep-learning', scope.keepLearningStream.logInfo());
                    RouteAnimationHelper.animatePathChange(scope.keepLearningStream.streamDashboardPath, 'slide-left');
                };

                scope.launchKeepLearningLesson = () => {
                    if (scope.keepLearningStream.hasTimeLimit && !scope.keepLearningStream.started) {
                        DialogModal.alert({
                            title: streamTranslationHelper.get('ready_to_begin'),
                            content:
                                '<p class="message" translate-once="lessons.stream.stream_dashboard.you_will_have_x_hours" translate-values="{numHours: numHours}"></p>' +
                                '<button class="go modal-action-button" ng-click="launchLesson()" translate-once="lessons.stream.stream_dashboard.start_exam"></button>',
                            size: 'small',
                            closeOnClick: false,
                            scope: {
                                launchLesson,
                                numHours: scope.keepLearningStream.timeLimitHours,
                            },
                            blurTargetSelector: 'div[ng-controller]',
                        });
                        return;
                    }

                    launchLesson();
                };

                //----------------------------
                // Misc Utilities
                //----------------------------

                scope.formattedLessonMinutes = lesson => String.padNumber(Math.ceil(lesson.approxLessonMinutes), 2);
            },
        };
    },
]);
