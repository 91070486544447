import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory('ExternalLinkMetadatum', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');
        const $q = $injector.get('$q');

        // eslint-disable-next-line func-names
        return Iguana.subclass(function () {
            this.setCollection('external_link_metadata');
            this.alias('ExternalLinkMetadatum');

            Object.defineProperty(this.prototype, 'title', {
                get() {
                    const title = this.metadata?.meta?.title;
                    // Prefer the link 'title' attribute if one was provided in the original lesson content
                    // via markdown syntax: [link text](https://some-url.com "Title Attribute Value")
                    return this.link_title_attribute || title || undefined;
                },
            });

            Object.defineProperty(this.prototype, 'errorStatus', {
                get() {
                    return this.fetch_error?.status;
                },
            });

            Object.defineProperty(this.prototype, 'isNotFound', {
                get() {
                    return [404, 410].includes(this.errorStatus);
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'isUnprocessableEntity', {
                get() {
                    return this.errorStatus === 422;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'isWorking', {
                get() {
                    if (this.robot_failed_to_fetch || this.isFailedStatus) {
                        return !!this.verified_working;
                    }
                    return this.verified_working ?? true;
                },
            });

            Object.defineProperty(this.prototype, 'isFailedStatus', {
                get() {
                    return this.isNotFound || this.isUnprocessableEntity;
                },
                configurable: true,
            });

            this.extend({
                _cacheByStreamId: {},

                getAllForStream(streamId, cohortIds, config = {}) {
                    return this._getCachedForStream(streamId) || this._fetchForStream(streamId, cohortIds, config);
                },

                _getCachedForStream(streamId) {
                    return this._cacheByStreamId[streamId];
                },

                _fetchForStream(streamId, cohortIds, config) {
                    const params = { stream_id: streamId };
                    if (cohortIds) params['cohort_ids[]'] = cohortIds;

                    return this.index(params, config).then(resp => {
                        const promise = $q.resolve(resp.result);
                        this._cacheByStreamId[streamId] = promise;
                        return promise;
                    });
                },
            });
        });
    },
]);
