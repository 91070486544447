import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import { setupBrandNameProperties } from 'AppBranding';
import { DEFAULT_SIGNUP_LOCATION } from 'SignupLocations';

angularModule.factory('StreamActivationHelperMixin', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const $window = $injector.get('$window');
        const ContentAccessHelper = $injector.get('ContentAccessHelper');
        const DialogModal = $injector.get('DialogModal');
        const EventLogger = $injector.get('EventLogger');
        const isMobile = $injector.get('isMobile');
        const isMobileMixin = $injector.get('isMobileMixin');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const ngToast = $injector.get('ngToast');
        const RouteAnimationHelper = $injector.get('RouteAnimationHelper');
        const safeApply = $injector.get('safeApply');
        const TranslationHelper = $injector.get('TranslationHelper');
        const timerSingleton = $injector.get('timerSingleton');
        const offlineModeManager = $injector.get('offlineModeManager');

        return {
            onLink(scope) {
                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                scope.translationHelper = new TranslationHelper('lessons.stream.stream_link_box');
                NavigationHelperMixin.onLink(scope);
                isMobileMixin.onLink(scope);
                setupBrandNameProperties($injector, scope);

                // navigation enabled by default
                scope.navigationEnabled = angular.isDefined(scope.navigationEnabled) ? scope.navigationEnabled : true;

                scope.$watch('stream', stream => {
                    offlineModeManager.streamIsAvailableOffline(stream).then(availableOffline => {
                        scope.streamIsAvailableOffline = availableOffline;

                        // streamIsAvailableOffline returns a native promise
                        safeApply(scope);
                    });
                });

                scope.$watchGroup(
                    ['currentUser', 'currentUser.pastDueForIdVerification', 'stream', 'streamIsAvailableOffline'],
                    () => {
                        if (scope.stream) {
                            scope.contentAccessHelper = new ContentAccessHelper(
                                scope.stream,
                                scope.currentUser,
                                scope.streamIsAvailableOffline,
                            );
                            scope.locked = !scope.contentAccessHelper.canLaunch;
                            scope.comingSoon = scope.stream.coming_soon;
                        }
                    },
                );

                scope.$watchGroup(['contentAccessHelper', 'isMobile'], () => {
                    scope.lockedTooltipEnable = !isMobile() && scope.locked;
                });

                scope.$watch('lockedTooltipEnable', val => {
                    if (val) {
                        scope.tooltipMessage = scope.contentAccessHelper.reasonMessage;
                    }
                });

                scope.handleClick = () => {
                    if (!scope.navigationEnabled) {
                        return;
                    }

                    if (scope.contentAccessHelper.reason === 'coming_soon') {
                        scope.showComingSoonModal();
                    } else if (scope.locked) {
                        scope.showLockedModal();
                    } else {
                        RouteAnimationHelper.animatePathChange(scope.stream.streamDashboardPath, 'slide-left');
                        timerSingleton.startTimer(`clickedToNavigateToStreamDashboard-${scope.stream.id}`);
                    }
                };

                scope.showComingSoonModal = () => {
                    const stream = scope.stream;
                    // if logged in and we're in mobile, show a dialog on click
                    // We only show the modal in mobile because in desktop the
                    // link box itself says coming soon on it.
                    if (isMobile()) {
                        // translations
                        const comingSoonTitle = scope.translationHelper.get('coming_soon_title');

                        const cancelLabel = scope.translationHelper.get('cancel');
                        const notifyLabel = scope.translationHelper.get('notify');
                        let comingSoonMessage;

                        // use native dialog for cordova / custom directive for web
                        if ($window.CORDOVA) {
                            comingSoonMessage = scope.translationHelper.get('coming_soon_modal_cordova', {
                                title: stream.title,
                                brandName: scope.brandNameShort,
                            });

                            navigator.notification.confirm(
                                comingSoonMessage,
                                buttonIndex => {
                                    if (buttonIndex === 2) {
                                        // buttonIndex is 1-based for some annoying reason
                                        scope.notifyMeClicked();
                                        safeApply(scope);
                                    }
                                },
                                comingSoonTitle,
                                [cancelLabel, notifyLabel],
                            );
                        } else {
                            comingSoonMessage = scope.translationHelper.get('coming_soon_modal', {
                                title: stream.title,
                                brandName: scope.brandNameShort,
                            });

                            const modalOptions = {
                                content: `<div><div class="coming-soon">${comingSoonMessage}</div><div class="button-container"><button type="button" ng-click="cancel()" class="flat white-and-beige outline modal-secondary-button">${cancelLabel}</button><button type="button" ng-click="notifyMe()" class="flat green modal-action-button">${notifyLabel}</button></div></div>`,
                                title: comingSoonTitle,
                                scope: {
                                    cancel() {
                                        DialogModal.hideAlerts();
                                    },
                                    notifyMe: scope.notifyMeClicked,
                                },
                            };

                            DialogModal.alert(modalOptions);
                        }
                    }
                };

                scope.showLockedModal = () => {
                    if (isMobile()) {
                        const lockedMessage = scope.contentAccessHelper.reasonMessage;
                        const lockedTitle = scope.contentAccessHelper.reasonTitle;

                        const modalOptions = {
                            title: lockedTitle,
                            content: `<div>${lockedMessage}</div>`,
                            closeOnClick: true,
                            closeOnOutsideClick: true,
                        };
                        DialogModal.alert(modalOptions);
                    }
                };

                scope.notifyMeClicked = () => {
                    // In case we were launched from an alert dialog
                    DialogModal.hideAlerts();

                    // If we're not logged in, send the user to the registration page
                    if (!scope.currentUser) {
                        scope.loadUrl(DEFAULT_SIGNUP_LOCATION);
                    } else {
                        EventLogger.log('lesson:stream:notify-coming-soon', scope.stream.logInfo());

                        ngToast.create({
                            content: scope.translationHelper.get('let_you_know'),
                            className: 'info',
                        });
                    }
                };
            },
        };
    },
]);
