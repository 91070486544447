import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { mergeIncomingChanges } from 'LessonProgress';

/*
    Unexpectedly, lesson.lesson_progress is sometimes a vanilla object, and not an instance
    of this class. See the comment in UserProgressLoader#getAllProgress.

    Seems like we do have instances of this class when we're in the lesson player, but not necessarily
    in other places in the UI.
*/
angularModule.factory('LessonProgress', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');
        const StreamProgress = $injector.get('Lesson.StreamProgress');
        const EventLogger = $injector.get('EventLogger');

        return Iguana.subclass(function () {
            this.setCollection('lesson_progress');
            this.alias('LessonProgress');
            this.embeddedIn('lesson');

            this.defineSetter('complete', function (val) {
                if (this.complete === val) {
                    return val;
                }

                // FIXME: see https://trello.com/c/yX3uh6Mj/227-getsentry-lessonprogress-complete-cannot-be-set-back-to-false-from-true
                if (this.complete && !val) {
                    return val;
                    // throw new Error('LessonProgress#complete cannot be set back to false from true.');
                }

                let justCompleted = false;
                if (!this.complete && val === true) {
                    justCompleted = true;
                }

                this.writeKey('complete', val);

                if (justCompleted) {
                    EventLogger.log('lesson:complete', this.lesson().logInfo());
                }
                return val;
            });

            this.extend({
                startLesson(lesson) {
                    const $rootScope = $injector.get('$rootScope');
                    const lessonProgress = this.new({
                        user_id: $rootScope.currentUser && $rootScope.currentUser.id,
                        locale_pack_id: lesson.localePackId,
                        complete: false,
                        frame_history: [],
                        completed_frames: {},
                        challenge_scores: {},
                        frame_durations: {},
                    });
                    lessonProgress.$$embeddedIn = lesson;
                    lesson.lesson_progress = lessonProgress;

                    EventLogger.log('lesson:start', lesson.logInfo());
                    return lessonProgress;
                },
            });

            Object.defineProperty(this.prototype, 'inProgress', {
                get() {
                    return !!this.started_at && !this.complete;
                },
            });

            Object.defineProperty(this.prototype, 'officialTestScore', {
                get() {
                    return this.for_test_lesson ? this.best_score : null;
                },
            });

            return {
                justStarted(lesson) {
                    return this.frame_bookmark_id === lesson.frames[0].id;
                },
                save($super, streamProgress, options) {
                    // it is possible to save a lessonProgress without a streamProgress (daily lessons),
                    // but if something is passed in, make sure it is a streamProgress
                    if (streamProgress && (!streamProgress.isA || !streamProgress.isA(StreamProgress))) {
                        throw new Error('streamProgress must be an instance of StreamProgress');
                    }

                    const meta = streamProgress
                        ? {
                              stream_progress_records: [this._getStreamProgressJson(streamProgress)],
                          }
                        : {};

                    const promise = $super(meta, options).then(response => {
                        // update our in-mem version of stream progress with updated stream progress if its sent back,
                        // like in the case of completing a stream and adding a certificate
                        const returnedStreamProgress = _.find(response.meta?.stream_progress_records, {
                            locale_pack_id: streamProgress?.locale_pack_id,
                        });
                        if (returnedStreamProgress) {
                            angular.extend(streamProgress, returnedStreamProgress);
                        }

                        return response;
                    });

                    return promise;
                },

                restart() {
                    this.completed_frames = {};
                    this.challenge_scores = {};
                    this.frame_durations = {};
                    this.frame_history = [];
                    this.frame_bookmark_id = null;
                    return this;
                },

                clear() {
                    this.restart();
                    this.started_at = null;
                    this.completed_at = null;
                    this.complete = false;
                },

                _getStreamProgressJson(streamProgress) {
                    // uncomment the following line to force
                    // a cyclic error and test the handling of it
                    // streamProgress.test = streamProgress;

                    try {
                        return streamProgress.asJson();
                    } catch (e) {
                        if (!e.message.match(/cyclic/) && !e.message.match(/circular/)) {
                            throw e;
                        }

                        // cherry-pick the keys we expect to be in the
                        // json result.
                        const json = {};
                        [
                            'lesson_stream_id',
                            'complete',
                            '__iguana_type',
                            'lesson_bookmark_id',
                            'started_at',
                            'completed_at',
                            'last_progress_at',
                            'id',
                            'certificate_image',
                        ].forEach(key => {
                            const val = streamProgress[key];
                            json[key] = val && val.asJson ? val.asJson() : val;
                        });

                        // Create an object and add all of the props
                        // from streamProgress one by one.  After adding
                        // each prop, call angular.toJson. When you get
                        // a cyclic error, log which property caused it.
                        const clone = {};
                        for (const i in streamProgress) {
                            clone[i] = streamProgress[i];
                            try {
                                angular.toJson(clone);
                            } catch (err) {
                                if (!err.message.match(/cyclic/) && !err.message.match(/circular/)) {
                                    throw err;
                                }
                                $injector
                                    .get('ErrorLogService')
                                    .notify(
                                        `Notification: Cyclic error in streamProgress.asJson seems to be caused by "${i}"`,
                                    );
                                break;
                            }
                        }

                        // return the json that we built up
                        // from just the expected keys
                        return json;
                    }
                },

                // override iguana's internal _save so that we can merge the challenge_scores and completed_frames
                _save(action, metadata, options) {
                    return this.constructor
                        .saveWithoutInstantiating(action, this.asJson(), metadata, options)
                        .then(response => {
                            const incomingAttrs = mergeIncomingChanges(this, response.result);
                            this.copyAttrs(incomingAttrs);
                            return {
                                result: this,
                                meta: response.meta,
                            };
                        });
                },
            };
        });
    },
]);
